.companiesList{
    width: 100%;
    background-color: white;
    height: 100vh;
    margin: 0px auto;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    scroll-behavior: smooth;
    padding-top: 8rem;
}
.companyListHeader {
  background-color: #f4f6f8;
  font-size: 20px;
  padding: 10px;
  font-weight: bolder;
  color: #2f2963;
  position: sticky;
  top: 0%;
  line-height: 18px;
}
.companies_parent_tile {
  margin: 1% 2%;
  background-color: #f4f6f8;
  height: 84vh;
  width: 100%;
  border-radius: 10px;
  border: 2px solid rgba(145, 145, 145, 0.055);
  box-shadow: 0 10px 10px rgba(145, 144, 144, 0.021),
    0px 0px 25px rgba(94, 93, 93, 0.096);
  overflow-y: scroll;
}
.companies_inner_div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.companies_parent_tile {
  scroll-behavior: smooth;
}
.companies_parent_tile::-webkit-scrollbar {
  width: 4px;
}
.companies_parent_tile::-webkit-scrollbar-track {
  background: #f4f6f8;
}
.companies_parent_tile::-webkit-scrollbar-thumb {
  background: #d8e9fa;
  border-radius: 5px;
}
.company_tile {
  width: 350px;
  height: fit-content;
  padding: 10px 10px;
  background: white;
  border: 1px solid rgba(36, 35, 35, 0.123);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1%;
  justify-content: space-between;
  transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.company_tile:hover {
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.103), 0px 0px 15px rgba(0, 0, 0, 0.068);
}
.company_list_logo {
  width: 45px;
  height: 45px;
}
.sub_header_companies {
  font-size: 14px;
  color: #2f2963a8;
}
.specialOffersHome {
  background-color: #f4f6f8;
  height: 100vh;
  width: 100%;
}
.head_specialOffers {
  line-height: 17px;
  z-index: 1 !important;
  display: flex;
  align-items: center;
}
.sub_head {
  font-size: 12px;
  color: #7773a0;
}
.special_offers_tiles_parent {
  flex-wrap: wrap;
  flex-direction: row;
}
.specialOffer_tile {
  width: 250px;
  height: 100px;
  background-color: #a837bf;
  color: white;
  border-radius: 10px;
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.103),
    0px 0px 30px rgba(0, 0, 0, 0.068);
  padding: 10px;
  font-weight: bolder;
  font-size: 16px;
  cursor: pointer;
  margin: 10px auto;
}
.tile_image {
  width: 60px;
  height: 60px;
  margin-top: -10px;
  margin-left: 180px;
}
.specialOffer_arrow {
  margin-top: 5px;
}
.tile_header {
  width: 55%;
  margin-top: 5px;
}
.specialOffers_navbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 80%;
  padding: 5px 5px 0px 5px;
}
.corona_cat_navbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 80%;
  padding: 5px 5px 0px 5px;
}
.corona_cat_navbar_mobile {
  display: none;
  flex-wrap: nowrap;
  justify-content: space-evenly !important;
  width: 80%;
  padding: 5px 5px 0px 5px;
}
.specialOffers_navbar_mobile {
  display: none;
  flex-wrap: nowrap;
  justify-content: space-evenly !important;
  width: 80%;
  padding: 5px 5px 0px 5px;
}
.navLink > p {
  color: #797f85;
  padding-bottom: 3px;
  font-size: 14px !important;
}
.activeClass {
  border-bottom: 2px solid #48d1cc;
  color: #4b4783 !important;
  font-size: 14px;
}
.corona_essentials_icons {
  color: #4b4783;
  height: 40px;
  width: 40px;
}
.discount_card_1 {
  width: 300px;
  padding: 30px;
  background-color: #f6e056;
  color: #886716;
  border: 1px solid #886716;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.103),
    0px 0px 30px rgba(0, 0, 0, 0.068);
}
.discount_section {
  margin: 10px 30px 15px 30px;
}
.bg_brown {
  background-color: #886716;
}
.bg_cream_lt {
  background-color: rgb(248, 210, 216);
  border: 1px solid rgba(146, 5, 26, 0.733);
}
.color_maroon {
  color: rgba(146, 5, 26, 0.733);
}
.bg_maroon {
  background-color: rgba(146, 5, 26, 0.733);
}
.card_check_1 {
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}
.bg_pink_lt {
  background-color: #edbee7;
  border: 1px solid #79349f;
}
.color_purple {
  color: #79349f;
}
.bg_purple {
  background-color: #79349f;
}
.spinner {
  display: block !important;
  margin: 0% 40% 4% 40%;
}
.coronat_cat {
  text-align: center !important;
}
.corona_cat_img {
  width: 80px !important;
  height: 80px !important;
  background-color: #f1fcfb;
  border: 1px solid #e5f7f7;
  border-radius: 8px;
}
.corona_cat_tag {
  font-size: 12px !important;
  top: -15px !important;
  left: 37% !important;
}
.corona_cat_navbar_mobile::-webkit-scrollbar {
  display: none;
}
.no_data {
  max-width: 300px;
  overflow: auto;
  font-size: 18px;
  color: grey;
  text-align: center;
}
@media only screen and (max-width: 750px) {
  .companyListHeader {
    font-size: 14px;
    margin-top: 40px;
    padding: 10px;
    top: 35px;
    width: 100%;
  }
  .companies_parent_tile {
    height: 92vh;
    width: 100%;
    margin: 0%;
  }
  .companiesList {
    height: 92vh;
    margin: 0%;
    width: 100%;
    padding: 5rem 0%;
  }
  .company_tile {
    width: 400px;
    margin: 1% auto;
  }
  .specialOffer_tile {
    width: 160px;
    height: 70px;
    background-color: #a837bf;
    color: white;
    border-radius: 10px;
    box-shadow: 0 16px 16px rgba(0, 0, 0, 0.103),
      0px 0px 30px rgba(0, 0, 0, 0.068);
    padding: 10px;
    font-weight: bolder;
    font-size: 16px;
    cursor: pointer;
    margin: 10px 0px;
  }
  .tile_image {
    width: 40px;
    height: 40px;
    margin-top: -10px;
    margin-left: 110px;
  }
  .tile_header {
    width: 35%;
    margin-top: 0px;
    font-size: 13px;
    line-height: 13px;
  }
  .specialOffer_arrow {
    margin-top: 5px;
    font-size: 12px;
  }
  .specialOffers_navbar {
    display: none;
  }
  .corona_cat_navbar {
    display: none;
  }
  .corona_cat_navbar_mobile {
    display: flex;
    justify-content: space-between !important;
    width: 94vw;
    padding: 5px 5px 0px 5px;
    margin-left: -10px;
    margin-top: 5px;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .specialOffers_navbar_mobile {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
    padding: 5px 5px 0px 5px;
    margin-left: -10px;
    margin-top: 5px;
  }

  .navLink > p {
    font-size: 12px !important;
    margin: 0px 15px 0px 0px;
    white-space: nowrap;
  }
  /* .sub_head{
        display: none;
    } */
  .corona_essentials_icons {
    color: #4b4783;
    height: 30px;
    width: 30px;
  }
  .discount_card_1 {
    padding: 10px 15px;
    width: fit-content;
    font-size: 12px;
    margin-right: 10px;
    display: table;
  }
  .card_check_1 {
    padding: 3px;
    white-space: nowrap;
    font-size: 10px;
  }
  .discount_section {
    margin: 10px;
  }
  .mobile_display {
    display: inline-flex !important;
  }
}
@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .companyListHeader {
    margin-top: 50px;
    top: 4%;
  }
  .discount_card_1 {
    width: fit-content;
  }
  .companiesList {
    height: 92vh;
    padding-top: 5rem;
  }
  .company_tile {
    width: 600px;
    margin: 1% auto;
  }
  .companies_parent_tile {
    height: 92vh;
  }
  .specialOffer_tile {
    margin: 10px auto;
  }
  .corona_essentials_icons {
    color: #4b4783;
    height: 30px;
    width: 30px;
  }
  .corona_cat_navbar {
    display: none;
  }
  .corona_cat_navbar_mobile {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
    padding: 5px 5px 0px 5px;
    margin-left: -10px;
    margin-top: 5px;
  }
}
