/* Gamification Banner */
.gamification_banner {
  margin: 0 0.5rem;
  height: 120px;
  background-image: linear-gradient(to right, #a93b9b, #79349f, #4b4495);
  border-radius: 20px;
  padding: 1rem;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;
  animation: giftOpen 0.25s forwards ease-in;
}

@keyframes giftOpen {
  0% {
    opacity: 0;
    transform: translateY(-3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gamification_banner_content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.game_sub_cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gamification_gift_icon {
  max-width: 124px;
  margin-right: 3rem;
  transition: all 0.25s ease-in;
}

.gamification_banner_head {
  display: grid;
  place-items: center;
  min-width: 200px;
  height: 65px;
  padding: 1rem;
  border: 1px solid #ffffff;
  border-radius: 3px;
}

.game_offer_cont {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in;
}

.gamification_banner h3 {
  margin: 0;
  min-width: 300px;
  text-align: center;
  transition: all 0.25s ease-in;
  font-weight: bold;
  color: #ffffff;
}

.gamification_banner p {
  margin: 0 1rem;
}

.gamification_saveo_icon {
  max-width: 175px;
  margin: 0 1rem;
}

.gamification_cta_btn {
  background-color: #f9cd39;
  color: #584301;
  font-size: 1.2rem;
  font-weight: bold;
  min-width: fit-content;
  padding: 1rem;
  height: 65px;
  width: 280px;
  border: none;
  border-radius: 9px;
  display: grid;
  place-items: center;
  transition: all 0.25s ease-in;
}

.gamification_banner:hover .gamification_cta_btn {
  background-color: #e8b202;
}

.gamification_cta_btn p {
  margin: 0;
  padding: 0.03rem 0;
  border-bottom: 1px solid #584301;
  width: fit-content;
}

.gamification_banner:hover .gamification_cta_btn p {
  border-color: #474747;
}

@media only screen and (max-width: 1110px) and (min-width: 1001px) {
  .gamification_cta_btn {
    width: 180px;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .gamification_banner {
    border-radius: 0;
    margin: 0;
  }

  .gamification_gift_icon {
    margin-right: 2rem;
  }

  .gamification_banner h3 {
    font-size: 1.2rem;
    min-width: fit-content;
  }

  .gamification_banner_head {
    min-width: 150px;
    height: 65px;
    padding: 0.5rem;
  }

  .gamification_cta_btn {
    padding: 0.5rem;
    width: 160px;
  }
}

@media only screen and (max-width: 750px) {
  .gamification_banner {
    margin: 0;
    height: 130px;
    border-radius: 0;
    padding: 0.5rem;
    justify-content: center;
  }

  .gamification_banner_content {
    flex: none;
    flex-direction: column;
    justify-content: center;
  }

  .gamification_gift_icon {
    max-width: none;
    width: 140px;
  }

  .gamification_banner h3 {
    font-size: 1.2rem;
    min-width: fit-content;
  }

  .gamification_banner_head {
    height: 40px;
    padding: 0.3rem 0.5rem;
    min-width: 150px;
  }

  .game_sub_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .game_offer_cont {
    margin-right: 0;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .game_offer_cont p {
    margin: 0;
    font-size: 0.7rem;
  }

  .gamification_saveo_icon {
    width: 67px;
    margin-right: 0;
  }

  .gamification_cta_btn {
    margin-top: 0.5rem;
    width: 118px;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    height: 32px;
    padding: 0.2rem;
  }
}
