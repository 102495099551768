.ws-search-main-container{
    background-color: #EAECEE;
    height: 100vh;
    overflow: scroll;
    width: 100%;
    scroll-behavior: smooth;
    padding-top: 4rem;
}

.ws-search-main-container::-webkit-scrollbar{
    display: none;
}

@media only screen and (max-width: 750px) {
    .ws-search-main-container{
        padding-top: 7rem;
    }
}


@media only screen and (max-width: 1001px) and (min-width: 751px) {
    .ws-search-main-container{
        padding-top: 7rem;
    }
}