/* // ------------------------------------------------------------------------------------------------------------- */

/* // arrow slider animation starts// */

.center-con {
    display: flex;
    /* height: fit-content; */
    align-items: center !important;
    justify-content: center !important;
}



.round {
    position: relative;
    width: 30px;
    height: 25px;  
    user-select: none !important;  
}

#cta{
    width:100%; cursor: pointer; position: absolute;
}

#cta .arrow{left: 30%;}
.arrow {position: absolute; bottom: 0;  margin-left:0px; width: 12px; height: 12px; background-size: contain; top:-2px;user-select: none !important;}
.segunda{margin-left: 8px;}

#cta .arrowLeft{left: 30%;}
.arrowLeft {position: absolute; bottom: 0;  margin-left:0px; width: 12px; height: 12px; background-size: contain; top:-2px;user-select: none !important;}
.segunda{margin-left: 8px;}
.next {
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==); */
}

@keyframes bounceAlpha {
  0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(10px) scale(0.9);}
  26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
}
@keyframes bounceAlphaLeft {
    0% {opacity: 1; transform: translateX(0px) scale(1);}
    25%{opacity: 0; transform:translateX(-10px) scale(0.9);}
    26%{opacity: 0; transform:translateX(10px) scale(0.9);}
    55% {opacity: 1; transform: translateX(0px) scale(1);}
  }

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.bounceAlphaLeft {
    animation-name: bounceAlphaLeft;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.arrow.primera.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.arrowLeft.primera.bounceAlphaLeft {
    animation-name: bounceAlphaLeft;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.round:hover .arrow{
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.round:hover .arrow.primera{
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.round:hover .arrowLeft{
    animation-name: bounceAlphaLeft;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.round:hover .arrowLeft.primera{
    animation-name: bounceAlphaLeft;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}


/* // arrow slider animation ends// */

/* // ------------------------------------------------------------------------------------------------------------------ */


/* shimmer animation */

.animateShimmer {
    animation : shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
     background-size: 1000px 100%;
 }

 .animateShimmer2{
    animation : shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f333 4%, #e2e2e27a 25%, #eff1f32f 36%);
     background-size: 1000px 100%;
 }
 
 @keyframes shimmer {
   0% {
     background-position: -1000px 0;
   }
   100% {
     background-position: 1000px 0;
   }
 }


 /* shake animation */

 .errorAnime{
    animation: error 0.5s ease-in-out !important;
}
@keyframes error {
    from{margin-left:-4px}
    30%{margin-left: 4px;}
    50%{margin-left: -4px;}
    75%{margin-left: 4px;}
    to{margin-left: -4px;}

}


