.map-modal-body{
    position: relative;
    display: table; /* <-- This makes the trick */
    overflow-y: auto;    
    overflow-x: auto;
    width: auto;
    min-width: 20rem;
    padding: 0px;
}

.map-modal-footer{
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;
}

.map-modal-content{
    border-radius: .3rem !important;
}


@media only screen and (max-width: 750px) {
    .map-modal{
        width: 97%;
    }
}
