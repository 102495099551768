.footer_parent{
    width: 100%;
    height: fit-content;
    display: table;
    flex-wrap: wrap;
    font-size: 18px;
    padding: 3%;
}
.bg_light_blue{
    background-color: #edecfd80 ;
}

.footer_first_sec > p{
    word-spacing: 3px;
    letter-spacing: 2px;
}
.play_store_img{
    width: 180px;
    cursor: pointer;
}
.line_height_8{
    line-height: 8px;
}
.social_logos{
    margin-top: 20px;
    width: 80%;
    margin-left: -8% ;
    justify-content: space-between;
}
.footer_saveo_logo{
    width: 70%;
}
.social_sec{
    margin-top: -5px;
}
.social_logos a{
    background-color: #EFEFEF;
    border-radius: 4px;
    padding: 3px 8px;
}
.footer_wtsapp{
    width: 90%;
    margin-top: -5%;
    margin-left: 20%;  

}
@media only screen and (max-width: 1001px) and (min-width: 751px) {
   .footer_wtsapp{
      width: 100px;
      margin-top: 0%;

   } 
   .footer_first_sec{
    font-size: 14px;
   }
   .play_store_img{
    width: 130px;
   }
   .contact_footer{
    font-size: 14px;
    padding: 10px;
   }
   .social_sec{
    margin-top: 0%;
   }
   .social_logos a{
    background-color: #EFEFEF;
    border-radius: 4px;
    padding: 3px 4px;
    font-size: 12px;
}
.wtsp_cntct_link{
    line-height: 40px ;
    white-space: nowrap;
}

}
@media only screen and (max-width: 750px) {

    .footer_first_sec{
        text-align: center;
       font-size: 14px;
    }
    .play_store_img{
           width: 130px;
    }
    .contact_footer{
        text-align: center;
        font-size: 14px;
        padding: 20px;
    }
    .social_sec{
        text-align: center;
    }
    .social_logos{
       width: 90%;
    }
    .social_logos{
        margin-left: 0%;
    }
    .footer_wtsapp{
        width:50%;
        margin-top: 5%;
        margin-left: 35%;  
     } 

}