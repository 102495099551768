.wholesale-banner-wrapper {
  margin: 1rem .5rem;
}

.tsp-main-container {
  min-height: 10rem;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  padding: 1rem;
}

.tsp-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tsp-header > span {
  margin-left: 1rem;
}

.tsp-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wholesale-product-card-wrapper {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.wholesale-product-card-wrapper::-webkit-scrollbar {
  display: none;
}

.wholesale-parent-container {
  background-color: #eaecee;
  min-height: fit-content;
  width: 100%;
  padding-top: 7rem;
}

.wholesale-cd-image {
  width: 9rem;
  height: 6rem;
  margin-bottom: 0.5rem;
}

.ws-cd-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background-color: #ffffff;
  border: solid 2px #86d8d0;
  border-radius: 5px;
  width: 23rem;
  height: 9rem;
  /* margin: 1rem; */
}
.ws-cd-card > div {
  font-size: 1rem;
  width: 100%;
  text-align: center;
  color: #000;
}
.ws-cd-card > div > span {
  font-weight: bold;
}

.ws-cd-main {
  background-color: #517187;
  color: #ffffff;
}

.ws-cd-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width: 50%; */
  margin: auto;
  padding: 1rem;
}

.ws-tco-tab-active {
  min-width: 14rem;
  height: 5rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 10px 0px;
  transition: .3s all;
  z-index: 9;
  position: sticky;
  left: 0rem;
  background-color: #ffffff;
  cursor: default;
}

.ws-tco-tab {
  min-width: 8rem;
  height: 3rem;
  border: 1px solid #e8e8e8;
  text-align: center;
  background-color: #e8e8e8;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #cbd1de;
  transition: .3s all;
  cursor: pointer;
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 3px 0px;

}

.ws-tco-tab > img {
  height: 2rem;
  width: 3rem;
  transition: .3s all;

}

.ws-tab-content p {
  margin: 0px;
  transition: .3s all;
}

.ws-tab-content-active {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  transition: .3s all;

}

.ws-tab-content-active h6 {
  font-size: 0.9rem;
  margin: 0px;
  transition: .3s all;

}

.ws-tab-content-active p {
  font-size: 0.6rem;
  text-transform: uppercase;
  margin: 0px;
  transition: .3s all;

}

.ws-tco-tabs-wrapper {
  padding: 1rem;
  align-items: flex-end;
  overflow-x: scroll;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  min-height: 6rem;
}

.ws-tco-tabs-wrapper::-webkit-scrollbar {
  display: none;
}

.ws-tab-content p {
  font-size: 0.7rem;
}

.ws-medicine-container {
  padding: 1rem;
  background-color: #f1fffe;
  border: 1px solid #e8e8e8;
  min-height: 16.1rem;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  overflow-x: scroll;
  border-radius: 0px 0px 5px 5px;
}

.ws-man-logo {
  height: 4rem;
  width: 4rem;
  border-radius: 8px;
}

.ws-man-logo-inactive{
  height: 2rem;
  width: 2rem;
  border-radius: 8px;
}

.ws-medicine-container::-webkit-scrollbar {
  display: none;
}

.fmp-card{
  margin: 1rem;
}

@media only screen and (max-width: 750px) {

  .wholesale-cd-image {
    height: 3rem;
    width: 5rem;
  }

  .ws-cd-card {
    max-height: 7rem;
    max-width: 7rem;
  }

  .ws-cd-card > div {
    font-size: 0.8rem;
  }
  .ws-cd-card-wrapper {
    padding: 1rem 0rem;
  }
  .ws-medicine-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .wholesale-parent-container{
    padding-top: 10rem;
    /* padding-bottom: 3rem; */
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .ws-cd-card {
    max-width: 9rem;
    max-height: 8rem;
    padding: 0.9rem;
  }
  .wholesale-cd-image {
    height: 4rem;
    width: 6rem;
  }

  .ws-medicine-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .wholesale-parent-container{
    padding-top: 10.2rem;
    padding-bottom: 0rem;
  }
}
