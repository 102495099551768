.bottom_toast{
    position: fixed;
    bottom: 3%;
    width: 100vw;
    text-align: center;
    z-index: 15 !important;
}
.toast_div{
    min-width: 400px;
    max-width: fit-content;
    margin: 0px auto;
    background-color: #FED0CA;
    padding: 5px;
    border-radius: 5px;
    border-left: 5px solid #fb5d48;
    box-shadow: 3px 3px 2px 2px rgba(128, 128, 128, 0.103);
}
.toast_content{
    display: flex;
    justify-content: space-between;
}
.error_icon{
    color:#fb5d48 !important;
    font-size: 20px !important;
}
.tryAgain_btn{
    border: none;
    background-color: #fb5d48;
    color: white !important;
    margin: 2px 10px;
    border-radius: 50%;
    box-shadow: 3px 3px 2px 2px rgba(128, 128, 128, 0.103);
    font-size: 20px;
    cursor: pointer;
}
.codes{
    font-size: 12px;
    color: #fb5d48;
    text-align: start;
    margin-left: 35px;
    display: flex;
    justify-content: space-between;
    padding-top: 2px;
    
}
.cancel{
    background-color: rgba(255, 255, 255, 0.395);
    border-radius: 50%;
    height: 21px;
    width: 21px;
    font-size: 13px;
    margin-top: 3px;
}


@media only screen and (max-width: 1000px){
    .bottom_toast{
       
        bottom: 7%;
      
    }
    .toast_div{
        width: 300px ;
    }
}