.giftParentCont {
  margin-top: 7rem;
  padding: 2%;
  min-height: calc(100vh - 117px);
  width: 100%;
  background-color: #f2f7f7;
  display: flex;
  justify-content: center;
}

.giftChildCont {
  width: 90%;
}

/* Heaeder */
.giftHeader {
  padding: 0;
  list-style: none;
  display: flex;
}

.giftHeader li {
  display: grid;
  place-items: center;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.giftHeader li:not(:first-child) {
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  color: #656565;
  text-align: center;
  border-bottom: 2px solid transparent;
}

.giftHeader li:not(:first-child):hover {
    color: #514aa1;
}

.giftBackBtn {
  padding-right: 0.5rem;
  font-size: 1.5rem !important;
  color: #a2a2a2;
}

.giftBackBtn:hover {
  color: #3cb3af;
}

.currentNav {
  color: #514aa1 !important;
  font-weight: 500 !important;
  border-color: #514aa1 !important;
}

/* Loader */
.campLoader {
  margin-top: 200px;
}

.campLoader svg {
  width: 100px;
}

/* Body */
.giftBodyCont {
  max-height: 75vh;
  overflow-y: auto;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

/* Empty Campaign */
.emptyCampDesc {
  margin-top: 20vh;
  color: #a2a2a2;
  font-size: 1.1rem;
  text-align: center;
}
