.player-wrapper {
  /* position: relative; */
  /* padding-top: 56.25% Player ratio: 100 / (1280 / 720); */
}

.react-player {
  width: 13.75rem !important;
  height: 24.25rem !important;
}

@media only screen and (max-width: 750px) {
  .react-player {
    width: 12.75rem !important;
    height: 22.5rem !important ;
  }
}
