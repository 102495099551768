.email-input-placeholder{
    background-color: #f8f8f8;
    border: none;
    border-radius: 10px;
    color: #11141c;
  transition: 0.3s;
  font-stretch: normal;
    font-weight: bold;
    letter-spacing: .7px;
    line-height: 1.15;
}
.email-input-placeholder:focus{
    border-top: 1px solid 11141c ;
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.2) !important;
    background-color: #f8f8f8;
}

.email-input-placeholder::placeholder{
    font-stretch: normal;
    font-weight: bold;
    letter-spacing: .7px;
    line-height: 1.15;
}

.email-header{
    font-size: .8rem;
    margin: 5px 5px;
    color: #11141c;
}

.continue-button{
    margin: 1rem auto;
    min-height: 2.5rem;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: #48D1CC;
    color: #ffffff;
    font-weight: bold;
    transition: .3s all;
}

.continue-button:hover{
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.2) !important;
}

.continue-button:disabled{
    background-color: rgb(193, 193, 193);
    color: #ffffff;
    cursor: not-allowed;
}

