.ws-products-primary-container {
  background-color: #eaecee;
  height: 100vh;
  padding-top: 3rem;
  overflow-y: scroll;
}

.ws-products-primary-container::-webkit-scrollbar {
  display: none;
}
.ws-search-container {
  display: flex;
  align-items: center;
  /* width: fit-content; */
  justify-content: flex-start;
  margin-left: 1rem;
  width: 25rem;
}

.ws-products-primary-search {
  width: 60%;
}

.ws-input-container-hidden {
  display: none;
}

.ws-product-search-container {
  background-color: #ffffff;
  color: rgba(151, 171, 184, 1);
  min-height: 3rem;
  width: 10%;
  margin: 0px;
  transition: 0.3s all;
}

.ws-product-search-container-expand {
  width: 100% !important;
  /* right: 50px; */
}

.ws-search {
  margin-left: 0rem;
}

.ws-search-expand {
  margin-left: 1rem;
}

.ws-products-main-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 4rem;
  height: 87vh;
  overflow-y: scroll;
}

.ws-infy-s-component {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.ws-products-main-container::-webkit-scrollbar {
  display: none;
}

.ws-products-main-container > p {
  color: #1a4059;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0px;
  margin-left: 3rem;
}

.ws-products-header {
  width: 100%;
  text-align: left;
  padding-left: 2rem;
  min-height: 4rem;
  position: fixed;
  top: 4rem;
  background-color: #eaecee; /* border: 1px solid red; */
  display: flex;
  align-items: center;
  z-index: 1;
}

.ws-products-header > p {
  margin: 0px;
  padding-left: 1rem;
  font-size: 1.2rem;
  color: #1a4059;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.ws-products-header > svg {
  /* color: #1A4059; */
  height: 1.5rem;
  width: 1.5rem;
  fill: #1a4059 !important;
  stroke: #1a4059 !important;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.ws-products-header > svg:hover {
  fill: #02c3b0 !important;
}

.ws-cd-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  /* min-height: 3.5rem; */
  /* position: sticky; */
  /* top: 3.5rem; */
  width: 100%;
  z-index: 9;
}

.ws-cd-tab {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}

.ws-cd-tab-active > p {
  font-size: 1rem;
  font-weight: bold;
  /* margin-top: 1rem !important; */
}

.ws-cd-tab > p {
  margin: 0px;
  font-size: 0.9rem;
}

.active-indicator {
  background-color: #ffffff;
  border: 3px solid #ffffff;
  border-radius: 5px 5px 0px 0px;
  width: 40%;
  /* margin-top: 1.3rem; */
}

.ws-cd-header {
  background-color: #517187;
  color: #ffffff;
}

.ws-dod-timer {
  margin-left: auto;
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
}

.ws-dod-timer > p {
  color: #ffffff !important;
}

.ws-companies-scroll {
  display: flex;
  overflow-x: scroll;
}

.ws-companies-scroll::-webkit-scrollbar {
  display: none;
}

.ws-companies-main-container {
  margin-top: 4rem;
}

.ws-companies-header {
  background-color: #1a4059;
  border: 1px solid #e8e8e8;
  padding: 1rem 0px 1rem 1rem;
  width: 100%;
}

.ws-companies-header > p {
  color: #fbfcfb;
  font-size: 0.9rem !important;
  text-decoration: underline;
  font-weight: bold;
}

.ws-companies-header p {
  color: #fbfcfb;
  font-size: 0.8rem;
  margin: 0px;
}

.ws-companies-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 80vh;
  overflow-y: scroll;
}

.ws-companies-list-container::-webkit-scrollbar {
  display: none;
}
.ws-company-list-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  margin: 1rem;
  min-height: 5rem;
  max-height: 5rem;
  min-width: 20rem;
  max-width: 20rem;
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  transition: 0.3s all;
  box-shadow: rgb(149 157 165 / 10%) 0px 2px 10px 0px;
}

.ws-company-list-card p {
  margin: 0px;
  font-size: 0.85rem;
  text-align: left;
}

.ws-company-list-card > p:first-child {
  color: #053833;
  font-weight: bold;
}

.ws-company-list-card > p:last-child {
  color: #9399a4;
  font-size: 0.8rem;
}

.ws-company-list-card:hover {
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 10px 0px;
}

.wholesale-product-image {
  height: 30rem;
  width: 30rem;
}

.ws-product-page-parent-container {
  display: flex;
  padding-top: 5rem;
  justify-content: center;
  min-height: 100vh;
  height: fit-content;
}

.ws-product-image-wrapper {
  display: flex;
  flex-direction: column;
}

.ws-product-image-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
}

.ws-product-details-main-container {
  padding: 1rem;
  width: 50%;
  background-color: #fbfbfb;
  border-radius: 10px;
  height: fit-content;
}

.ws-product-details-main-container > div:first-child > p:first-child,
.ws-product-header-details-min > p {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #cffffa;
  padding: 0.3rem 1rem;
  margin: 0px;
  text-align: center;
  border-radius: 5px;
  font-size: 0.9rem;
  width: fit-content;
  margin-bottom: 1rem;
}

.ws-product-details-main-container > div:first-child > h5,
.ws-product-header-details-min > h5 {
  color: #000000;
  font-weight: bold;
  font-size: 1.5rem;
  /* margin-bottom: 1rem; */
}

.ws-product-man-details {
  display: flex;
  align-items: center;
}

.ws-product-man-details p:first-child {
  color: #02c3b0;
  font-weight: bold;
}

.ws-product-man-details p:last-child {
  color: #9399a4;
  margin-left: 1rem;
}

.ws-product-tabs {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  align-items: flex-end;
}

.ws-product-tabs::-webkit-scrollbar {
  display: none;
}

.ws-product-tab {
  background-color: #eaecee;

  min-height: 3rem;
  max-height: 3rem;
  /* min-width: 14rem; */
  /* max-width: 14rem; */

  flex-grow: 1;
  border: 1px solid #eaecee;
  color: #27324b;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  border: 1px solid #d1d1d3;
  cursor: pointer;
}

.ws-product-tab p {
  margin: 0px;
}

.ws-product-tab p:first-child {
  color: #9099ab;
}

.ws-product-tab-active {
  min-height: 4rem;
  max-height: 4rem;
  /* min-width: 14rem;
    max-width: 14rem; */
  flex-grow: 1;
  background-color: #869dac;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d1d1d3;
  cursor: pointer;
}

.ws-product-tab-active p:first-child {
  text-transform: uppercase;
  font-weight: bold;
  color: #000000;
  margin: 0px;
}
.ws-product-tab-active p:last-child {
  text-transform: uppercase;
  font-weight: bold;
  color: #ffffff;
  margin: 0px;
}

.ws-product-tab-content {
  background-color: #f8f8f8;
  border: 1px solid #d1d1d3;
  padding: 1rem;
  min-height: 15rem;
  display: flex;
  /* border-bottom-left-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
}

.ws-other-tab-separator {
  width: 2px;
  height: 11rem;
  background-color: #dbdbdb;
  margin-top: 0.9rem;
}

.ws-product-other-tab-content {
  background-color: #ffffff;
  border: 1.5px solid #00DAC5;
  transition: .3s all;
  padding: 1rem;
  /* min-height: 15rem; */
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
}

.ws-tab-content-summary {
  display: flex;
}

.ws-tab-content-summary>div>p {
  color: #68737a;
  font-size: 0.9rem;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.ws-scheme-container{
  display: flex;
  align-items: center;
  color: #27324b;
  font-size: 0.9rem;
  font-style: normal;
}

.ws-tab-content-summary p {
  margin: 0.9rem 0.5rem;
}

.ws-tab-content-summary>div>p>span {
  color: #27324b;
  font-size: 0.9rem;
  font-style: normal;
}

.ws-tab-content-summary>div>p:last-child>span {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
  width: fit-content;
  border: 1px solid #d1d1d3;
  font-size: 0.8rem;
  font-weight: bold;
}

.ws-tab-content-breakup {
  flex: 1;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
}

.ws-tab-content-breakup p {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.4rem;
  font-size: 0.9rem;
  margin: 0.5rem;
}

.ws-tab-content-breakup p:last-child {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: auto;
  padding-top: 0.5rem;
}
.ws-tab-content-breakup p:last-child > span {
  border-top: 2px solid #dbdbdb;
}

.ws-product-quantity-container {
  margin-top: 1rem;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
}

.ws-product-quantity-container div p {
  margin: 0px;
}

.ws-product-quantity-container div:first-child {
  display: flex;
  flex-direction: column;
}

.ws-product-quantity-container div:first-child p:first-child {
  color: #9399a4;
  font-size: 0.9rem;
}

.ws-product-quantity-container div:first-child p:last-child {
  color: #2a2a2a;
  font-size: 1rem;
  font-weight: bold;
}

.ws-prod-delete {
  background-color: #ffffff;
  border: 1px solid #e4e5e5;
  border-radius: 5px;
  padding: 0.5rem;
  transition: 0.3s all;
  cursor: pointer;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 2px 10px 0px;
}

.ws-prod-delete:hover {
  background-color: #cffffa;
}

.ws-product-quantity-container > div:last-child p {
  margin: 0px;
}

/* .ws-product-quantity-container>div:last-child p:first-child{
     background-color: #EAECEE;
     color: #DBDBDB;
 } */

.ws-product-quantity-container > div:last-child {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ws-product-quantity-container > div:last-child > img {
  margin-right: 1rem;
}

.quantity-input-wrapper {
  display: flex;
  align-items: center;
}

.quantity-input-wrapper > p:first-child {
  font-size: 1.5rem;
  background-color: #eaecee;
  color: #dbdbdb;
  width: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 2rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.quantity-input-wrapper > input {
  background-color: white;
  border: 1px solid #eaecee;
  width: 5rem;
  height: 2rem;
  color: #2a2a2a;
  font-weight: bold;
  text-align: center;
}

.ws-quantity-input-error {
  border: 1px solid crimson !important;
}

.quantity-input-wrapper > p:last-child {
  font-size: 1.5rem;
  background-color: #cffffa;
  color: #20475e;
  width: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 2rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.confirm-button {
  background-color: #ffffff;
  color: #2a2a2a;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #02c3b0;
  border-radius: 0.5rem;
  width: 7.7rem;
  height: 2.5rem;
}

.ws-add-quantity-container {
  margin-top: 1rem;
  background-color: #00dac5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
}

.ws-add-quantity-container > div:first-child > p:first-child {
  color: #064650;
  font-size: 0.8rem;
  margin: 0px;
}

.ws-add-quantity-container > div:first-child > p:last-child {
  color: #000000;
  font-size: 1rem;
  font-weight: bold;
}

.ws-item-focus {
  background-color: #cffffa;
  border: 1px solid #00dac5;
  border-radius: 0.25rem;
}

.ws-quantity-error-message {
  color: crimson;
  font-size: 0.8rem !important;
  text-align: right;
}

.ws-product-other-tab-content-min {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin: 1rem 0rem;
  height: fit-content;
  transition: .3s all;
}

.ws-tab-content-summary-min {
  display: flex;
  height: 100%;
}
.ws-tab-content-summary-min p {
  margin: 0.9rem 0.5rem;
}

.ws-tab-content-summary-min > div:first-child {
  width: 30%;
}

.ws-tab-content-summary-min > div:last-child {
  width: 70%;
  padding-left: 1rem;
  display: flex;
  justify-content: flex-end;
}

.ws-tab-content-summary-min > div:first-child > p {
  color: #68737a;
  font-size: 0.8rem;
  font-style: italic;
}

.ws-tab-content-summary-min > div:first-child > p > span {
  color: #27324b;
  font-weight: bold;
  font-style: normal;
  margin-left: 0.3rem;
  font-size: 0.9rem;
}

.ws-product-separator-min {
  width: 2px;
  background-color: #d1d1d3;
  height: 70%;
  margin-top: 0.9rem;
}

.ws-product-net-rate-container > p:first-child {
  font-size: 0.8rem;
  color: #27324b;
  font-weight: 500;
}

.ws-product-net-rate-container > p:first-child > span {
  font-size: 1rem;
  font-weight: bold;
  color: #27324b;
}

.ws-product-net-rate-container > p:last-child {
  font-size: 0.8rem;
  color: #68737a;
  font-style: italic;
}

.ws-product-net-rate-container > p:last-child > span {
  font-size: 0.9rem;
  color: #27324b;
  font-weight: bold;
}

.ws-product-see-more {
  color: #02c3b0;
  font-weight: bold;
  text-decoration: underline;
  font-size: 0.9rem !important;
  text-align: center;
  margin: 0px;
  text-transform: capitalize;
  cursor: pointer;
}

.ws-product-items-wrapper {
  background-color: #ffffff;
  position: sticky;
  border-radius: 10px;
  width: 100%;
}

.ws-product-buy-now-container {
  display: flex;
  /* position: absolute; */
  /* min-width: 39.5rem; */
  align-items: center;
  /* margin-top: 10rem; */
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: -2rem;
  padding: 0rem 2rem;
}

.ws-product-buy-now-container > button {
  /* float: right; */
  margin-left: auto;
  /* position: absolute; */
  /* right: 10.5rem; */
  margin-top: -1.5rem;
  background-color: #1a4059;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.25rem;
  border: 1px solid #03dac5;
}

.ws-product-see-more-alt {
  /* margin-left: 18.4rem; */
  /* margin: auto !important; */
  /* margin-left: 46%; */
  /* margin: auto !important; */
  /* margin-left: 89.4px; */
  width: 100%;
}

.ws-product-header-container-min {
  display: none;
}
.ws-products-sbc-primary-header {
  top: 7rem;
}

.ws-products-sbc-main-container {
  margin-top: 8rem;
}

.ws-cd-header-update{
  top: 5.8rem;
}

.ws-products-sbc-primary-header-update{
  top: 9rem;
  z-index: 1;
}

.ws-products-primary-container-update{
  padding-top: 4rem;
}

.ws-products-primary-header-update{
  top: 6rem;
  z-index: 1;
}

.ws-slab-container{
  width: 100%;
}
 .ws-slab-container p{
  margin: 0;
 }

 .ws-slab-header{
  padding:.5rem;
  background-color: #869DAC;
 }

 .ws-slab-carousel{
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 1rem .5rem;
  background-color: #EAECEE;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:.5rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
 }

 .ws-slab-carousel::-webkit-scrollbar{
  display: none;
 }

 .ws-slab-card{
  background-color: #1A4059;
  min-width: 7rem;
  padding: .2rem .5rem;
  border-radius: .25rem;
 }

 .ws-slab-card>p:first-child{
  color: #fff;
 }

 .ws-slab-card>p:last-child{
  color: #03C3B0;

 }

 .ws-slab-card-default{
  cursor: pointer;
  background-color: #fff;
  color: #9099AB !important;
  border: 1px solid #9099AB;
 }


 .ws-slab-card-default>p:first-child{
  color: #9099AB;
 }

 .ws-slab-card-default>p:last-child{
  color: #9099AB;
  font-weight: bold;
 }

@media only screen and (max-width: 750px) {

  .ws-tab-content-summary>div>p{
    gap: 1rem;
  }

  .ws-tab-content-summary{
    width: 40%;
  }

  .ws-back-nav-product-page {
    display: none;
  }

  .ws-product-page-parent-container {
    flex-wrap: wrap;
    padding-top: 7rem;
    justify-content: flex-start;
    padding-bottom: 3rem;
    min-height: 100vh;
  }

  .ws-product-image-container {
    display: none;
  }

  .wholesale-product-image {
    height: 5rem;
    width: 5rem;
    margin-right: 1rem;
    border: 1px solid #f4f6f8;
  }

  .ws-product-details-main-container {
    width: 100%;
    margin-top: 1rem;
  }

  .ws-product-other-tab-content,
  .ws-product-tab-content {
    padding: 0rem 0rem 1rem 0rem;
  }

  .ws-product-header-container {
    display: none;
  }

  .ws-product-header-container-min {
    display: flex;
    background-color: #ffffff;
  }

  .ws-product-header-details-min > p {
    margin-bottom: 0.5rem;
  }
  .ws-product-header-details-min > h5 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
  .ws-product-man-details p {
    font-size: 0.8rem;
  }

  .ws-back-nav {
    margin-left: 1rem;
  }

  .ws-product-tab p {
    font-size: 0.8rem !important;
  }

  .ws-product-tab-active p {
    font-size: 0.9rem !important;
  }

  .ws-products-header {
    min-height: 2rem;
  }

  .ws-products-primary-header {
    padding: 0.6rem 1rem;
    top: 6.9rem;
  }
  .ws-products-primary-header > p {
    width: 100% !important;
  }
  .ws-products-main-container {
    margin-top: 6rem;
  }

  .ws-products-primary-container {
    padding: 3rem 0rem;
  }

  .ws-products-sbc-primary-header {
    top: 10rem;
  }

  .ws-products-sbc-main-container {
    margin-top: 10rem;
  }

  .ws-product-items-wrapper{
    padding: 0rem .5rem;
  }

  .active-indicator{
    width: 85%;
  }

  .ws-cd-header-update{
    top: 8.6rem;
  }
  
  .ws-products-sbc-primary-header-update{
    top: 11.6rem;
  }

  .ws-products-primary-container-update{
    padding-top: 6rem;
  }

  .ws-products-primary-header-update{
    top: 8.9rem;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .ws-products-sbc-primary-header {
    padding: 0.6rem 1rem;
    top: 10rem;
  }

  .ws-products-sbc-main-container {
    margin-top: 11rem;
    /* padding-bottom: 15rem; */
  }

  .ws-products-primary-container {
    padding: 6rem 0rem;
  }

  .ws-products-primary-header {
    padding: 0.6rem 1rem;
    top: 7rem;
  }

  /* .ws-back-nav-product-page {
    display: none;
  } */

  .ws-product-image-container {
    display: none;
  }

  .wholesale-product-image {
    height: 5rem;
    width: 5rem;
    margin-right: 1rem;
    border: 1px solid #f4f6f8;
  }

  .ws-product-details-main-container {
    width: 100%;
    margin-top: 1rem;
  }

  .ws-product-other-tab-content,
  .ws-product-tab-content {
    padding: 0rem 0rem 1rem 0rem;
  }

  .ws-product-header-container {
    display: none;
  }

  .ws-product-header-container-min {
    display: flex;
    background-color: #ffffff;
  }

  .ws-product-header-details-min > p {
    margin-bottom: 0.5rem;
  }
  .ws-product-header-details-min > h5 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
  .ws-product-man-details p {
    font-size: 0.8rem;
  }

  .ws-back-nav {
    /* margin-left: 1rem; */
    margin-top: 1rem;
  }

  .ws-product-tab p {
    font-size: 0.8rem !important;
  }

  .ws-product-tab-active p {
    font-size: 0.9rem !important;
  }

  .ws-product-page-parent-container{
    padding: 10%;
    padding-top: 8rem;
  }

  .ws-product-image-wrapper{
    align-items: center;
    padding: .5rem 1rem;
  }

  .ws-product-items-wrapper{
    padding: 0rem .5rem;
  }

  .ws-cd-header-update{
    top: 8.9rem;
  }
  
  .ws-products-sbc-primary-header-update{
    top: 12rem;
  }
}
