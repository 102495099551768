.expiry-parent-container {
  height: 100vh;
  padding-top: 8rem;
}

.expiry-main-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header-banner {
  background-color: rgb(255, 208, 80);
  color: rgb(177, 70, 70);
}

.no-items-container 

.no-items-text {
  margin-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: gray;
}

.expiry-list-main-container {
  margin-top: 0.5rem;
}

.no-orders-image {
  height: 10rem;
  width: auto;
}

.add-expiry-item-container {
  /* position: absolute;
  bottom: 4rem;
  right: 2rem; */
  position: sticky;
  bottom: 3.4rem;
  padding-left: 90%;
}

.add-expiry-item-container button {
  border: 1px solid #48d1cc;
  border-radius: 5rem;
  min-width: 8rem;
  min-height: 3rem;
  background-color: #48d1cc;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.add-expiry-item-container button:hover {
  transform: translateY(-5px);
}

.list-group-item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expiry-item-card {
  width: 50%;
}

.expiry-item-container {
  padding: 0rem 2rem;
}

.expiry-item-button-container > button {
  border: 1px solid #4b4495;
  min-height: 2rem;
  min-width: 5rem;
  border-radius: 5px;
  background-color: #ffffff;
  color: #4b4495;
  transition: all 0.3s;
  box-shadow: 0rem 0.125rem 0.125rem #0000001c;
}

.expiry-item-button-container button:first-child {
  margin-right: 10px;
  border: 1px solid #dd2c00;
  color: #dd2c00;
}

.expiry-item-button-container button:first-child:hover {
  background-color: #dd2c00;
  color: #ffffff;
  transform: translateY(-1px);
  /* transform: rotate(0.5turn); */
}

.expiry-item-button-container button:last-child:hover {
  background-color: #4b4495;
  color: #ffffff;
  transform: translateY(-1px);
}

.pickup-button-container {
  width: 50%;
  margin-top: 2rem;
  /* border:1px solid #48d1cc; */
}

.pickup-button-container p {
  margin: 0px;
}

.pickup-button-container .list-group-item {
  border: none;
}

.pickup-button {
  border: none;
  background-color: #4b4495;
  color: #ffffff;
  width: 100%;
  min-height: 3rem;
  border-radius: 3rem;
  transition: 0.3s all;
  box-shadow: 0rem 0rem 1rem 0.2rem #0000001c;
}

.pickup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0rem 0.125rem 0.125rem #0000001c;
}

.pickup-section2 p:last-child {
  color: #48d1cc;
}

.tabs-container button {
  color: #4b4495 !important;
  font-weight: bold;
  font-size: 15px;
  text-transform: none;
}

.all-expiry-main-container {
  height: 78vh;
  overflow: scroll;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  padding: 0rem 1rem 1rem 1rem;
}

.all-expiry-main-container::-webkit-scrollbar {
  display: none;
}

.expiry-card {
  margin-top: 1rem;
  font-size: 0.9rem;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  width: 50%;
}

.expiry-card:last-child {
  margin-bottom: 1rem;
}

.expiry-card p {
  margin: 0;
}

.expiry-inner-card {
  margin-top: 0.5rem;
}

.expiry-inner-card .card-body {
  padding: 0.5rem 1rem;
  width: 100%;
}

.expiry-infinite-scroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.all-expiry-main-container > div {
  width: 100%;
}

.expiry-type-container {
  position: sticky;
  top: -0.5rem;
  justify-content: space-between;
  width: 50% !important;
  z-index: 2;
}

.order-type-btn {
  width: 49%;
  border-radius: 0.35rem;
  height: 2.5rem;
  border: 1px solid #62e4de;
  color: #000;
  background-color: #fff;
  transition: 0.3s all;
}

.order-type-btn.active {
  background-color: #62e4de;
}

.expiry-type-select
  .css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: #fff;
}

@media only screen and (max-width: 750px) {
  .expiry-item-card {
    width: 100% !important;
  }

  .expiry-item-container {
    padding: 0rem 0.5rem;
    padding-bottom: 4rem;
  }
  .expiry-main-container {
    margin-top: 0.5rem;
  }
  .expiry-card {
    width: 100%;
  }
  .pickup-button-container {
    width: 100%;
  }
  .add-expiry-item-container {
    position:initial ;
    padding: 0;
    width: 100%;
    padding: 0rem 1rem;
  }

  .add-expiry-item-container > button{
    width: 100%;
  }

  .expiry-card .card-body {
    padding: 0;
  }
  .expiry-inner-card .card-body {
    padding: 0;
  }
  .expiry-inner-card .list-group-item {
    padding: 0.3rem;
  }
  .all-expiry-main-container {
    padding: 0rem;
    padding-bottom: 4rem;
  }

  .expiry-type-container {
    width: 100% !important;
    padding: 0;
    padding-bottom: 0.5rem;
    top: -0.5rem;
  }
  .order-type-btn {
    width: 50%;
    border-radius: 0;
  }
}
@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .expiry-main-container {
    margin-top: 0.5rem;
  }
  .expiry-card {
    width: 70%;
  }
  .pickup-button-container {
    width: 70%;
  }
  .expiry-parent-container {
    padding-top: 9rem;
  }

  .expiry-type-container {
    width: 70% !important;
  }
}
