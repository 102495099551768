.wholesale-product-card {
  border-radius: 8px;
  min-width: 18rem;
  margin: 1rem;
  max-width: 18rem;
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 3px 0px;}

.wholesale-product-header {
  border-radius: 8px 8px 0px 0px;
  background-color: #50e6d8;
  height: 6rem;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.wholesale-product-body {
  background-color: #1a4059;
  /* height: 10rem; */
  border-radius: 0px 0px 8px 8px;
}

.dod-badge {
  margin-right: auto !important;
  margin-bottom: 0.2rem;
  height: 1rem;
  /* width: 60%; */
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  background-color: #1a4059;
  color: #ffffff;
  font-size: 0.6rem;
  font-weight: bold;
  box-shadow: 0px 0px 0.2rem rgba(0, 0, 0, 0.2) !important;
  text-transform: uppercase;
}

.wholesale-product-header-content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 1rem;
  overflow-x: scroll;
  /* padding-right: .5rem; */
}

.wholesale-product-header-content::-webkit-scrollbar{
  display: none;
}

.ws-product-med-details > p {
  font-size: 0.6rem;
  margin: 0px;
  color: #317771;
}

.ws-product-med-details > p:last-child {
  font-weight: bold;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image-wrapper > img {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 8px;
}

.wholesale-product-header-content > h6 {
  margin: 0px;
  font-size: 0.9rem;
  color: #053833;
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.stock-details {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.seperator {
  border-bottom: 1px solid #ffffff;
  width: 90%;
  margin: auto;
}

.stock-details div p {
  margin-bottom: 0.3rem;
}
.stock-details > div:first-child {
  font-size: 0.8rem;
  color: #cecfcf;
}

.stock-details > div:last-child {
  font-size: 0.8rem;
  color: #ffffff;
}

.ws-card-ptr{
  color: #9399A4;
  font-size: 0.6rem;
  margin: 0px;
}

.ws-card-ptr > span {
  text-decoration: line-through;
  color: #9399A4;
  font-size: 0.7rem;
  margin-left: .2rem;
  font-weight: bold;
}

.price-details > :nth-child(2) {
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0px;
}

.price-details > :nth-child(3) {
  margin: 0px;
  color: #cecfcf;
  font-size: 0.9rem;
}

.price-details > :nth-child(3) > span {
  color: #1a4059;
  height: 1.5rem;
  width: 4.5rem;
  border-radius: 2px;
  background-color: #02c3b0;
  border: 1px solid #cffffa;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
  margin-top: -0.3rem;
}

.product-card-footer {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.price-details {
  width: 50%;
}

.stock-details > div:nth-child(2) {
  color: #ffffff;
  width: 2%;
  text-align: center;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.ws-card2-main-container {
  min-height: 15.5rem;
  min-width: 15rem;
  max-height: 15.5rem;
  max-width: 15rem;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden !important;
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 3px 0px;
}

.ws-card2-header {
  border-bottom: 1px solid #e8e8e8;
  height: 4rem;
  overflow: hidden;
  /* display: flex; */
  /* align-items: center; */
}

.ws-card2-content {
  width: 70%;
  color: #2a2a2a;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  height: inherit;
  overflow-y: scroll;
}

.ws-card2-content::-webkit-scrollbar {
  display: none;
}
.ws-card2-wrapper {
  width: 30%;
  /* height: 1.2rem; */
}

.ws-card2-cd-tag {
  padding: 0.2rem;
  font-size: 0.7rem;
  font-weight: bold;
  background-color: #02c3b0;
  width: 8rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transform: rotate(40deg);
  position: relative;
  left: 10.5rem;
  margin-top: -2.5rem;
  text-align-last: end;
  text-align: start;
  padding-right: 2.5rem;
}

.ws-card2-body {
  display: flex;
  padding: 1rem;
}

.ws-card2-body p {
  margin: 0px;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.ws-card2-body > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  color: "#7B7A82";
  font-weight: bold;
}

.ws-card2-body > div:nth-child(2) {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.ws-card2-body > div:last-child {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  color: #9399a4;
}

.ws-card2-body > div:nth-child(3) > p:nth-child(2) > span:first-child {
  font-size: 0.7rem;
  text-decoration: line-through;
}

.ws-card2-body > div:nth-child(3) > p:nth-child(2) > span:last-child {
  font-size: 1rem;
  text-decoration: none !important;
  font-weight: bold;
  color: #27324b;
}

.ws-scheme-container>span{
  border: 1px solid #000;
  border-radius: .25rem;
  padding: 0rem .2rem;
  margin-right: .2rem;
}

.ws-scheme-container>span:last-child{
  margin-right: 0;
}

.ws-card2-add-to-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ws-card2-add-to-cart > div {
  width: 85%;
}

.wholesale-product-body-light {
  background-color: #f2f7f7;
}

.stock-details-light > div:first-child {
  color: #9099ab;
}

.stock-details-light > div:nth-child(2) {
  color: #9399a4;
}

.stock-details-light > div:last-child {
  color: #3d4b5f;
}

.seperator-light {
  border-bottom: 1px solid #9099ab;
}

.price-details-light > :nth-child(1) {
  color: #9399a4;
}
.price-details-light > :nth-child(2) {
  color: #000000;
}

.price-details-light > :nth-child(3) {
  color: #3d4b5f;
}

.price-details-light > :nth-child(3) > span {
  color: #03dac5;
  background-color: #1a4059;
  border: none;
}

.dod-main-container {
  background-color: #517187;
  color: #ffffff;
}

.ws-mini-card{
  min-height: 8rem;
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
  cursor: pointer;
}
.section1{
  border: 1px solid #DEDFE0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #ffffff;
  transition: .2s ease-in;
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 3px 0px;

  /* box-shadow: 0px 0px 10px 1px #DEDFE0 ; */
}

.section1:hover{
  transform: translateY(-2px);
  box-shadow: 0px 0px 10px 1px #DEDFE0 ;

}

.ws-mini-image-wrapper{
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.ws-mini-image-wrapper>img{
  height: 4.5rem;
  width: 6rem;
}

.ws-mini-cd-wrapper{
  background-color: #02C3B0;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: .2rem .5rem;
}

.ws-mini-cd-wrapper p{
  font-size: .8rem !important;
}

.ws-mini-details{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #C719B1;
  color: #FFFFFF;
  padding: 0rem .2rem;
  width: 100%;
  border-radius:0px 0px 5px 5px;

}

.ws-mini-card p{
  margin: 0px;
  font-size: .9rem;
}

.ws-mini-product-name-container{
  border: 1px solid #E8E8E8;
  min-height: 8rem;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: -4rem;
  border-radius: 6px;
  transition: 0.3s ease-in-out;
  /* box-shadow: 0px 0px 10px .5px #dedfe0; */
  box-shadow: rgb(149 157 165 / 60%) 0px 2px 3px 0px;


}

.ws-mini-product-name-container:hover{
  /* box-shadow: 0px 0px 10px 1px #dedfe0; */
}

.ws-mini-product-name-container>p{
  padding: .5rem;
}

.ws-mini-product-name-container p{
  margin: 0px;
}

.ws-product-card-max{
  min-width: 23rem;
}

.ws-product-card-max .wholesale-product-header{
  background-color: #FFFFFF;
}

.ws-product-card-max .image-wrapper > img{
  border: 1px solid #F4F6F8;
  box-shadow: rgb(149 157 165 / 60%) 0px 1px 1px 0px;
}

.ws-product-card-max .ws-product-med-details > p:last-child{
  color: #02C3B0;
}
.ws-product-card-max .ws-product-med-details > p{
  color: #9399A4;
}

.ws-product-card-max .wholesale-product-body{
  background-color: #FFFFFF;
}

.ws-product-card-max .stock-details>div:first-child{
  color: #9399A4;
}

.ws-product-card-max .stock-details > div:last-child {
  color: #3D4B5F ;
}

.ws-product-card-max .stock-details > div:nth-child(2){
  color: #9099AB;
}

.ws-product-card-max .price-details > :nth-child(1){
  color: #3D4B5F;
}
.ws-product-card-max .price-details > :nth-child(2){
  color: #000000;
}

.ws-product-card-max .price-details > :nth-child(3){
  color: #3D4B5F;
}

.ws-cd-light>p:first-child{
  text-decoration: line-through;
  margin: 0px;
}

.ws-cd-light{
  display: flex;
  align-items: center;
}

.ws-cd-light>span:nth-child(2){
  border: 1px solid #1A4059;
  background-color: #1A4059;
  color: #03DAC5;
  /* width: 3rem; */
  padding: 0rem .3rem;
  margin-left: .5rem;
  border-radius: 3px ;
}


.ws-product-card-max .price-details > :nth-child(1) {
  text-decoration: none !important;
}

.ws-product-margin-container{
  margin-bottom: .15rem;
}

.ws-product-margin-container>p:first-child {
  color: #3D4B5F;
  margin: 0px !important;
}

.ws-product-margin-container>p:last-child{
  color: #1A4059;
  font-weight: bold;
  background-color: #EFEFF2;
  border: 1px solid #9399A4;
  padding: 0rem .3rem;
  border-radius: 5px;
  margin: 0;

}

.ws-product-margin-container>p:last-child>span{
  color: #1A4059;
  font-weight: 500;
  font-size: .7rem;
  margin-left: .3rem;
}

.ws-company-card{
  min-height: 6rem;
  min-width: 8rem;
  background-color: #FFFFFF;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem;
}

.ws-company-card>img{
  height: 5rem;
  width: 7rem;
}

.ws-company-card-wrapper{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.ws-company-card-wrapper>p{
  text-align: center;
}

.ws-product-scheme-item{
  border: 1px solid #4FE6D8;
  padding: .1rem .2rem;
  border-radius: .25rem;
  margin-right: .2rem;
}

.ws-product-margin-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}