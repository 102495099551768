.wholesale-header-logo {
  height: 2rem;
  width: 9rem;
  cursor: pointer;
}

.wholesale-header {
  background-color: #1a4059;
  padding: 0.5rem 1.5rem;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.notif-icon {
  border: 1px solid #dedfe0;
  border-radius: 4px;
  height: 2rem;
  width: 3rem;
  padding: 0.4rem;
  cursor: pointer;
}

.notif-help {
  border: 1px solid #03dac5;
  margin-left: .5rem;
  color: #ffffff;
  font-size: 0.9rem;
  width: 4.5rem;
}

.icons-wrapper-min {
  display: none !important;
}

.icons-wrapper{
  display: flex !important;
  width: 100%;
  flex-direction: row;
  align-items: center ;
  justify-content: flex-end ;
}


.search-bar-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-content{
  justify-content: flex-end !important;
  height: 100%;
}


.tab-logo{
  height: 1rem;
}

.tab-active{
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: .35rem solid #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 30%;
  /* transition: 0.2s ease-in-out; */
  /* transform: scale(1, 1); */
  transition: border-top 0.15s ease-out
}

.tab{
  border-top: none;
  transition: border-top 0.35s ease-in
}

.wholesale-sub-header{
  /* display: block; */
  position: fixed;
  top: 4rem;
  background-color: #517187;
  color: #ffffff;
  /* padding: .21rem; */
  height: 3.1rem;
  margin-bottom: .6rem;
  z-index: 10;
  width: 100%;
  overflow :hidden;
  margin-left: 0px !important;
}

.ws-update-present{
  top: 5.85rem;
}

@media screen and (max-width: 1000px) {
  .WSearchHeader {
    padding-top: 12px;
  }
}

@media only screen and (max-width: 1001px) and (min-width:751px){
  .wholesale-sub-header{
    top: 7.1rem;
  }
  .ws-update-present{
    top: 8.9rem;
  }
  .xmas_header_fix{
    height: 150px;
  }
}
@media only screen and (max-width: 750px) {
  .wholesale-header-logo {
    height: 4rem;
    width: 11rem;
  }

  .icons-wrapper {
    display: none !important;
  }

  .icons-wrapper-min {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    /* margin-right: 0px; */  
  }
  .wholesale-header-logo{
    height: 2rem;
    width: 9rem;
  }

  .tab-active{
    width: 100%;
  }

  .wholesale-sub-header{
    margin-bottom: 0rem;
    top:6.8rem
  }

  .ws-update-present{
    top: 8.6rem;
  }

  .ws-cart-notify{
    margin-left: 15px;
    margin-top: 4px;
  }
}
