.header {
  width: 100%;
  min-height: 28px !important;
  max-height: fit-content !important;
  z-index: 3 !important;
}
.p-8 {
  padding: 0.5rem;
}
.p-8-1 {
  padding: 0.5rem 0.1rem;
}

.header_outer_div {
  background-color: #4b4495;
}
.header_outer_div_ob {
  background-color: #4b4495;
}
.bg_behalf {
  background-color: #f46d6a !important;
}
.shadow {
  box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.041) !important;
}
.shadow_btm {
  box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.041) !important;
}
.xmas_logo{
  width: 170px;
  height: auto;
}
.network-panel {
  position: sticky;
  top: 0;
  z-index: 10;
  animation: networkShow 0.5s forwards ease;
  transition: all 0.5s;
}

@keyframes networkShow {
  0% {
    transform: translateY(-3rem);
  }

  100% {
    transform: translateY(0rem);
  }
}

.network-panel p {
  padding: 0.3rem;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 0 !important;
  transition: 0.5s ease;
}

.network-panel svg {
  margin-bottom: 3px;
}

.no-network {
  color: #ffff;
  background-color: crimson;
}

.network {
  color: #ffffff;
  background-color: #15920f;
}

.searchBar {
  font-size: 14px !important;
  padding: 8px !important;
  letter-spacing: 1px;
}
.searchBar::placeholder {
   font-size: 12px !important;
}
.inputGroup{
  width: 75% !important;
  margin-right: 3% !important;
}
.inputGroupFullWidth{
  width:100% !important;
}
.form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd !important;
}
.header_icons {
  /* padding: 3px 6px; */
  text-align: center !important;
  font-size: 22px;
  /* margin: 5px auto; */
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.pos_fixed {
  position: fixed;
  z-index: 10 !important;
}
.font_14 {
  font-size: 14px ;
}

.menu_bar > div {
  margin: 0rem 2rem;
  width: 8rem;
}

.menu_bar > div > a:hover {
  color: #17706c !important;
  transition: 0.2s ease-in !important;
}

.menu_bar > div:hover {
  color: #17706c !important;
  transition: 0.2s ease-in !important;
}
.saveo_logo {
  width: 27%;
  /* height: 30%; */
}
.saveo_name {
  width: 100px;
  margin: 0px 10px 0px 15px;
}
.menu_icons_min {
  display: none;
}
.dup_div {
  width: 100%;
  height: 65px !important;
}
.bg_dark {
  background-color: #2f2963;
}
.sub_menu {
  /* padding-top: 65px; */
  position: relative;
  /* background-color: #4b4495 ; */
  box-shadow: 3px 2px 7px 3px #75757533 !important ;
  color: #9399a4;
}
.sub_menu svg {
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
}
.no_wrap {
  white-space: nowrap !important;
}

.cart_icon {
  display: grid;
  place-items: center;
}

.cart_icon > div{
  width: 50px;
  /* padding:3px 5px; */
  height: 33px;
  border: 1px solid #3D377E;
  background-color: #90FFFB;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:'20px' !important;
}

.resp_cart {
  border: 1px solid #3D377E;
  border-radius: 5px;
  background-color: #90FFFB;
  width: fit-content !important;
  max-width: fit-content !important;
  display: grid !important;
  place-items: center;
}

.resp_cart > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart_empty {
  margin-right: 0.5rem;
}

.cart_empty, .cart_empty_max > div {
  background-color: transparent !important;
}

.cart_empty_max > div {
  padding: 3px 5px;
}

.cart_empty_max svg {
  font-size: 22px !important;
}

.cart_empty > div, .cart_empty_max > div {
  border: none !important;
  width: fit-content;
}

.cart_empty svg, .cart_empty_max svg {
  color: #ffffff !important;
}

.cart_icon_cntr {
  width: fit-content !important;
  max-width: fit-content !important;
  border: 1px solid #3D377E;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-right: 0.5rem;
}

.cart_icon svg, .resp_cart svg {
  color: #17706c;
  font-size: 18px;
}
.cart_notification{
  font-weight: bolder;
  text-align: center;
  color: #17706c;
  font-size: 14px;
}
.notification_count {
  font-weight: bolder;
  text-align: center;
  position: relative;
  color: #17706c;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: rgb(209, 42, 42);
  color: white;
  font-size: 12px;
  margin-top: -18px;
  margin-left: 10px;
  bottom: -10px;
}
.cart_notification_whl{
  font-weight: bolder;
  font-size: 14px;
  text-align: center;
  color: #17706c;
  background-color: rgba(255, 255, 255, 0.979);
  border-radius: 50%;
  width: 18px;
  height: 18px;
 position: absolute;
 margin-top: -8px;
 margin-left: 10px;

}
.cart_notify_behalf {
  background-color: #4b4495 !important;
}
.notification_hover_parent {
  width: 450px !important;
  height: 50vh;
  border: 2px solid rgba(128, 128, 128, 0.164);
  background-color: #f0fbfb;
  position: absolute;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-left: -14% !important;
  overflow: hidden;
  border-radius: 10px;
}

/* Second header design */
.header_2 {
  background-color: white !important;
  width: 100vw !important;
  border: 1px solid #CAF1EF !important;
}

.mobile_footer {
  display: none;
  width: 100%;
  position: fixed;
  padding: 2px 20px;
  height: fit-content;
  background-color: white;
  z-index: 5;
  bottom: 0%;
  font-size: 12px;
  border-top: 1px solid #4b4495;
}
.footer_icons {
  font-size: 25px;
}
.footer_sec {
  margin: 0px 25px;
}
.report-issue-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5bd2cc;
  border-radius: 3px;
  height: 30px;
  width: 200px;
  transition: 0.3s all;
}

a.dropdown-item:has(div.report-issue-container, div.q-and-a-container) {
  background-color: transparent !important;
  box-shadow: none !important;
}

.report-issue-container > a {
  text-decoration: none !important;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
.report-issue-container:hover,
.q-and-a-container:hover {
  box-shadow: 1px 1px 5px #5bd2cc;
  letter-spacing: 1px;
}
.report-issue-container > a:hover {
  color: white;
}
.q-and-a-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5bd2cc;
  height: 30px;
  width: 200px;
  border-radius: 3px;
  transition: 0.3s all;
}
.q-and-a-container > a {
  text-decoration: none;
  color: #5bd2cc;
  font-weight: bold;
  font-size: 12px;
}

.q-and-a-container > a:hover {
  color: #5bd2cc;
}

.search-autocomplete-container-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 1;
  z-index: 1;
  top: 3.75rem;
  left: 0;
}

.search-autocomplete-container-update {
  top: 10rem;
}

.add-backdrop {
  background-color: rgba(0, 0, 0, 0.248);
}
.bg_white {
  background-color: white !important;
}
.remove-backdrop {
  background-color: transparent;
}

.search-autocomplete-container {
  margin-left: 0.2rem;
  display: block;
  position: fixed;
  background-color: #f2f7f7;
  color: #d7d1d1;
  z-index: 1;
  max-height: 30rem;
  height: 30rem;
  width: 48.35%;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  overflow-y: scroll;
  padding: 0px !important;
  box-shadow: 6px 6px 24px #0000001f;
  padding-bottom: 3rem !important;
  margin-top: -0.7rem;
}

.search-autocomplete-container::-webkit-scrollbar {
  display: none;
}

.show-autocomplete-header {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 2rem;
  padding: 0.5rem 1rem;
}

.show-autocomplete-header p {
  margin: 0px;
}

.show-autocomplete-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 100%;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.2s all;
}

.show-autocomplete-content > div:last-child {
  margin-left: 1rem;
}

.show-autocomplete-content div:last-child p {
  margin: 0px;
  color: #6e6e6e;
  font-size: 0.9rem;
}

.show-autocomplete-content div:last-child p:hover {
  font-weight: bold;
  letter-spacing: 0.2px;
}

.item-active {
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 0.6rem rgba(20, 20, 20, 0.25));
  z-index: 10;
  /* box-shadow: 0px 3px 6px #9399a41f !important; */
}

/* .show-autocomplete-content.item-active {
} */

.show-autocomplete-content.item-active div:last-child p {
  font-weight: bold;
  letter-spacing: 0.2px;
}

.product-autocomplete-table {
  background-color: #ffffff;
  width: 100%;
  padding: 0.5rem;
}

.product-autocomplete-table > thead > tr {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 99;
  height: 3rem;
}

.product-autocomplete-table th,
td {
  padding: 0.2rem 0.5rem;
  text-align: start !important;
}

.product-autocomplete-table th {
  text-transform: uppercase;
  font-weight: bold;
  color: #939393;
  font-size: 0.9rem;
}

.product-autocomplete-item {
  height: 5rem;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  margin: 10px 0px;
  transition: 0.1s ease-in;
}

.product-autocomplete-item td {
  vertical-align: top;
}

/* .product-autocomplete-item > td:nth-child(2),
.product-autocomplete-item > td:nth-child(3),
.product-autocomplete-item > td:nth-child(4),
.product-autocomplete-item > td:nth-child(5),
.product-autocomplete-item > td:nth-child(6),
.product-autocomplete-item > td:nth-child(7) {
  text-align: center;
} */

.product-autocomplete-item p {
  margin: 0px;
}
.search_dd_product_div{
  color: #393939;
  font-size: 1rem;
  font-weight: bold;
  word-wrap: break-word;
}
.search_dd_product_parent_div{
  align-items: center;
}
.product-autocomplete-item > td:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-autocomplete-item > td:first-child > div:first-child {
  color: #393939;
  font-size: 1rem;
  font-weight: bold;
}

.product-autocomplete-item > td:first-child > div:last-child {
  color: #48d1cc;
  font-size: 0.9rem;
  text-transform: capitalize;
}
.search_dd_cd_tag{
  color: #7e6322;
  background-color: #fff7af;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  font-size: 0.8rem;
  width: fit-content;
  /* margin-left: 17rem; */
  /* margin-top: -3rem; */
  width: 4rem;
  text-align: center;
  height: fit-content;
  white-space: nowrap;
}
.product-autocomplete-item > div:nth-child(2) {
  color: #7e6322;
  background-color: #fff7af;
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  font-size: 0.8rem;
  width: fit-content;
  margin-left: 17rem;
  margin-top: -3rem;
  width: 4rem;
  text-align: center;
}

.product-autocomplete-item > td:nth-child(3) > div {
  color: #393939;
  font-size: 0.9rem;
  font-weight: bold;
}

.product-autocomplete-item > td:nth-child(4) > div {
  display: flex;
  flex-direction: column;
}

.product-autocomplete-item > td:nth-child(4) > div div {
  color: #393939;
  padding: 0.2rem 0.5rem;
  background-color: #efeeff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.8rem;
  margin: 0.2rem;
  width: 4.5rem;
  text-align: center;
}

.product-autocomplete-item > td:nth-child(5) > div,
.product-autocomplete-item > td:nth-child(6) > div,
.product-autocomplete-item > td:nth-child(7) > div {
  color: #393939;
  font-weight: bold;
  font-size: 0.9rem;
}

.search_dd_delivery_slot{
  position: absolute;
  margin-left: -20.6vw;
  margin-top: 1.5rem;
  width: 20vw;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.5rem;
  justify-content: end !important;
  padding-top: 10px;
  white-space: nowrap;
  text-align: end;
}
.search_dd_delivery_slot_2{
  position: absolute;
  margin-left: -15.6vw !important;
  margin-top: 1.5rem;
  width: 15vw !important;
  display: flex;
  flex-wrap: wrap;
 flex-wrap: wrap;
  padding-left: 0.5rem;
  justify-content: end;
  padding-top: 10px;
  white-space: wrap !important;
  word-wrap: break-word;
}
.search_dd_delivery_slot_2 > p {
  color: #c6c6c6;
  font-size: 0.7rem;
  width: 16.5rem;
}

.search_dd_delivery_slot > p {
  color: #c6c6c6;
  font-size: 0.7rem;
  width: 16.5rem;
}

.product-quantity-input-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: absolute;
  margin-top: -4rem;
}

.product-quantity-input-container p {
  margin: 0px;
}

.product-quantity-input-container > div:first-child {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.product-quantity-input-container > div:first-child {
  display: flex;
  align-items: center;
}
.product-quantity-input-container > div:first-child > input {
  width: 4rem;
  height: 2.2rem;
  border: 1px solid #616161;
  border-radius: 0.25rem;
}

.product-quantity-input-container > div:first-child > p {
  color: #808080;
  font-size: 0.8rem;
}

.total-amount-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.5rem;
  background-color: #ffffff;
  /* border: 1px solid #9C9C9C; */
  border-radius: 0.25rem;
  padding: 0.1rem 0.2rem;
  height: 2.2rem;
  min-width: 8.1rem;
  /* box-shadow: 0px 2px 3px 2px rgba(10, 10, 10, 8%) !important; */
}

.total-amount-container div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.total-amount-container > div:last-child {
  margin-left: 0.2rem;
}

.total-amount-container div p {
  font-size: 0.8rem;
  color: rgb(147, 147, 147);
  text-transform: uppercase;
}

.total-amount-container > div:last-child > p {
  font-weight: bold;
  color: #393939;
}

.product-quantity-input-container > div:first-child > div > p:nth-child(5) {
  font-weight: bold;
  color: #393939;
}

.product-quantity-input-container > div:first-child > div > p:nth-child(4) {
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.product-quantity-input-container > div:last-child > button {
  background-color: #48d1cc;
  color: #ffffff;
  font-weight: bold;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  box-shadow: 0px 3px 6px #93d3d1;
  width: 84px;
  height: 32px;
}

.product-autocomplete-item.secondary-state {
  height: 8rem;
}

.search-cart-container {
  background-color: #f8f8f8;
  box-shadow: 6px 6px 24px #0000001f;
  z-index: 10;
  top: 5.2rem;
  display: block;
  position: fixed;
  max-height: 30rem;
  min-height: 30rem;
  width: 28.4% !important;
  border-radius: 0.25rem;
  overflow-y: scroll;
  padding: 0px !important;
  margin-left: 0.5rem;
  /* left: 65.5%; */
  padding-bottom: 6rem !important;
}
.search-cart-container::-webkit-scrollbar {
  display: none;
}

.search-cart-container p {
  margin: 0px;
}

.search-cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #f8f8f8;
}

.search-cart-header > p:last-child:hover {
  color: crimson;
  font-size: 0.95rem;
}

.search-cart-header p {
  color: #808080;
  font-size: 0.9rem;
  transition: 0.2s all;
  cursor: pointer;
  text-transform: uppercase;
}

.search-cart-header p > span {
  font-size: 0.5rem;
  position: absolute;
  margin-left: -2.5rem;
  margin-top: 1.2rem;
}

.search-cart-item-group1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.search-cart-item-group1 > div:first-child > p:first-child {
  font-weight: bold;
  color: #393939;
  font-size: 0.9rem;
}

.search-cart-item-group1 > div:first-child > p:last-child {
  /* font-weight: bold; */
  color: #48d1cc;
  font-size: 0.8rem;
}

.search-cart-item-group1 > div:last-child {
  display: flex;
  align-items: center;
}

.search-cart-item-group1 > div:last-child > p {
  color: #808080;
  font-size: 0.9rem;
}

.search-cart-item-group1 > div:last-child > input {
  min-width: 3.313rem;
  max-width: 3.313rem;
  min-height: 1.75rem;
  max-height: 1.75rem;
  margin-right: 1rem;
  border: 1px solid #616161;
  border-radius: 0.25rem;
}

.delete-cart-icon {
  color: #c9c9c9;
  transition: 0.3s all;
  cursor: pointer;
}

.delete-cart-icon:hover {
  color: crimson;
  transform: translateY(-2px);
}

.search-cart-item-group2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-cart-item-group2 > div:first-child {
  display: flex;
  align-items: center;
}

.search-cart-item-group2 > div:first-child > p:first-child {
  color: #808080;
}

.search-cart-item-group2 > div:first-child > p:last-child {
  color: #1a1c1c;
  font-weight: bold;
}

.search-cart-item-group2 > div:last-child > p {
  color: #808080;
  font-size: 0.8rem;
}

.search-cart-item-group3 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-top: 0.5px solid #c5c3c3;
  margin-top: 0.5rem;
}

.search-cart-item-group3 > p {
  color: #c5c3c3;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.search-cart-item-card {
  transition: 0.2s all;
}

.search-cart-item-card-body {
  padding: 0.6rem 1rem !important;
}

.hide-search-cart-container {
  z-index: 10;
  top: 3.1rem;
  /* left: 95.05%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-height: 30.1rem;
  min-height: 30.1rem;
  width: 2rem;
  border-radius: 0.25rem;
  padding: 0px !important;
  transition: 0.3s all;
}
.hide--container {
  z-index: 10;
  top: 3.1rem;
  /* left: 65.81%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  max-height: 30.1rem;
  min-height: 30.1rem;
  width: 2rem;
  border-radius: 0.25rem;
  padding: 0px !important;
  transition: 0.3s all;
}
.hide--container > div {
  background-color: #ffffff;
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
}

.hide--container > div:hover {
  box-shadow: 6px 6px 24px #0000001f;
}

.hide-search-cart-container > div {
  background-color: #ffffff;
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;
}

.hide-search-cart-container > div:hover {
  box-shadow: 6px 6px 24px #0000001f;
}
.search_shortKey_tag {
  background-color: white !important;
  font-size: 12px !important;
  padding: 0px;
  color: rgba(128, 128, 128, 0.488) !important;
  border: none !important;
}

.search_shortKey_tag > span {
  color: rgba(128, 128, 128, 0.7) !important;
}

.search_icon {
  overflow: hidden;
  transition: all 0.2s ease-in;
  font-size: 20px;
}
.search_icon:hover{
  color: rgba(0, 0, 0, 0.812) !important;
  font-size: 23px;
}
.proceed-to-checkout-container > div {
  width: 28.3%;
  background-color: #ffffff;
  color: #808080;
  border: none;
  text-align: center;
  position: fixed;
  top: 29.25rem;
  z-index: 10;
  height: 3rem;
  border-bottom: 1px solid #0000001f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.net-price-container > p > span:first-child {
  font-weight: bold;
  color: #1a1c1c;
}

.net-price-container > p > span:last-child {
  font-size: 0.7rem;
  color: #808080;
  margin-left: 0.3rem;
}

.proceed-to-checkout-container > button {
  width: 28.3%;
  background-color: #ffffff;
  color: #4b4495;
  border: none;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  position: fixed;
  top: 32.25rem;
  z-index: 10;
  height: 3rem;
  /* left: 74.2%; */
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.proceed-to-checkout-container > button > span {
  color: #c6c6c6;
  font-size: 0.8rem;
  margin-top: -1rem;
}

.proceed-to-checkout-container > button:hover {
  background-color: #4b4495;
  color: #ffffff;
  font-weight: bold;
  /* transform: translateY(2px) */
}

.quantity-input-error {
  color: crimson !important;
  font-size: 0.8rem !important;
  position: absolute;
  top: 2rem;
  margin-left: 0px !important;
}

.available-quantity {
  color: #4caf50 !important;
  font-size: 0.8rem !important;
  position: absolute;
  top: 2.6rem;
  margin-left: 0px !important;
}

.available-quantity > span {
  color: #393939;
  font-weight: bold;
}

.view-all-container {
  position: fixed;
  top: 32.25rem;
  z-index: 10;
  width: 48.3%;
}

.view-all-container > button {
  width: 100%;
  border: none;
  height: 3rem;
  background-color: #ffffff;
  color: #4b4495;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.view-all-container > button:hover {
  background-color: #4b4495;
  color: #ffffff;
  font-weight: bold;
}

.view-all-container > button > span {
  color: #c6c6c6;
  font-size: 0.8rem;
}

header {
  z-index: 10;
}

.upload_cart_modal_btn {
  display: flex;
  align-items: center;
  height: fit-content;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background-color: transparent;
  transition: all 0.25s ease;
  width: 85px !important;
  padding: 5px 5px;
}

.upload_cart_modal_btn:hover {
  transform: translateY(-2px);
}

/* .upload_cart_modal_btn svg {
  font-size: 1.8rem;
} */
.ws-footer-tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 3rem;
}

.ws-footer-tab-active {
  height: 0.4rem;
  min-width: 250%;
  background-color: #48d1cc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -3px;
}

.update-available-banner {
  position: sticky;
  top: 0;
  background-color: #e9e6fd;
  z-index: 10;
  animation: slideFromTop 0.5s forwards ease;
  transition: all 0.5s;
}
.contact_snackbar{
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
  color: #075E54;
  z-index: 10;
  animation: slideFromTop 0.5s forwards ease;
  transition: all 0.5s;
  cursor: pointer;
  text-decoration: none;
  width: 100% !important;
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-3rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

.update-available-banner > p {
  cursor: default;
  font-weight: 500;
  font-size: 0.8rem;
  color: #4b4495;
  padding: 0.3rem;
  margin: 0px;
  text-align: center;
  transition: 0.5s ease;
}

.update-available-banner > p > span {
  text-decoration: underline;
  cursor: pointer !important;
}

.contact_snackbar > .marquee {
  background-color: #DBF8C6;
  font-weight: 500;
  font-size: 0.8rem;
  color: #075E54;
  padding: 0.3rem;
  margin: 0px !important;
  text-align: center;
  transition: 0.5s ease;
  width: 100%;

}

.header-lottie {
  height: 29px;
  width: 160px;
}
.firstSpan{
  margin-right: 12%;
}
.firstSpan_innerDiv{
  margin-right: 10%;
}
.secondSpan_innerDiv{
  margin-right: 10% ;
}

@media screen and (max-width: 1040px) {
  .dummy_div {
    display: none;
  }
}

@media screen and (min-width: 1001px) {
  .mob_menu {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .web_menu {
    display: none;
  }

  .header_2 {
    background-color: transparent !important;
    border: none !important;
  }

  .searchHeader {
    padding-top: 12px;
  }
  .inputGroup{
    width: 100% !important;
    padding-top: 12px !important;
    margin-right: 0% !important;
  }
}

.chat_header_menu{
  margin: 0.2rem 0;
  background-color: #dcf8c6 !important;
  /* color: white !important; */
  color: #075E54 !important;
  padding: 3px 10px;
  border-radius: 4px;
  /* border: 2px solid #1d19506f; */
  font-size: 12px !important;
}
.chat_dropdown{
  margin-left: -10px !important;
  margin-top: 3px !important;
  max-width: 100px !important;
  padding:6px 10px !important;
  text-align: center !important;
}
.wtsapp_qr{
  width: 120px;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: -10px;
}
.qr_nmbr{
  color: #075E54;
  font-weight: bolder;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 14px;
}
.qr_redirect_btn{
  background-color: #EFFFE3 !important;
  color: #075E54 !important;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
  width: 85%;
  margin: 5px auto 0px auto !important;
}
.qr_redirect_btn:hover{
  background-color:#DCF8C6 !important;
}
.menu_wtsapp{
  width: 20px;
}
.menu_wtsapp_mini{
  width: 18px;
}

.notification-po-header {
  padding: 1rem;
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.notification-card {
  min-height: 6rem;
  height: fit-content;
  width: 20rem;
  padding: 0rem 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  display: flex;
}

.notification-card {
  color: #000;
}

.notif-permission-panel{
  width: 20rem;
  background-color: #0000001f;
  padding: 1rem;
}

.rotate{
  -moz-transition: all .1s linear;
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
}

.rotate.down{
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

.popover-arrow{
  transform: rotate(-90deg);
}
.searchHeader{
  padding-top: -2px;
  text-align: end !important;
}
.searchBarDiv{
  width: 100%;
  display: flex;
  justify-content: end !important;
  text-align: end !important;
}
.icons_div{
  display: flex;
  justify-content: end;
  align-items: center !important;
  height: 65px;
}
.icons_div > div {
    margin: 0px 3.5%;
    border: 1px solid #3d377ec2;
    border-radius: 5px;  
     /* height: 30px !important;
    overflow: hidden !important; */
}

.target_dot{
  width: 10px !important;
  height: 10px !important;
  background-color: #FF9828 !important;
  border: 1px solid #4b4495;
  border-radius: 50%;
  position: absolute;
  margin-top: 0px;
  margin-left: 12px;
}
.target_dot_footer{
  width: 10px !important;
  height: 10px !important;
  background-color: #FF9828 !important;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  margin-top: 0px;
  margin-left: 20px;
}

.searchType{
  padding: 0px !important;
  background-color: #F3F3F3 !important;

}
.searchType > .dropdown-toggle{
  background-color: #F3F3F3 !important;
  border: none !important;
  color: #707070 !important;
  font-size: 14px !important;
}
.searchType > .dropdown-toggle:focus{
  box-shadow: none !important;
}


@media only screen and (max-width: 750px) {
  .menu_icons_max {
    display: none;
  }
  .menu_icons_min {
    display: block;
  }
  .saveo_logo {
    width: 30px;
  }
  .xmas_logo {
    width: 60%;
  }
  .saveo_name {
    width: 70px;
    margin: 1px 5px 0px 7px;
  }
  .searchHeader {
    padding-top: 8px;
  }
  .menu_bar {
    display: none !important;
  }

  .mobile_footer {
    display: block;
    font-size: 14px !important;
  }
  .footer_icons {
    font-size: 20px !important;
  }

  .header-lottie {
    height: 20px;
    width: 100px;
  }

  .header_outer_div {
    background-size: 80%;
  }
  .header_outer_div_ob {
    background-size: 80%;
  }

  .secondSpan_innerDiv{
    margin-right:10px ;
    font-size: 10px;

  }
  .secondSpan_innerDiv2{
    font-size: 10px;
  }
  .firstSpan{
    display: none;
  }
 
  .notification-icon {
    /* height: 1rem; */
    width: 1rem;
  }
  .notification-wrapper{
    display: block !important;
  }

  .popover-arrow{
    display: none;
  }
  .inputGroup{
    width: 100% !important;
    padding-top: 2px !important;
    margin-right: 0% !important;
  }
}
@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .header {
    height: 40px;
  }
  .menu_icons_max {
    display: none;
  }
  .menu_icons_min {
    display: block;
  }
  .menu_icons_min > svg {
    font-size: 33px !important;
    padding-top: 5px;
  }
  .saveo_logo {
    width: 7%;
  }
  .xmas_logo {
    width: 30%;
  }
  .saveo_name {
    width: 20%;
    margin: 1px 5px 0px 9px;
  }
  .menu_bar {
    display: none !important;
  }
  .mobile_footer {
    display: block;
  }
  .header_outer_div {
    background-size: 40%;
  }
  .header_outer_div_ob {
    background-size: 40%;
  }
  .secondSpan_innerDiv{
    margin-right:40px ;
  }
  .firstSpan{
    margin-right: 30px ;
  }
  .firstSpan_innerDiv{
    margin-right: 30px;
  }
  .secondSpan_innerDiv2{
    display: none;
  }
  
  .notification-icon {
    margin-top: 0;
  }

  .notification-wrapper{
    display: block !important;
  }

  .popover-arrow{
    display: none;
  }
  .inputGroup{
    width: 100% !important;
    padding-top: 12px !important;
  }
}

.settingIcon {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  color: #4b4495 !important;
}

.notification-card{
  min-height: 6rem;
  height: fit-content;
  width: 20rem;
  padding:1rem;
  cursor: pointer;
  display: flex;
}

.notification-card{
  color: #000;
}

.notif-card-text {
  overflow: hidden;
  width: 17rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bell-shake {
  animation: bell 1.3s ease-out;
  transform-origin: center top;
}

.notification-count {
  background-color: crimson;
  color: #fff;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  padding: 10px;
  margin-left: -12px;
  position: absolute;
  margin-top: 10px;
  text-align: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.productNameDiv {
  max-width: 240px !important;
  font-size: 15px !important;
}

@keyframes bell {
  0% {transform: rotate(35deg);}
  12.5% {transform: rotate(-30deg);}
  25% {transform: rotate(25deg);}
  37.5% {transform: rotate(-20deg);}
  50% {transform: rotate(15deg);}
  62.5% {transform: rotate(-10deg)}
  75% {transform: rotate(5deg)}
  100% {transform: rotate(0);}  
}
.marquee {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 29px !important;
}

.marquee > div {
  /* position: absolute; */
  width: 100%;
  margin: 0;
  text-align: center;
  -moz-animation: scroll-right 40s linear infinite;
  -webkit-animation: scroll-right 40s linear infinite;
  animation: scroll-right 40s linear infinite;
}
.marquee > div:hover{
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}
.text_underline{
  text-decoration: underline;
}
@-moz-keyframes scroll-right {
  0% {
      -moz-transform: translateX(70%);
  }
  100% {
      -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-right {
  0% {
      -webkit-transform: translateX(70%);
  }
  100% {
      -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-right {
  0% {
      -moz-transform: translateX(70%);
      -webkit-transform: translateX(70%);
      transform: translateX(70%);
  }
  100% {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
  }

}

.notif-popover>.MuiPaper-root::-webkit-scrollbar{
  display: none;
}
