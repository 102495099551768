.onboarding-container {
  height: 100vh;
  background-color: #4d6495;
  width: 100%;
}
.header-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  /* margin: 10px 10px */
  padding: 10px 10px;
}

.header-title-os5 {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.5rem;
  padding-left: 15px;
  margin: 0px;
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  /* top: 30%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%);  */
}

.saveo-logo-OS5 {
  width: 170px;
  height: 200px;
}

.greeting-header {
  color: #f7b737;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 50px;
}
.info-text {
  color: #cad1df;
  font-size: 1rem;
  margin-top: 20px;
}

.verification-details-container {
  position: fixed;
  bottom: 0px;
  background-color: #ffffff;
  box-shadow: 10px 10px 22px #07151411;
  /* margin-top: 30px; */
  width: 100%;
  /* height: 100% */
  height: 20em;
  margin-top: 145px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}

.details-header {
  color: #2a2a2a;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
}

.items-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  margin: 10px 10px;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  margin: 20px 135px;
}

.item-container > p {
  margin: 0px;
}

.item-text {
  margin-left: 25px !important;
  color: #6d6d6d;
  font-size: 1rem;
  font-weight: 450;
}

.view-button {
  margin-left: auto !important;
  color: #48d1cc;
  font-weight: bold;
  font-size: 1rem;
}

@media only screen and (max-width: 750px) {
  .items-container {
    width: 100% !important;
    margin: 20px 0px;
  }

  .onboarding-container {
    padding: 0px;
    height: 90vh;
  }

  .items-container {
    width: auto;
    height: 100%;
  }
  .item-container {
    margin: 20px;
    padding: 0px 15px;
  }

  .item-text {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .onboarding-container {
    padding: 0px;
  }
}
