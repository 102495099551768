.close-modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 100%; */
}

.close-modal * {
  transition: all 0.2s ease 0s;
  cursor: pointer;
  outline: none;
}

.close-modal *:hover {
  color: red;
  /* color: #fff; */
}

.modal-body-info {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-body-info p {
  margin-top: 1%;
  font-size: small;
}

.agreement-modal-footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: none;
}

.agreement-modal-footer p {
  margin-top: 1%;
  font-size: small;
  width: 100%;
  color: gray;
}

.agreement-modal-footer button {
    margin: 1rem auto;
  width: 70%;
  border-radius: 20px;
  min-height: 2.5rem;
  background-color: #48d1cc;
  color: white;
  letter-spacing: 0.1rem;
  font-weight: 500;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

.agreement-modal-footer button:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-1px);
}

.agreement-modal-content{
    /* border-radius: 10px !important; */
    min-height: 10rem;
}