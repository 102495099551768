.r_cart_top {
  background-color: #ffffff;
}

.r_cart_top > div {
  display: flex;
  justify-content: space-between;
}

.r_cart_top h6,
.r_cart_bot h6,
.r_cart_bot p {
  color: #4b4495;
  font-weight: 600;
  font-size: 14px;
}

.r_cart_top h6 span {
  margin-left: 0.3rem;
  font-weight: 400;
}

.r_cart_top svg {
  color: #817db5;
  cursor: pointer;
}

.r_cart_top p {
  margin: 0;
  color: #494949;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.r_cart_top p span {
  color: #9399a4;
  text-decoration: underline;
  cursor: pointer;
}

.r_cart_bot {
  display: grid;
  place-items: center;
  background-color: #e9e8fa;
  border: 1px solid #3d377e;
  cursor: pointer;
}

.r_cart_bot p {
  margin: 0;
}

.r_cart_bot p span {
  font-size: 12px;
  margin-left: 0.3rem;
  color: #494949;
}

/* Right cart card */
.r_cart_mid {
  background-color: #e2f4f3;
  max-height: 66vh;
  overflow-y: scroll;
  animation: openCartCard 0.1s forwards ease-in;
}

@keyframes openCartCard {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.r_cart_mid::-webkit-scrollbar {
  display: none;
}

.r_cart_card {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.r_cart_card:not(:last-child) {
  margin-bottom: 0.5rem;
}

.rc_card_top {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.rc_card_top h6 {
  width: 70%;
  font-size: 14px;
  font-weight: 600;
  color: #494949;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rc_card_top p {
  display: flex;
  flex-direction: column;
  align-items: end;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.rc_card_top p span {
  color: #9399a4;
  font-size: 12px;
  font-weight: 400;
}

.r_cart_card > p {
  padding: 0rem 0rem 0.5rem 0.5rem;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #9399a4;
}

.rc_card_bottom {
  font-size: 12px;
  padding-bottom: 0.5rem;
  align-items: center;
}

.r_cart_margin {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e7fffe;
  border: 1px solid #48d1cc;
  border-left: transparent;
  border-radius: 0 5px 5px 0;
  line-height: 1;
  color: #4b4495;
  font-weight: 600;
}

.r_cart_margin span {
  font-weight: 400;
  color: #17706c;
}

.rc_card_actions {
  align-items: center;
}

.rc_delete_btn {
  color: #a1a1a19c;
  margin: 0 1rem;
  font-size: 20px;
  cursor: pointer;
}

/* Error Messsage */
.cart_tb_error {
  animation: errorOpen 0.25s forwards ease-in;
}

@keyframes errorOpen {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cart_tb_error p {
  margin: 0;
  font-size: 12px;
  background-color: #dc143c1c;
  color: crimson;
  text-align: center;
}
