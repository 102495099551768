.add-medicine-form-container>div{
    /* width: 100%; */
    color: #6d699b28;
}

.medicine-form-submit-button{
    border: 1px solid #48d1cc;
  border-radius: 5px;
  min-width: 8rem;
  min-height: 3rem;
  background-color: #48d1cc;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  margin-top: 5px;
}

.add-medicine-form-content{
    border-radius: 10px !important;
}

.medicine-form-submit-button:hover{
    transform: translateY(-5px);

}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #4B4495 !important;
    color: #4B4495 !important;
}

.MuiInputLabel-root.Mui-focused {
    color: #4B4495 !important;
}

.suggestion-container{
    background-color: #f5f5f5;
    position: absolute;
    max-height: 15rem;
    min-height: 15rem;
    overflow-y: scroll;
    /* border: 1px solid gray; */
    z-index: 1000;
    margin-top: -4rem;
    width: 93.5%;
    transition: 0.3s;
    border-radius: 5px;
}


.suggestion-container > li{
    color: black;
    /* font-weight: bold; */
    /* font-size: .8rem !important; */
    font-size: small;
    font-weight: 400;
    /* letter-spacing: .1rem; */
    font-family: 'Ubuntu', sans-serif !important;

}