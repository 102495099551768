.productCard_parent{
    width: 280px ;
    height: 150px;
    display: table;
    margin:10px;
    padding: 15px;
    border-radius: 10px;
    background-color: white ;
    border: 1px solid #d8d8d8 ;
    overflow: hidden;
}

.productCard_parent2{
    width: 370px ;
    height: fit-content;
    display: table;
    margin:20px;
    padding: 15px;
    border-radius: 10px;
    background-color: white ;
    border: 2px solid #F2F2F2 ;
    transition:1s  cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow: hidden;
    position: relative;
}
.productCard_parent2:hover{
    /* box-shadow: 0 0px 10px 8px rgba(75, 75, 75, 0.068); */
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
    
}
.max_qty_error{
    font-size: 12px;
    padding: 5px;
    
}
.product_img{
    width: 60px !important;
    height: 50px !important;
    border-radius: 5px !important;
    border: none !important;
    background-color: white;
    border: 1px solid #8a8a8a21 !important;
}
.product_img2{
    width: 85px !important;
    height: 70px !important;
    border-radius: 5px !important;
    border: 2px solid #F2F2F2 !important;
}
.product_margin{
    background-color: #E7FFFE;
    border: 1px solid #E5F7F7;
    border-radius: 5px;
}

.scheme{
    color: #926023;
    background-color: #FBF796;
    border: 1px solid #F8ED39;
    border-radius: 3px;
    padding: 3px 6px;
    font-size: 13px;
}

.scheme span:nth-child(2){
    font-weight: bolder;
}

.scheme:not(:last-child){
    margin-right: 0.5rem;
}

.card_mrp{
    color: #4B4495;
    font-size: 13px;
    align-items: center;
    padding: 3px 6px;
}

.card_mrp span:nth-child(2){
    font-weight: bolder;
    font-size: 11px;
}

.brdr_grey{
    border: 1px solid #F2F2F2 !important;
}
.marign_top_10{
    margin:10px auto !important;
}
.product_name{
    width: 200px;
    font-size: 14px;
    color: #717171;
}
.product_name p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}
.product_name2{
    font-size: 14px;
}
.tooltip-inner {
    background-color: #106360;
    font-size: 12px;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: #106360 !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: #106360 !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #106360 !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #106360 !important;
}
.product_hr {
    background-color: #9399A4;
}
.product_ptr {
    color: #494949;
    font-size: 14px;
    font-weight: 600;
}
.product_ptr sup {
    padding: 0 0.25rem;
    color: #68737A;
}
.product_margin {
    color: #17706C;
    padding: 0.2rem 0.5rem;
    background-color: #E7FFFE;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid #E5F7F7;
    border-radius: 3px;
}
.product_margin span {
    font-size: 11px;
    font-weight: 400;
}
.product_add_btn_cart_gen{
   background-color: #F46D6A !important;
   color: white !important;
   border: none !important;
}
.product_add_btn_cart_gen_minus{
    background-color: #FAFAFA !important;
    color: #F46D6A !important;
    border: none !important;
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.076);
}
.product_add_btn{
    cursor: pointer;
    color: #4B4495;
    font-size: 12px;
    display: grid;
    place-items: center;
    width: 50px;
    height: 30px !important;
    border: 1px solid #AFA8F6;
    border-radius: 3px;
    background-color: #DCD9FC;
    transition: all 0.25s ease-in;
}
.product_add_btn:hover{
    color: #DCD9FC;
    background-color: #4B4495;
}
.pc2_add {
    display: flex;
    align-items: center;
    width: fit-content !important;
}
.pc2_add span {
    font-weight: bolder;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid #AFA8F6;
}
.product_add_btn2{
    font-size: 12px;
    padding: 7px 15px;
    height: 30px !important;
}
.price_section{
    margin: 10px 10px 0 2px;
}
.manf_cntr {
    width: 200px;
}
.manf_link{
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
}
.body_inStock{
  width: 70px;
  border-right: 1px solid #F2F2F2;
}
.body_inExpiry{
    width: 70px;
    padding-left: 10px;
    border-right: 1px solid #F2F2F2;
}
.flashSale_tag{
    font-size: 10px;
    font-weight: bold;
    background: rgb(31,1,84);
    background: linear-gradient(90deg, #504097 0%, rgb(214, 31, 231) 100%);
    width: fit-content;
    padding:1px 5px;
    border-radius: 3px;
    color: white;
}
.deal_tag{
    font-size: 10px;
    font-weight: bold;
    background: #DADAFD !important;
    width: fit-content;
    padding:1px 5px;
    border-radius: 3px;
    margin-bottom: 5px;
}
.special_tag{
    font-size: 10px;
    font-weight: bold;
    background: #217F78 !important;
    width: fit-content;
    padding:1px 5px;
    border-radius: 3px;
}
.multiplyFactor_tag{
    font-size: 10px;
    font-weight: bold;
    width: fit-content;
    padding:1px 5px;
    border-radius: 3px;
    color: #c3c3f8;
}
.product_header{
    padding-bottom: 10px;
    border-bottom: 1px solid #F2F2F2 ;
}
.deals_section{
    border: 1px solid #e6e4e4;
    background-color: #F4F6F8;
    border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  white-space: nowrap;
}
.deal_card{
    border: 1px solid #e6e4e4;
    font-size: 10px;
    padding: 1px 4px;
    border-radius: 4px;
    margin: 0px 4px;
    background-color: white;
    font-weight: bold;
    white-space: nowrap;
}
.price_data{
    border-radius: 5px;
    padding: 2px 4px;
    width: 55%;
}
.minusQty{
    padding: 3px 7px;
    background-color:#F4F6F8 ;
    font-weight: bold;
    border-radius: 4px;
    transition: 0.1s ease-in;
    height: 30px;
    cursor: pointer;
}
.minusQty:hover{
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.062), 0px 0px 3px rgba(0, 0, 0, 0.11);

}
.addQty{
    padding: 3px 7px;
    font-weight: bold;
    border-radius: 4px;
    transition: 0.1s ease-in;
    height: 30px;
   cursor: pointer;
}
.addQty:hover{
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.096), 0px 0px 6px rgba(0, 0, 0, 0.13);
}
.qty{
  padding: 3px 5px;
  font-size: 13px;
  font-weight: bolder;
  min-width: 25px;
  max-width: fit-content !important;
  user-select: none;
  text-align: center;
}
.cd_flag{
    position: absolute;
    width: 130px;
    left: -40px;
    top: -20px;
    font-size: 13px;
    text-align: start;
    padding-left: 20px;
    background-color: #C719B1;
    transform: rotate(-40deg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.096), 0px 0px 6px rgba(0, 0, 0, 0.264);
}
.productQty{
   
   min-width: 60px;
   max-width: fit-content;
}
.ptrInfo{
    margin-left: 0.2rem;
    width: 13px;
    height: 13px;
    transition:0.1s ease-in !important;
}
.ptrInfo:hover{
   width: 16px;
   height: 16px; transition:0.1s ease-in;
}
.CompanyCard{
    width: fit-content;
    padding: 30px;
    border: 1px solid  rgba(150, 149, 149, 0.349);
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}
.CompanyCard:hover{
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.096), 0px 0px 6px rgba(0, 0, 0, 0.13);
}

.bg_opacity{
    background-color: rgba(255, 255, 255, 0.726) !important;
}
.timer_tag{
    background-color: yellow;
    padding:0px 2px;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
@media only screen and (max-width: 750px) {
    .productCard_parent{
        width: 230px;
        height: 150px;
        margin: 5px;
        padding: 7px;
    }
    .product_img{
        width: 50px !important;
        height: 40px !important;
    }
    .product_img2{
        width: 60px !important;
        height: 60px !important;
    }
    .product_name{
        font-size: 10px;
    }
    .product_nam2{
        font-size: 12px;
    }
    .product_add_btn{
        font-size: 9px;
        white-space: nowrap;
        margin-left: 5% !important;
    }
    .productCard_parent2{
        padding: 10px;
        margin:5px 0px;
    }
    .productQty{
        /* margin-left: 60%; */
    }
    .body_inStock{
        width: 60px;
      }
      .body_inExpiry{
          width: 60px;
         
      }
      .cd_flag{
        position: absolute;
        width: 130px;
        left: -40px;
        top: -10px;
        font-size: 10px;
        text-align: start;
        padding-left: 30px;
        transform: rotate(-40deg);
    }
}

@media only screen and (max-width: 1001px) and (min-width:751px){
    .productCard_parent2{
        width: 325px ;
        height: fit-content ;
       
    }
    .product_img2{
        width: 70px !important;
        height: 60px !important;
    }
    .body_inStock{
        width: 60px;
      }
      .body_inExpiry{
          width: 60px;
         
      }
      .productCard_parent2{
          padding: 10px;
          margin:20px 10px;
      }
}