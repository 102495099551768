.sc_parent_cntr {
  background-color: #f7f0ff;
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
}

.sc_cntr {
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  padding-top: 8rem;
  position: relative;
}

.sc_cntr::-webkit-scrollbar {
  display: none !important;
}

/* Background shapes */
.sc_bg_ele {
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 9;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.triangleShape {
  width: 0;
  height: 0;
  filter: opacity(0.5);
}

.sc_bg_ele .triangle1 {
  border-left: 40px solid #d8c6e8;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  transform: translateY(3.8rem);
}

.sc_bg_ele .triangle2 {
  border-left: 60px solid #d8c6e8;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
}

.sc_bg_ele_right .triangle1 {
  transform: translateY(8.8rem);
}

.sc_bg_ele_right {
  transform: rotate(180deg) translateY(-40%);
}

.squareShape {
  width: 15px;
  height: 15px;
  background-color: #d8c6e8;
  filter: opacity(0.5);
}

.sc_bg_ele_left,
.sc_bg_ele_right {
  position: relative;
}

.sc_bg_ele_left .squareShape {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: rotate(45deg);
}

.sc_bg_ele_right .squareShape {
  position: absolute;
  top: 80%;
  left: 100%;
  transform: rotate(45deg);
}

.sc_header {
  background-color: #f7f0ff;
  font-size: 20px;
  padding: 30px 15px 8px 30px;
  text-align: center;
  font-weight: bolder;
  color: #4b4495;
  position: sticky;
  top: 0%;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc_goBack {
  font-size: 2rem;
  color: #474747;
  transition: all 0.25s ease-in-out;
}

.sc_goBack:hover {
  filter: opacity(0.5);
}

.sc_header img {
  max-width: 250px;
}

/* Silvercross Banner section */
.sc_banner_cntr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  transition: all 0.25s ease-in-out;
  width: 100%;
  overflow-x: auto;
}

.sc_banner_cntr::-webkit-scrollbar {
  height: 0.5rem;
}

.sc_banner_cntr::-webkit-scrollbar-track {
  border-radius: 5px;
}

.sc_banner_cntr::-webkit-scrollbar-thumb {
  background: #6d699b56;
}

/* Silvercross Campaign */
.sc_camp_cntr {
  max-width: 500px;
  padding: 1rem;
  background-color: #592c82;
  padding: 0.5rem;
  border-radius: 18px;
  display: flex;
}

.sc_camp_left {
  display: grid;
  place-items: center;
}

.sc_logo img {
  filter: brightness(10);
  width: 150px;
}

.sc_logo p {
  margin: 0;
  color: #f8ed39;
  font-size: 0.7rem;
  transform: translateX(3rem);
}

.sc_campMilestone {
  margin: 0;
  height: 90px;
  width: 320px;
  padding: 0.5rem 0;
  list-style: none;
  overflow: hidden;
}

.sc_campMilestone li {
  width: 300px;
}

.sc_campMilestone li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.sc_campDescCont {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0 1rem 0.5rem 1rem;
}

#sc_tooltip .tooltip-inner {
  color: #383838;
  background-color: #f8ed39;
  font-size: 13px;
  font-weight: 500;
}

#sc_tooltip .tooltip-arrow::before {
  border-bottom-color: #f8ed39 !important;
}

.sc_campCheck {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  display: flex;
}

.sc_campBullet {
  color: #d1d1d1;
  margin-top: -0.1rem;
  margin-right: 0.5rem;
  display: flex;
  font-size: 0.8rem;
}

.sc_campDesc {
  color: white;
  margin: 0;
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Campaign Progress Bar */
.sc_campProgressCont {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 0.5rem 0 1rem;
  width: 100%;
}

.sc_campProgressBar {
  width: 100%;
  height: 5px;
  background-color: #d1d1d1;
  display: flex;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
}

.sc_campProgress {
  height: 100%;
  background-color: #f8ed39;
  border-radius: 3px;
}

.completedMilestone .sc_campProgressCont {
  margin: 0 0.5rem 0 1rem;
}

/* Campaign Progress Box */
.sc_campProgressBox {
  position: absolute;
  padding: 0.2rem;
  top: 50%;
  background-image: linear-gradient(to bottom, #f8ed39, #aba421);
  min-width: 55px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 3px;
  border: 1px solid #f8ed39;
  line-height: 1.2;
}
.sc_campProgressBox p {
  margin: 0;
}

.sc_campProgressHeader {
  font-weight: bold;
  font-size: 0.65rem;
  color: #383838;
}

.sc_campProgressValue {
  font-weight: bolder;
  font-size: 0.8rem;
  color: #383838;
}

.sc_camp_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.order-btn {
  border: 1px solid #f8ed39;
  border-radius: 5px;
  background-color: #f8ed39;
  font-size: 0.85rem;
  font-weight: 500;
  width: 100px;
}

/* Navigation campaigns */
.sc_navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sc_navigation p {
  margin: 0 0.5rem;
  color: #e3c6fd;
  font-size: 0.8rem;
}

.sc_navigation p::before {
  content: "Campaign ";
}

.sc_navigation svg {
  cursor: pointer;
  color: #b68edb;
  transition: all 0.25s ease-in-out;
}

.sc_navigation svg:hover {
  transform: rotate(360deg);
}

.sc_banner {
  display: grid;
  place-items: center;
}

.sc_banner img {
  width: 500px;
}

/* Rewards Button */
.sc_rewards {
  display: grid;
  place-items: center;
  width: 100%;
  margin: 1rem 0;
}

.sc_rewards button {
  width: 70%;
  background-color: #ffffff;
  border: 1px solid #9c27b0;
  border-radius: 7px;
  color: #9c27b0;
  font-weight: bold;
  padding: 0.5rem;
}

/* Rewards Flyer */
.sc_rewards_img {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 99;
  padding: 1rem;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.66);
  backdrop-filter: blur(5px);
  overflow: hidden;
  animation: openFlyer 0.5s forwards ease-in-out;
}

/* Banner images Carousel */
.sc_banner_carousel {
  width: 100%;
  margin: 1rem 0;
  display: grid;
  place-items: center;
}

.sc_carousel {
  max-width: 500px;
}

.sc_carousel .carousel-control-prev {
  left: -5rem;
  filter: brightness(0);
}

.sc_carousel .carousel-control-next {
  right: -5rem;
  filter: brightness(0);
}

.sc_carousel .carousel-control-next span,
.sc_carousel .carousel-control-prev span {
  height: 1.5rem;
  width: 1.5rem;
}

.sc_carousel .carousel-indicators {
  margin-bottom: 0;
}

.sc_carousel img {
  /* max-width: 200px; */
  height: 100%;
  border-radius: 8px;
}

/* No data */
.sc_parent_cntr .no_data {
  font-size: 1rem;
  color: #592c82;
}

.sc_parent_cntr .no_data svg {
  font-size: 1.5rem;
}

@keyframes openFlyer {
  0% {
    opacity: 0;
    top: 0%;
    left: 0%;
    transform: scale(0) translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);
  }
}

.sc_rewards_img img {
  max-width: 500px;
}

.sc_productList {
  height: 70vh;
  overflow-y: scroll !important;
  scroll-behavior: smooth;
}

.sc_productList::-webkit-scrollbar {
  display: none !important;
}

@media only screen and (max-width: 1000px) {
  .sc_banner_cntr {
    justify-content: flex-start;
    max-width: 500px;
    margin: auto;
  }
}

/* Responsive code */
@media only screen and (max-width: 750px) {
  .sc_cntr {
    padding-top: 7.5rem;
  }

  .sc_header {
    font-size: 14px;
    padding: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .sc_cntr {
    padding-top: 7.5rem;
  }

  .sc_header {
    margin-top: 20px;
    top: 1%;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .sc_cntr {
    display: block;
  }

  .sc_header img {
    max-width: 180px;
  }

  .sc_logo img {
    max-width: 100px;
  }

  .sc_logo p {
    transform: translateX(1.2rem);
  }

  .sc_camp_cntr {
    max-width: 100%;
  }

  .sc_campMilestone {
    padding: 0;
    width: 100%;
  }

  .sc_campMilestone li {
    width: 300px;
  }

  .sc_campDescCont {
    width: 250px;
  }

  .order-btn {
    font-size: 0.8rem;
    max-width: 90px;
  }
  .sc_navigation p::before {
    content: "";
  }

  .sc_rewards button {
    width: 100%;
  }

  .sc_rewards_img img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .sc_campMilestone {
    padding: 0;
    width: 220px;
  }

  .sc_campMilestone li {
    width: 200px;
  }

  .sc_campDescCont {
    width: 200px;
  }
}
