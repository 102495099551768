.see-all-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #eaecee;
  color: black;
  cursor: pointer;
  transition: 0.3s all;
}

.see-all-icon:hover {
  transform: translateY(-2px);
  box-shadow: 0px 0px 0.2rem rgba(0, 0, 0, 0.2) !important;
}

.add-to-cart-button{
    width: 50%;
}

.add-to-cart-button>button{
    height: 3rem;
    width: 100%;
    background-color: #EBFDFB;
    border: 1px solid #03DAC5;
    border-radius: 8px;
    font-weight: bold;
    /* margin-top: 1.1rem; */
}

.add-to-cart-button>div>p:last-child{
    margin: auto;
    color: #0B504C;
    background-color: #F7B737;
    padding: .1rem .4rem;
    font-size: .5rem;
    width: 3.5rem;
    font-weight: bolder;
    border-radius: 2px;
    border: 1px solid #0B504C;
}

.moq-badge{
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: -1.5rem;
    text-align: center;
}