.ws-footer-logo-backdrop{
    background-color:#1A4059;
    padding: 1rem;
    border-radius: 10px;
    width: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(138, 142, 146, 0.6) 0px 2px 3px 1px;
}

.ws-footer-icon{
    transition: .3s all;
}

.ws-footer-icon:hover{
    transform: translateY(-3px);
}

@media only screen and (max-width: 750px) {
    .ws-footer-logo-backdrop{
        margin: auto;
    }

    .footer-container{
        padding-bottom: 3.5rem;
    }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
    .footer-container{
        padding-bottom: 3.5rem;
    }
}