.saveo-logo{
    height: 70px;
    width: 200px;
    /* margin-top: 10px;
    margin-left: 20%; */
    margin: 10px auto 0px auto;
}

.header-onboarding-screen3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0px 10%;
}

.header-back-icon{
    cursor: pointer;
}

.help-link{
    margin: 0px 0px !important;
    /* font-size: 25px; */
    font-weight: bold;
    color: #A6B2CA;
    cursor: pointer;
}


.welcome-onboard-section{
    /* position: absolute; */
    width: 67%;
    height: 600px;
    text-align: center !important;
    margin-top: 30px;
    margin: 10px auto !important;

}

.welcome-header{
    color: #FFFFFF;
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
}

.selected-profile{
    color: #FFE3AD;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
}

.video-container{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.video-desc{
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.video-player{
    border: 8px solid rgba(166, 178, 202, .5);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    border-radius: 8px;
    /* border: 8px solid #A6B2CA; */
    /* border-radius: 8px; */
    /* box-shadow: 0px 0px 0px 8px rgba(166, 178, 202, .5);  
    border-radius: 1px;
    margin-top: 10px; */
    margin: 20px 0px; 
}

.setup-button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 320px;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin: 20px auto 0px auto;

}


.btn-text{
    color: #364668;
    font-weight: bold;
    font-size: 16px;
    margin-left: 20px;
}

.icon-container{
    width: 60px;
    height: 37px;
    border-radius: 8px;
    background-color: #4D6495;
    margin-right: 20px;
}

.next-btn-icon{
    margin-top: -5px;
    color: white;
    size: 1.5rem;
}

.nav-back-link{
    margin-top: 10px;
    color: #FFE3AD;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    .saveo-logo{
        height: 70px;
        width: 170px;
    }

    .welcome-onboard-section{
        position: relative;
        width: 100%;
        height: 100vh;
        text-align: center !important;

    }

    .welcome-header{
        /* font-size: 20px; */
        margin-top: -10px;
    }

    .selected-profile{
        /* font-size: 18px ; */
        margin-top: 10px;
    }

    .video-container{
        margin-top: 30px;
    }

    .video-desc{
        /* font-size: 15px; */
        padding: .2rem;
    }
    .setup-button-container{
        margin-top: 50px;
    }
    .nav-back-link{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 1001px) and (min-width:751px){
    .saveo-logo{
        height: 90px;
        width: 300px;
    }

    .header-onboarding-screen3{
        font-size: 25px !important;
    }

    .welcome-header{
        font-size: 40px;
    }
    .selected-profile{
        font-size: 30px;
    }

    .video-desc{
        font-size: 25px;
    }

    .setup-button-container{
        height: 85px;
        width: 400px;
    }

    .icon-container{
        width: 75px;
        height: 50px;
    }

    .btn-text{
        font-size: 22px;
    }
    .welcome-onboard-section{
        position: relative;
        height: 100vh;
        width: 100%;
    }
    .welcome-header{
        margin-top: 40px;
        /* margin-top: 20px; */
        /* font-size: 20px; */
    }

    .selected-profile{
        /* font-size: 18px; */
    }

    .nav-back-link{
        margin-top: 20px;
        font-size: 20px;
    }

 }