.download-csv-parent-container {
  height: 100vh;
  width: 100%;
  padding: 1% 20%;
}

.csv-nav-container {
  /* margin: 1.1rem 0rem; */
  margin: 0;
  padding-top: 9rem;
  padding-left: 3rem;
}

.csv-nav-container span {
  margin-left: 1.5rem;
}

.csv-nav-container > * {
  cursor: pointer;
}

.download-container {
  /* min-height: 4rem; */
  border-radius: 10px;
  /* min-width: 6rem; */
  position: sticky;
  bottom: 1rem;
  left: 50%;
  /* transform: translate(-50%, -50%); */
}

.download-button {
  /* border-right: 1px solid black !important; */
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.email-button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.download-container > span {
  position: relative;
  width: 1px;
  height: 1rem;
}

.download-container button {
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: #4b4495;
  min-height: 3rem;
  min-width: 8rem;
  color: #ffffff;
  transition: 0.3s;
}

.download-container button:hover {
  transform: translateY(-2px);
}

.MuiDataGrid-columnHeader {
  font-weight: bold !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin: 0px;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

/* .table-header{
    font-weight: bold !important;
    font-size: 1rem;
    color: #ffffff;
} */

.MuiDataGrid-columnHeaders {
  background-color: #e8eaf6;
  /* color: #ffffff; */
  font-weight: bolder !important;
  font-size: 1rem;
  text-transform: uppercase;
}

.date-range-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13.5rem;
  /* padding-left: 17.5rem; */
}

.date-range-container {
  width: fit-content;
  background-color: #ffffff;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.date-range-container > div .MuiFilledInput-root {
  width: 14rem;
  background-color: #f1f2f8;
}

.date-picker {
  width: 10rem;
}

.remove-filter > svg {
  transition: 0.3s all;
}
.remove-filter > svg:hover {
  transform: translateY(-2px);
  color: crimson !important;
}
.header-filter > svg {
  margin-top: -3px;
}

@media only screen and (max-width: 750px) {
  .csv-nav-container {
    /* margin-top: 3rem; */
    padding-left: 0rem;
  }
  .csv-nav-container span {
    margin-left: 1rem;
  }
  .download-container {
    bottom: 4rem;
  }
  .download-csv-parent-container {
    padding: 0;
  }
  .format-date-cell {
    white-space: pre !important;
  }
  .invoice-data-cell {
    font-size: 0.7rem;
  }

  .format-date-cell {
    font-size: 0.7rem;
  }
  .MuiDataGrid-columnHeaders {
    font-size: 0.9rem;
  }
  .date-range-container > div .MuiFilledInput-root {
    width: 10rem;

  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .csv-nav-container {
    /* margin-top: 4rem; */
    padding-bottom: 1rem;
  }
  .download-container {
    bottom: 4rem;
  }
  .download-csv-parent-container {
    padding: 0 5%;
  }
  .date-range-wrapper {
    top: 14.5rem;
  }
}
