.ws-profile-body-container {
  height: 100%;
  padding: 2rem 20%;
}

.ws-profile-accordion-header button {
  height: 5rem;
  font-size: 1.2rem;
  color: #000000;
  font-weight: 500;
}

.ws-profile-body-container p {
  margin: 0px;
}

.ws-profile-details-sub-section,
.ws-profile-docs-details-sub-section {
  margin: 0.5rem 2rem;
}

.ws-profile-docs-details-sub-section{
  width: 100%;
}

.ws-profile-details-sub-section > p:first-child {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 0.8rem;
}

.ws-profile-details-sub-section > p:last-child,
.ws-profile-docs-details-sub-section > p {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
}

.ws-profile-accordion-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ws-profile-doc-image {
  /* width: 50%; */
  margin-top: 1rem;
  object-fit: cover;
  flex: auto;
  border-radius: .25rem;
  height: 10rem;
  margin-right: .5rem;
  border: 1px solid #47D1CC;
}

@media only screen and (max-width: 750px) {
  .ws-profile-body-container {
    padding: 2rem 0.5rem;
  }

  .ws-profile-doc-image {
    width: 100%;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .ws-profile-body-container {
    padding: 2rem 10%;
    padding-bottom: 6rem;
  }
}
