.searchPage{
    background-color:#2f2963 ;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
    margin-top: 0px;
    padding-top: 2.5rem;
}
.searchPage::-webkit-scrollbar{
    display: none !important;
}
.top_searches{
    margin-top: 40px;
    width: 80vw;
    height: fit-content;
    /* border: 1px solid white; */
}
.recent_searches{
    width: 80vw;
    height:fit-content;
    margin-top: 2.5%;
}
.trending_products{
    width: 80vw;
    height:fit-content;
    margin-top: 2.5%;
}
.heading{
    font-size: 16px !important;
}
.top_searches_content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.toasst{
    border: 1px solid aliceblue;
    border-radius: 25px;
    padding: 5px 10px;
    width: fit-content;
    height: fit-content;
    font-size: 1rem;
    margin: 10px 25px 10px 0px;
    cursor: pointer;
    display: table;
}
.toasst:hover{
    box-shadow: 7px 7px 7px 7px rgba(0, 0, 0, 0.164);
}
.products_list{
    background-color: #2f2963;
    height: 91vh;
    width: 100%;
    margin-top: -92px !important;
    overflow: hidden;
    padding-top: 0.3%;
}
.productList::-webkit-scrollbar{
    display: none !important;
}
.searchProductList::-webkit-scrollbar{
    display: none !important;
}
.searchProductList{
    height: 100%;
    width: 100%;
    margin: 0px auto;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: scroll;
    scroll-behavior: smooth;
}
.no_data_parent{
    margin-top: 8%;
    text-align: center;
}
.no_data_parent > img{
    width: 200px;
}

/* .search-autocomplete-container{
    position: absolute;
    top: 8rem;
    z-index: 9999;
    background-color: #E7E9EB;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 43.2rem;
    height: 10rem;
    overflow-y: scroll;
    margin-left: -1.9rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 3px 6px 0 rgb(163 163 163);
}

.search-autocomplete-container::-webkit-scrollbar{
    display: none;
}

.search-autocomplete-list-item{
    display: flex;
    align-items: center;
    min-height: 2rem;
    background-color: #ffffff;
    border: 1px solid #E7E9EB;
    font-size: .9rem;
    transition: .3s all;
}

.search-autocomplete-list-item-active{
    min-height: 3rem;
    background-color: #2f2963;
    color: #ffffff;
} */




@media only screen and (max-width: 750px) {
    .toasst{
        font-size: 8px;
        margin: -5px 8px 10px 0px;
        padding: 3px 5px;
    }
    .top_searches{
         width: 100%;
         margin-top: 40px;
    }
    .recent_searches{
        width: 100%;
    }
    .trending_products{
        width:100%;
       
    }
    .heading{
        font-size: 10px !important;
    }
    .products_list{
        margin-top: -62px !important;
    }
    .searchPage{
        
    }
}
@media only screen and (max-width: 1001px) and (min-width:751px){
   .toasst{
       font-size: 10px;
       margin: 10px 10px 10px 0px;
   }
   .top_searches{
    width: 100%;
    margin-top: 40px;
}
.recent_searches{
   width: 100%;
}
.trending_products{
   width:100%;
  
}
.products_list{
    margin-top: -62px !important;
}

}