.ws-place-order-modal .modal-content{
    border-radius: .5rem !important;
}

.ws-place-order-modal .modal-footer{
    padding: 0rem;
    border: none;
}

.ws-place-order-modal .modal-footer>button{
    margin: 0px;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    border: none;
}

.ws-order-placed-main-container p{
    margin:  0px;
}

.ws-order-placed-main-container, .ws-order-message, .ws-order-price-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ws-order-message, .ws-order-price-details{
    padding: 1rem;
}

.ws-order-message>p:first-child{
    color: #053833;
    font-weight: bold;
    font-size: 1rem;
}

.ws-order-message>p:last-child{
    color: #68737A;
    font-size: .9rem;
    /* font-weight: bold; */
}

.ws-order-price-details{
    border-top: 1px solid #E8E8E8;
    padding-bottom: 0rem !important;
}

.ws-order-price-details>p{
    color: #27324B;
    font-weight: bold;
}

.ws-order-price-details>p:first-child>span{
    color: #02C3B0;
}

.ws-delete-item-main-container{
    /* width: 7rem; */
}

.ws-delete-item-message{
    text-align: center;
    font-size: 1rem;
    /* color: crimson; */
    margin-top: 2rem;
}

.ws-delete-item-modal .modal-content{
    border-radius: .5rem !important;
    /* color: #FFFFFF; */
}

.ws-delete-item-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color:rgb(26 64 89); */
    gap: 1rem;
    padding: 1rem;
    border: none;
}

.ws-delete-item-footer button{
    flex: 1;
    border: none;
    height: 3rem;
    margin: 0px;
    border-radius: .25rem;
    font-weight: bold;
    letter-spacing: .5px;
}

.ws-delete-item-confirm{
    background-color: #5CD2CC;
    color: #FFFFFF;
    transition: .3s all;
}

.ws-delete-item-confirm:hover{
    box-shadow: #7fe8de 0px 0px 5px 0px;
}

.ws-delete-item-cancel{
    background-color: #FFFFFF;
    border: 1px solid #E8E8E8 !important;
    transition: .3s all;
}

.ws-delete-item-cancel:hover{
    background-color: #FFFFFF;
    color: rgb(26, 64, 89);
    box-shadow: rgb(149 157 165 / 50%) 0px 2px 10px 0px;

}

.ws-track-order-modal .modal-content{
    border-radius: .25rem !important;
}

.ws-track-order-modal iframe{
    width: 100%;
    height: 70vh;
}

.ws-track-order-modal-iframe::-webkit-scrollbar{
    display: none;
}

/* .ws-track-order-modal-body{
    min-height: 70vh;
} */

