.timer-container{
    background-color: #1A4059;
    color: #FFFFFF;
    font-weight: bold;
    font-size: .9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 9rem;
    border-radius: 5px;
    margin-left: 1rem;
}

.timer-container p{
    margin: 0px;
    line-height: 1.3px;
    /* color: #FFFFFF !important; */
}

.timer-container span{
    font-weight: 500;
    font-size: .7rem;
    margin-left: .4rem;
}