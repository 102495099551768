.onBehalf_ret_list {
  margin: 0px 12px;
  width: 240px !important;
  position: relative;
}

.onBehalf_ret_list .dropdown-menu {
  width: 240px !important;
  overflow-y: auto  !important;
  padding: 0 !important;
}

.onBehalf_ret_list .dropdown-menu .dropdown_item {
  color: #222222;
  letter-spacing: 1px;
  transition: all 0.25s ease;
  width: 100% !important;
  padding: 8px !important;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: bolder !important;
}

.onBehalf_ret_list .dropdown-menu .dropdown_item:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}

.onBehalf_ret_list .dropdown_item span {
  display: block;
  font-size: 0.6rem !important;
  color: #6e6e6e;
  text-transform: capitalize;
}

.onBehalf_ret_list .dropdown-menu .dropdown_item:hover {
  /* color: #ffff; */
  background-color: rgba(128, 128, 128, 0.3);
}

.onBehalf_ret_list .dropdown-menu .dropdown_item:hover span{
  color: #616161;
}

.reasonInput{
  width: 240px !important;
  margin: 0px auto !important;
}
.margin_auto{
  margin: 0px auto !important;
}
.onbehalf_modal_footer{
  margin: 0px 10px;
}
.confirmNmbr{
  border: 1px solid rgb(185, 184, 184);
  margin: 15px 13px;
  border-radius: 4px;
  padding: 10px 10px 8px 10px;
  text-align: start;
  cursor: pointer;
  font-size: 17px !important;
}
.confirmLabel{
  font-size: 10px;
  position: absolute;
  margin-top: -20px;
  background-color: white;
  padding: 2px;
  font-weight: bold;
}
.vistiDiv{
  display: flex;
  justify-content: start;
  font-size: 14px;
  margin: 5px 13px;
  padding-top: 6px;
  width: 240px;
}
.phyVisit{
  border: 1px solid rgb(185, 184, 184);
  border-radius: 4px;
  padding: 5px;
  cursor: pointer !important;
  white-space: nowrap;
}
.phnVisit{
  border: 1px solid rgb(185, 184, 184);
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer !important;
  white-space: nowrap;
}

.activeVisit{
   background-color:#4b4495;
   color: white; 
   border-color: transparent !important;
}