.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10%;
  padding: 20px 0px;
}

.header-title {
  margin: 0px 0px;
  color: #ffffff;
  font-weight: bold;
  font-size: 30px;
}

.welcome-hero {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-left: 10px;
  margin: 0px 10%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.onboard-form-section {
  background-color: #f2f7f7;
  max-height: fit-content;
  min-height: 100vh;
  /* width: 50%; */
  position: absolute;
}
.accordion-form-section {
  position: relative;
  padding-top: 10px;
  margin: 10px auto !important;
  width: 65%;
}
.panel-wrap {
  /* position: absolute; */
  /* margin: 10px auto 10px auto; */
  position: relative;
  margin: 10px 0px 10px 0px;
  /* width: 62%; */
  background-color: white;
  padding: 1px 0px;
}

.panel-header {
  /* border-top: 1px solid #E8E8E8; */
  /* border-bottom: 1px solid #E8E8E8; */
  border: 1px solid #e8e8e8;
  min-height: 42px;
}

.head-items-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 42px;
  margin-left: 10px;
  margin-right: 10px;
}

.accordion-head {
  margin: 0px 0px;
  color: #4d6495;
  font-size: 15px;
  font-weight: bold;
}

.accordion-subhead-disabled {
  display: none;
}

.accordion-subhead {
  color: #6d6d6d;
  font-size: 13px;
  margin: 0px 10px;
}

.accordion-expanded {
  border-bottom: 1px solid #4d6495;
}

.business-details {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
}

.business-details-form-wrapper {
  margin: 20px 20px;
}

.input-title {
  margin: 10px 0px;
  color: #364668;
  font-size: 14px;
}

.line1-wrapper {
  /* position: relative;
    display: inline-block !important; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-input {
  /* position: relative; */
  background-color: #f5f5f5;
  /* background-color: red !important; */
  width: 100% !important;
  min-height: 50px;
  border-radius: 5px !important;
  border: none;
  font-size: 13px;
  padding-left: 30px;
  /* margin: auto 10px 10p 10px !important; */
  border: 1px solid #e8e8e8;
}

.form-input-line1 {
  /* width: 80% !important; */
  width: 95% !important;
}

.form-input-line2 {
  /* width: 95% !important; */
}

.location-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4d6495;
  min-height: 50px;
  min-width: 90px;
  background-color: #fff9ef;
  border: 1px solid #f7b737;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;
}

.multiple-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.line2-wrapper {
  margin-right: 10px !important;
}

.landmark-wrapper {
  margin-left: 10px;
}

.input-files-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.input-files-wrapper > :first-child {
  margin-right: 10px;
}

.input-files-wrapper > :nth-child(2) {
  margin-right: 10px;
}

.preview-image {
  height: 78px !important;
  width: 158px !important;
  border-radius: 10px !important;
  object-fit: cover;
  padding: 1px;
}
.input-document-container {
  height: 5rem;
  width: 10rem;
  /* border-style: dashed; */
  border: 1px dashed #ffe3ad;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px !important;
  /* cursor: pointer; */
}

.input-image-preview-container {
  height: 80px;
  width: 160px;
  border-radius: 10px !important;
  border: 1px dashed #ffe3ad;
}

.preview-close-container {
  position: absolute;
  size: 40px;
  margin-top: -11px;
  margin-left: -2px;
  height: fit-content;
  width: fit-content;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.preview-close {
  position: absolute;
  color: #f7b737;
}

.file-input {
  opacity: 0px !important;
  height: 0.1px;
  width: 0.1px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f7b737;
  font-size: 13px;
  cursor: pointer;
}

.scheduleX-section {
  margin: 10px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.scheduleX-section label {
  color: #7b7a82;
  font-weight: 500;
  font-size: 13px;
}

.scheduleX-section input {
  border: 1px solid #cad1df;
  min-width: 18px;
  min-height: 18px;
}

.footer-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.footer-button {
  position: relative;
  /* bottom: 0px; */
  /* bottom: 80px; */
  /* top: 90%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  height: 60px;
  /* width: 100%; */
  border: 0px !important;
  border-radius: 0px !important;
  font-size: 16px;
  font-weight: bold;
  /* position: absolute; */
  bottom: 3%;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-button:focus {
  border: none !important;
}

.footer-button:active {
  background-color: #f6b736 !important;
  border: none !important;
}

.footer-button-enabled {
  background-color: #f7b737 !important;
  color: #ffffff !important;
}

/* .footer-button:focus{
  background-color: #f7b737;
  color: #ffffff;
} */

.footer-button-disabled {
  background-color: #e2e2e2;
  color: #b3b6bc;
  pointer-events: none;
}

.error-log-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.agreement-check {
  display: flex;
  align-items: center;
  /* align-items: flex-start; */
  flex-wrap: nowrap !important;
  margin-top: 1.5rem;
}

.agreement-error {
  color: crimson;
  font-size: 0.7rem;
  font-weight: 500;
}

.agreement-message {
  color: #7b7a82;
  font-weight: 500;
  font-size: 0.9rem;
  margin-left: 1rem !important;
}

.agreement-error-container {
  margin-left: 4.125rem;
}

.inp-container input {
  /* position: absolute; */
  opacity: 1;
  -webkit-appearance: none;
  cursor: pointer;
  height: 50px;
  width: 50px;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(0, 0, 70, 0.12);
  border-radius: 50%;
  /* border: 8px solid #fdfdfd; */
  border: 8px solid rgba(0, 0, 70, 0.016);

  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.inp-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inp-container input::after {
  transition: 0.5s;
  font-family: monospace;
  content: "";
  color: #7a7a7a;
  font-size: 25px;
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid #7a7a7a;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

.inp-container input:checked {
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12),
    inset -10px -10px 15px rgba(255, 255, 255, 0.5),
    inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  transition: 0.5s;
}

.inp-container input:checked::after {
  transition: 0.5s;
  border: solid #15e38a;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

.retailer-type-select:focus{
  border: none !important;
}

.store-category-wrapper>select{
  box-shadow: none !important;
}

@media only screen and (max-width: 750px) {
  .form-input-line1 {
    width: 85% !important;
  }

  .input-document-container {
    min-width: 120px;
  }
  .input-files-wrapper {
    justify-content: space-between;
  }
  .footer-button {
    width: 100%;
    /* bottom: -30px; */
    /* position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60px; */
    /* border-radius: 0px !important; */
  }
  .header-title {
    font-size: 0.9rem !important;
    padding: 0px 5px;
    text-align: center !important;
  }
  .welcome-hero {
    font-size: 0.8rem;
    padding: 0px 0px 10px 0px !important;
  }
  .onboard-form-section {
    width: 106%;
    padding: 0px;
  }
  .accordion-form-section {
    padding: 0px 10px;
    /* margin: 0px; */
    width: 100%;
    /* margin-left: 20px !important;  */
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .form-input-line1 {
    width: 85% !important;
  }
  .footer-button {
    /* bottom: 240px; */
  }
  .header-title {
    font-size: 1.5rem;
    padding: 0px 10px;
    text-align: center !important;
  }

  .welcome-hero {
    padding: 0px 0px 10px 0px !important;
  }
}

@media only screen and (max-width: 1290px) and (min-width: 1002px) {
  .footer-button {
    /* bottom: 70px !important; */
  }
}
