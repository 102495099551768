.q-and-a-parent-container {
  padding-top: 8rem;
  width: 90%;
  /* height: 100vh; */
  height: fit-content;
  /* min-height: 90vh; */
}

.qa-parent-alternative{
  min-height: 100vh;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: inherit;
  margin-top: 1.5rem;
  /* cursor: pointer; */
}

.nav-container-form{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  margin-top: 2rem;
}

.nav-main{
  /* float: left !important; */
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  color:#4B4495;
  cursor: pointer;
}
.nav-main>span{
  margin-left: 1rem !important;
  font-size: 1.2rem;
}

.page-title{
  color: #4B4495;
  font-size: 1.2rem;
  margin-left: .5rem;
}

/* .nav-container > span {
  margin-left: 20px;
  font-weight: 500;
  color: #4b4495;
  font-size: 1.2rem;
} */

.main-container {
  /* border: 1px solid black; */
  width: 80%;
  margin-top: 5rem;
  /* height: fit-content; */
}

.search-container {
  width: 60%;
  margin: 10px auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 60px; */
  border: 1px solid #F3F6F8;
  border-radius: 8px;
  min-height: 56px;
  box-shadow: 0rem 0.125rem 0.125rem #0000001C;
}

.qa-search{
  margin: 0px;
  border: none;
  width: 100%;
  margin-left: 1rem;
}


.qa-input-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.qa-search-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.qa-search-icon{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}


.faq-accordion-container {
  margin-top: 20px;
}

.section-accordion {
  margin: 5px 0px;
  font-weight: 500 !important;
}
.question-accordion {
  border-bottom: 0.5px solid #4b4495;
  font-weight: 500 !important;
}

.bill-image {
  /* width: 675px !important; */
  width: 100% !important;
  height: auto !important;
  margin: 0px auto;
}

.qa-panel-wrap {
  min-height: 52px;
  border: 1px solid #48d1cc;
  border-radius: 6px;
  margin: 0.75rem 0rem;
  padding: 0px;

}

.qa-header-items-wrapper {
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qa-panel-header {
  background-color: #d9f4f3 ;
  border-radius: 6px;
  min-height: 51px;
  padding: 0px 10px;
}

.accordion-header-text {
  margin: 0px;
  padding: 0px;
}

.background-container{
  /* margin: */
  /* padding: 0px; */
  background: #F2F7F7;
  display: block;
  overflow: auto;
  min-height: 100vh;
  /* padding-top: 6rem; */
}

.section-card{
  margin: 1rem 0rem;
  border-radius: 8px !important;
  color: #FFFFFF !important;
  box-shadow: 0rem 0.125rem 0.375rem #9399A417 !important;
  border: none !important;
}

.report-issue-text{
  color: #2A2A2A;
  font-size: 0.9rem;
  font-family: 'Ubuntu', sans-serif;
}
.list-group{
  border-radius: 8px !important;
  color: #FFFFFF !important;
  box-shadow: 0rem 0.125rem 0.375rem #9399A417 !important;
  border: none !important;
}
.list-group-item{
  border: none;
  border-bottom: 1px solid #F2F2F2 ;
  cursor: pointer;
  min-height: 2rem;
}

.list-group-item-general{
  margin-left: 1.7rem;
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.9rem;
  color: #2A2A2A;
}

.next-icon-report{
  /* margin-right: 0px ; */
  /* margin-left: auto ; */
  float: right;
}

.general-issue-header{
  background: #FFFFFF;
  cursor: default;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.instructions-header{
  color: #116361 !important;
  background: #48D1CC !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.2rem;
}
.instructions-card{
  border: 1px solid #48D1CC !important;
  border-radius: 4px;
  margin-top: 2rem;
  /* width: 20rem; */
}

.instructions-body{
  background: #F6F6F6;
  color: #1A1C1B;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1rem;
}

.explain-issue-container{
  margin: 1.5rem auto;
}

.describe-issue-label{
  color: #68737A ;
  font-size: 0.8rem;
}

.describe-issue-input{
  background: #F6F6F6 !important;
  border: 1px solid #F2F2F2 ;
  border-radius: 4px;
  font-size: 0.9rem;
  height: 6rem;
}

.issue-image-input-container{
  height: 5rem;
  width: 5rem;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  color: #48D1CC;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
}

.issue-image-input{
  opacity: 0px !important;
  height: 0.1px;
  width: 0.1px;
}

.issue-image-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.8rem;
}

.issue-images-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.issue-images-wrapper:last-child{
  margin-right: 0px;
}

.issue-preview-image{
  height: 4.5rem;
  width: 4.5rem;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #F2F2F2;
}

.issue-image-preview-container{
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 4px;
  border: 1px solid #F2F2F2;
  margin-right: 1rem;
}

.navigate-back{
  cursor:pointer;
}

.issue-image-upload{
  height: 2rem;
  width: 3rem;
}

.submit-button-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-issue-button{
   height: 3.5rem;
   width: 48%;
   border-radius: 0px;
   background: #4B4495;
   color: #AFAFD0;
   margin: 1.5rem auto;
}

*{
  font-family: 'Ubuntu', sans-serif;
}

.issue-form-main-container{
  width: 50%;
}

.ticket-parent-container{
  margin-top: 40px;
}

.ticket-status-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 9rem;
}

.ticket-main-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.ticket-home-button{
  margin: 3rem auto;
  width: 50%;
  background: #48D1CC;
  border-radius: 8px;
  border: none;
  height: 3rem;
  color: #FFFFFF;
  box-shadow: 0rem 0.125rem 0.125rem #0000001C;
}

.order-list-group-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-list-subitem-group-left{
  display: flex;
  align-items: flex-start; 
  justify-content: flex-start;
  flex-direction: column;
}

.order-list-subitem-group-right{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.order-list-item-final-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.order-list-item-card{
  margin-top: 2.5rem;
  font-size: 0.9rem;
  border-radius: 8px;
  box-shadow: 0rem 0.3rem 0.5rem #9399A417 !important;
  border: 1px solid #E8E8E8;
  transition: 0.2s ease-in-out;
}

.order-list-item-card:hover{
  box-shadow: 1rem 1rem 1.5rem 1rem #9399A417 !important;

}

.order-list-item-card p{
  margin: 0px;

}

.oreder-list-item-header-text{
  color: #9399A4;
  font-size: 0.8rem;
}

.order-issue-main-container{
  width: 60%;
}

.order-issue-nav-container{
  padding: 1.5rem ;
  margin: 0;
}

.order-issue-main-container:last-child{
  margin-bottom: 2.5rem;
}

.live-order-issue-parent-container{
  background: #161522;
  min-height: 100vh;
  padding-top: 6rem;
}

.live-order-issue-list-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.live-order-list-header{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-top: 1.5rem;
}

.live-order-list-item{
  background: #D9F4F3;
  color: #116361;
  border: 0.1rem solid #48D1CC;
  border-radius: 6px ;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-height: 51px;
  margin: 0.5rem 1rem;
  padding: 0rem 2rem;
  width: 60%;
}
.live-order-list-item:last-child{
  margin-bottom: 1rem;
}

.live-order-nav-container{
  margin-top: 0px;
  padding: 1rem 2rem;
}

.no-orders {
  height: 15rem;
  width: 15rem;
}
.no-orders-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.order-issue-parent-container{
  min-height: 85vh;
  padding-top: 8rem;
}

.ticket-parent-container{
  min-height: 85vh;
}

.call-support-container{
  color: #48D1CC;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}
.call-support-container>div>span{
  margin-left: 1rem !important;
  font-size: 1rem;
}



@media only screen and (max-width: 750px) {
  .faq-accordion-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .q-and-a-parent-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
    padding-top: 6.5rem;
  }

  .nav-container {
    margin-top: 1rem;
  }
  .nav-container-form{
    margin-top: 1rem;
  }

  .main-container {
    padding: 0px;
    margin: 0px auto;
    width: 95%;
  }

  .search-container {
    width: 80%;
    padding: 0px;
  }

  .qa-search-wrapper{
    justify-content: space-evenly;
  }
  .all-container-wrap{
    margin: 0px;
    padding: 0px;
  }
  
  .submit-issue-button{
    width: 100%;
  }
  .ticket-parent-container{
    margin-top: 4.5rem;
  }
  .order-issue-main-container{
    width: 100%;
  }
  .order-issue-nav-container{
    margin: 0px;
    margin-top: 1rem;
    padding:0px;
  }
  .live-order-list-header{
    width: 100%;
  }
  .live-order-list-item{
    width: 100%;
  }
  .live-order-issue-main-container{
    padding: 0px;
  }

  .issue-search{
    width: 100%;
  }
  .live-order-nav-container{
    margin-top: 0px;
    padding: 0rem;
    padding-top: 1rem;
  }
  .call-support-container>div>span{
    margin-left: 0.3rem !important;
    font-size: 0.9rem;
    margin-right: 0.3rem;
  }
  .nav-main>span{
    margin-left: 0.5rem !important;
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {

  .issue-form-main-container{
     width: 80%;
  }
  
  .submit-issue-button{
    width: 78%;
  }

  .ticket-parent-container{
    margin-top: 4.5rem;
  }

  .order-issue-nav-container{
    margin-top: 1rem;
  }

  .live-order-issue-parent-container{
    padding-top: 7rem;
  }

  .live-order-nav-container{
    margin-top: 0px;
    padding-top: 1rem;
  }

  .background-container{
    /* padding-top: 7.5rem; */
  }
}
