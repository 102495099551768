.login {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  /* position: fixed; */
  /* top: 0; */
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.233);
  /* overflow-y: hidden; */
  padding: 0px 0px !important;
}

.parentDiv_login {
  width: 100%;
  /* margin: 3% auto; */
  height: 100vh;
  /* border-radius: 10px; */
  background-color: #4b4495;
  overflow-y: scroll;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
  /* border: 3px solid rgba(245, 245, 245, 0.233); */
  padding: 0px 0px !important;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.saveoLogo {
  width: 130px;
  height: 160px;
  /* margin-left: 45% !important; */
  margin-top: 10px;
}

.storeLogo {
  width: 200px;
  height: 300px;
  margin-left: 16%;
  margin-top: 10px;
}

.storeLogo2 {
  width: 200px;
  height: 300px;
  margin-left: 40%;
  margin-top: -110px;
}

.login_section {
  width: 70%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: fit-content;
  background-color: white;
  margin: -100px auto;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.inputs_div {
  margin: 0px auto !important;
  /* padding:3% 10px; */
  padding: 50px 10px;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.input_phone {
  width: 350px;
  margin: 20px auto !important;
}

.button {
  width: 200px !important;
  padding: 10px;
  margin: 10px;
}

.button:focus {
  outline: 0 !important;
}
.pass-link-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 350px;
}
.pass_link {
  font-size: 12px;
  /* margin-top: -10px; */
  /* margin-left: 58%; */
  color: rgb(73, 88, 170);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin: 0px;
}
.otp_link {
  font-size: 12px;
  /* margin-top: -10px; */
  /* margin-left: 150px; */
  color: rgb(73, 88, 170);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin: 0px;
}

.otp-timer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.resend-otp {
  margin: 0px;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(73, 88, 170);

}

.otp-inputs-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.resend-otp-disabled {
  color: gray;
  pointer-events: none;
  cursor: not-allowed !important;
  margin: 0px;
}

.otp_timer {
  font-size: 12px;
  /* margin-top: -10px; */
  /* margin-left: 125px; */
  /* margin-left: 10px; */
  color: rgb(73, 88, 170);
  font-weight: bold;
  /* cursor: pointer; */
  margin: 0px;
  width: fit-content;
}

.width_200px {
  width: 350px;
  text-align: center;
  margin: 0px auto;
}
@media only screen and (max-width: 750px) {
  .saveoLogo {
    height: 120px;
    width: 100px;
    /* margin-left: 38%; */
  }
  .parentDiv_login {
    width: 106%;
    margin: 0% 0% 0% -3%;
    height: 100vh;
  }
  .login {
    overflow: hidden;
  }
  .login_section {
    height: 100%;
    width: 108%;
    margin: 0px 0px 0px -3%;
  }
  .storeLogo {
    width: 150px;
    height: 270px;
    margin-left: 2%;
    margin-top: 10px;
    position: relative;
    top: 96px;
  }
  .storeLogo2 {
    width: 100px;
    height: 200px;
    margin-left: 20%;
    margin-top: -30px;
    position: relative;
    top: 100px;
  }
  .input_phone {
    width: 280px;
    margin: 15px auto !important;
  }
  .pass_link {
    margin-left: 40%;
  }
  .login_section_2 {
    position: absolute;
    width: 93.5%;
    left: 10px;
    bottom: 0px;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .saveoLogo {
    height: 150px;
  }
  .parentDiv_login {
    /* margin:10% auto !important; */
    height: 100vh;
  }
  .login_section {
    /* height: 300px; */
    height: 100vh;
    justify-content: space-between;
  }
  .storeLogo {
    width: 150px;
    height: 270px;
    margin-left: 18%;
    margin-top: 10px;
  }
  .storeLogo2 {
    width: 120px;
    height: 240px;
    margin-left: 35%;
    margin-top: -60px;
  }
  .login_inputs {
    width: 250px !important;
  }
}
@media only screen and (max-width: 1290px) and (min-width: 1002px) {
  .saveoLogo {
    height: 150px;
  }

  .parentDiv_login {
    /* margin:10% auto !important; */
    /* height: 100vh; */
  }
  .login_section {
    /* height: 10; */
    justify-content: flex-start;
  }
  .storeLogo {
    width: 200px;
    height: 300px;
    margin-left: 15%;
    margin-top: 10px;
  }
  .storeLogo2 {
    width: 200px;
    height: 300px;
    margin-left: 35%;
    margin-top: -110px;
  }
}
