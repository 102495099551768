.info_icon {
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #48d1cc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
}

.info_icon svg {
  width: 40px;
  height: 40px;
  fill: #48d1cc;
  stroke: white;
  stroke-width: 1;
  transform: rotate(180deg);
}

.info_header {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.info_message {
  margin-top: 1.5rem;
  width: 100%;
}

.info_message p {
  margin: 0;
  line-height: 1.6;
  color: #787878;
  font-size: 0.9rem;
  text-align: center;
}

.infoActions {
  border-top: 1px solid #e5e5e5;
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.infoActions button {
  margin: 0.5rem 1rem;
  padding: 0.25rem 0.5rem;
  width: 100px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #48d1cc;
  color: white;
  border: 1px solid #48d1cc;
  border-radius: 3px;
  transition: all 0.25s ease-in;
}

.info_confirm_btn:hover {
  color: #48d1cc;
  background-color: white;
  border: 1px solid #48d1cc;
  box-shadow: 0 3px 5px #0000001a, 0px 0px 5px #0000001a;
  transform: scale(1.05);
}
