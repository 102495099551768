.wholesale-container {
  background-color: #eaecee;
  padding: 1rem 0;
}

.worders-main-container {
  margin-top: 7rem;
  display: grid;
  place-items: center;
}

.worders-main-container .infinite-scroll-component__outerdiv {
  min-width: 60%;
}

.worders-sub-container {
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 2px 3px 10px 2px #00000026;
}

.worder-header {
  color: #27324b;
  font-size: 0.8rem;
}

.worder-header p {
  margin: 0.5rem 0;
}

.worder-header p:last-child {
  font-size: 0.9rem;
  font-weight: 600;
}

.worder-header span {
  color: #9399a4;
  margin-right: 1rem;
}

.worders-card {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-bottom: 1rem;
  transition: all 0.5s ease;
  overflow: hidden;
}

.worders-card:hover {
  border-color: #02c3b0;
}

.shipmentCont {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.shipmentDetails .detailsHeader {
  display: flex;
  padding-bottom: 0.5rem;
}

.shipmentDetails .detailsHeader h6,
.shipmentDetails .detailsHeader p {
  margin: 0;
}

.shipmentDetails .detailsHeader h6 {
  font-weight: bolder;
}

.shipmentDetails .detailsHeader p {
  padding-left: 0.5rem;
  color: #9399a4;
  font-size: 0.9rem;
}

.shipmentDetails .detailsHeader p::before {
  content: "●";
  margin-right: 0.5rem;
}

.shipSeller {
  margin-right: 1rem;
  font-weight: 700;
}

.shipmentDetails p {
  font-size: 0.8rem;
}

.shipmentDetails p span {
  color: #9399a4;
  font-weight: 500;
  margin-right: 0.5rem;
}

.shipActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #CFFFFA;
  height: fit-content;
  padding: .5rem;
  margin: -.5rem -.5rem 0rem 0rem;
  border-top-right-radius: 5px;
}

.shipActions .btn {
  font-size: 0.8rem;
  font-weight: 600;
  color: #001412;
  margin-top: .5rem;
  padding: .5rem 2rem;
}

.shipActions .invoice {
  border: 1px solid #02c3b0;
  background-color: #ffffff;
  transition: .3s all;
}

.shipActions .invoice:hover {
  background-color: #02c3b0;
  color: #ffffff;
}

.worders-card .totalPrice {
  color: #27324b;
  background-color: #ebfdfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
}

.worders-card .totalPrice p {
  margin: 0;
}

.worders-card .totalPrice span {
  background-color: #1a4059;
  padding: 0.2rem 0.5rem;
  color: #03dac5;
  margin-right: 1rem;
  border-radius: 3px;
}

.worders-card .totalPrice span::before {
  content: "₹";
  margin-right: 0.1rem;
}

.worders-card .totalPrice p:last-child {
  font-weight: 600;
}

.twoItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.twoItems p {
  margin: 0;
}

.seeAllBtn {
  padding: 0.5rem;
  margin: 0;
  text-align: center;
  color: #24c8c2;
  font-weight: 600;
  cursor: pointer;
  border-top: 1px solid #eaecee;
  transition: all 0.25s ease;
}

.seeAllBtn:hover {
  color: #1a4059;
  border-color: #24c8c2;
  background-color: #ebfdfb;
}

.seeAllBtn span {
  margin: 0 1rem;
  font-size: 0.8rem;
}

.closeSeeAll {
  display: inline-block;
  transform: rotate(0);
  transition: all 0.25s ease;
}

.openSeeAll {
  display: inline-block;
  transform: rotate(-90deg);
  transition: all 0.25s ease;
}

.medList {
  animation: openMedList 0.5s forwards;
}

@keyframes openMedList {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.medList-close {
  display: none;
}

.medList > p {
  padding: 0.5rem;
  margin-bottom: 0;
  font-weight: bolder;
}

.medList hr {
  margin: 0.5rem 0;
}

.medAmount {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.medAmount p {
  margin: 0;
}

.medDetail {
  display: flex;
}

.medDetail p {
  font-size: 0.9rem;
}

.medDetail p:first-child {
  margin-right: 1rem;
}

.medAmount p {
  display: flex;
  flex-direction: column;
}

.medAmount span {
  font-size: 0.8rem;
  font-weight: 300;
}

.medAmount > p {
  font-weight: bold;
}

.medAmount > p span {
  text-align: right;
}

.wOrders-pg {
  width: 100%;
  padding: 1rem;
  display: grid;
  place-items: center;
}

.wOrders-pg .Mui-selected {
  color: #27324b;
  font-weight: bolder;
  background-color: #02c3b0 !important;
}

.wOrders-pg .MuiPaginationItem-page {
  border-color: #02c3b0;
  box-shadow: 1px 1px 3px 1px #00000010;
}

.wOrders-pg .MuiPaginationItem-previousNext {
  color: #02c3b0;
  box-shadow: 1px 1px 3px 1px #00000010;
}

.listEnd-msg {
  border-top: 1px solid #9399a4;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
  text-align: center;
  color: #9399a4;
  font-weight: 500;
}

.ws-orders-header-wrapper > svg {
  /* color: #1A4059; */
  height: 1.5rem;
  width: 1.5rem;
  fill: #ffffff !important;
  stroke: #ffffff !important;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.ws-orders-header-wrapper > svg:hover {
  fill: #02c3b0 !important;
}

.ws-orders-sub-header-update{
  top: 5.8rem;
}

@media only screen and (max-width: 750px) {
  .wholesale-sub-header {
    margin-bottom: 0rem;
  }

  .tab-active {
    width: 100%;
  }

  .worders-main-container{
    margin-top: 9rem;
    padding: 1rem 1rem 3rem 1rem;
  }
  .worders-main-container .infinite-scroll-component__outerdiv {
    min-width: 100%;
  }
  .ws-orders-sub-header-update{
    top: 8.5rem;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .worders-main-container{
    margin-top: 8rem;
    padding: 2rem 0rem 3rem 0rem;
  }
  .ws-orders-sub-header-update{
    top: 8.97rem;
  }
}
