.digitalPaymentModal {
  background-color: #00000069;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  display: grid;
  place-items: center;
  animation: openDPModal 0.25s forwards ease-in;
}

@keyframes openDPModal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dp_modal_cntr {
  margin: 1rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 3px;
  min-width: 70%;
  min-height: 400px;
  box-shadow: 0 10px 10px #00000017, 0px 0px 25px #00000017;
}

.dp_modal_close {
  fill: none;
  stroke: #43cdc8;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dp_modal_close:hover {
  fill: #3a8381;
  stroke: white;
}

.dp_modal_header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dp_modal_details {
  margin: 1rem 0;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #d1cfe950;
  border: 1px solid #443f78;
  border-radius: 3px;
}

.dp_modal_info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.3rem;
  width: 300px;
}

.dp_modal_info p {
  margin: 0;
  font-size: 0.9rem;
  color: #443f78;
  font-weight: bold;
}

.dp_modal_info span {
  font-size: 1rem;
  color: #8c8c8c;
}

.dp_modal_info p:first-child {
  font-size: 0.8rem !important;
  color: #8c8c8c;
  width: 100px;
  font-weight: 400;
}

.dp_modal_info p:last-child {
  padding-top: 3px;
}

.dp_modal_details p span {
  font-weight: 400;
  font-size: 1rem;
  color: #443f78;
}

.dp_edit_btn {
  cursor: pointer;
  fill: none;
  stroke: #43cdc8;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  height: 20px;
  width: 20px;
  transition: all 0.2s ease;
}

.dp_edit_btn:hover {
  fill: #443f78;
  stroke: white;
}

.dp_edit_amount {
  display: flex;
  animation: showEditAmt 0.15s forwards ease-in;
}

.dp_edit_amount label {
  font-weight: 400;
  font-size: 0.8rem;
  color: #8c8c8c;
}

.dp_edit_amount input {
  padding: 0 0.3rem;
  width: 150px;
  font-size: 1rem;
  border: none;
  color: #3b9a97;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: white;
  border-radius: 2px;
}

@keyframes showEditAmt {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.dp_payment_details {
  margin: 1rem;
  display: grid;
  place-items: center;
}

.dp_qr_code {
  width: 200px;
  height: 200px;
  overflow: hidden;
  padding: 0.25rem;
  border: 1px solid #86e1dd;
  border-radius: 3px;
  box-shadow: 0 3px 10px #00000037, 0px 0px 13px #00000037;
  margin-bottom: 1rem;
}

.dp_qr_code img {
  width: 100%;
}

.dp_qr_code svg {
  width: 100%;
  height: 100%;
}

.dp_sample_qr {
  position: relative;
}

.dp_sample_qr img {
  opacity: 0.2;
  filter: blur(2px);
}

.dp_sample_qr button {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  border-radius: 25px;
  background-color: #514aa1;
  color: white;
  height: 30px;
  width: 100px;
  box-shadow: 0 3px 5px #00000044, 0px 0px 5px #00000044;
  transition: all 0.2s ease;
}

.dp_sample_qr button:hover {
  background-color: white;
  border: 1px solid #514aa1;
  color: #514aa1;
}

.dp_qr_header {
  color: #514aa1;
  font-size: 1rem;
  font-weight: bold;
}

.dp_or {
  color: #aaa9a993;
  font-size: 1.5rem;
}

.dp_phn {
  display: flex;
  align-items: center;
  position: relative;
}

.dp_phn > div {
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #514aa1;
  border-radius: 3px;
  overflow: hidden;
}

.dp_phn > div p {
  font-size: 1rem;
  margin: 0;
  background-color: white;
  color: #8c8c8c;
  width: 40px;
  padding: 0.5rem 0;
  text-align: center;
  border-right: 1px solid #514aa1;
}

.dp_phn input {
  font-size: 1rem;
  border: none;
  letter-spacing: 3px;
  padding: 0.5rem;
  color: #656565;
}

.dp_phn button {
  margin: 0 0.5rem;
  padding: 0.5rem 0;
  width: 100px;
  border: none;
  background-color: #514aa1;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #514aa1;
  border-radius: 3px;
  box-shadow: 0 1px 6px #00000033, 0px 0px 3px #00000033;
  transition: all 0.2s ease;
}

.dp_phn button:hover {
  background-color: white;
  color: #514aa1;
}

.dp_payment_info {
  margin: 0;
  color: #514aa1;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.dp_timer {
  margin-top: 1rem;
  color: #3cb3af;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
}

.dp_timer span {
  font-size: 0.9rem;
  color: #8c8c8c;
  font-weight: 500;
}

.dp_warning_msg {
  position: absolute;
  top: -2rem;
  left: 50%;
  width: 100%;
  margin: 0;
  color: #f48172;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  animation: DPWarningMsg 0.25s forwards ease-in;
}

@keyframes DPWarningMsg {
  0% {
    opacity: 0;
    transform: translate(-50%, 1rem);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.dp_msg_cntr {
  display: grid;
  place-items: center;
  height: 400px;
}

.dp_msg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dp_msg img {
  width: 250px;
}

.dp_success h4 {
  color: #50d05c;
  animation: paymentSuccess 0.6s forwards ease-out;
}

.dp_fail h4 {
  color: #f38172;
  animation: paymentSuccess 0.6s forwards ease-out;
}

.dp_fail p {
  color: #8c8c8c;
  animation: paymentSuccess 0.6s forwards ease-out;
}

.dp_invoice_nmbrs_list{
  width: 40vw !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}
@keyframes paymentSuccess {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.dp_Loader {
  margin: 1rem;
  width: 200px;
  height: 200px;
}

.dp_Loader div {
  display: grid;
  place-items: center;
}

.dp_Loader svg {
  width: 100px !important;
  height: fit-content !important;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .dp_modal_details {
    flex-direction: column;
  }
}

@media only screen and (max-width: 400px) {
  .dp_modal_cntr {
    width: 90%;
  }

  .dp_phn > div p {
    font-size: 80%;
  }

  .dp_phn input {
    font-size: 80%;
  }

  .dp_phn button {
    font-size: 80%;
  }
}
