.ws-cart-main-container {
  /* margin-top: 3.9rem; */
}
.ws-products-header-cart {
  top: 4rem;
}

.ws-cart-main-content-wrapper {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 4rem);
  height: fit-content;
  background-color: #eaecee;
  justify-content: center;
  padding: 0rem 2rem;
  padding-top: 4rem;
}

.ws-cart-items-list {
  width: 39%;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.ws-cart-card-main-container {
  background-color: #ffffff;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  width: 100%;
  transition: 0.3s all;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
}
.ws-cart-card-main-container:hover {
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
}

.ws-cart-card-main-container p {
  margin: 0px;
}

.ws-cart-card-details-sub-section-1 > p:first-child {
  color: #2a2a2a;
  font-size: 1rem;
  font-weight: bold;
}

.ws-cart-card-details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ws-cart-card-details-sub-section-1 > p:last-child {
  font-size: 0.8rem;
  color: #68737a;
}

.ws-cart-details-sub-section-2 > p {
  color: #68737a;
  font-size: 0.8rem;
}

.ws-cart-details-sub-section-2 > p > span {
  color: #001412;
  font-size: 0.8rem;
  font-weight: bold;
}

.ws-cart-card-price-details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.ws-cart-card-price-details-sub-section-1 > p {
  color: #9399a4;
  font-size: 0.8rem;
}

.ws-cart-card-price-details-sub-section-1 > p:nth-child(2) {
  color: #001412;
  font-size: 1rem;
  font-weight: bold;
}

.ws-cart-card-cd-inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
}

.ws-cart-card-cd-inline > p:first-child {
  text-decoration: line-through;
  color: #68737a;
  font-size: 0.8rem;
}

.ws-cart-card-cd-inline > p:last-child {
  margin-left: 1rem;
  color: #03dac5;
  background-color: #1a4059;
  padding: 0.1rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.25rem;
  font-weight: bold;
}

.ws-cart-card-price-details-sub-section-2 {
  display: flex;
}

.ws-cart-price-details-container {
  width: 35%;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  margin: 1rem;
  position: sticky;
  top: 8rem;
  height: fit-content;
  /* padding: 1rem; */
}

.ws-cart-price-details-container p {
  margin: 0px;
}

.ws-product-price-details-header {
  padding: 1rem;
}

.ws-product-price-details-header > p:first-child {
  color: #68737a;
  font-size: 0.8rem;
}
.ws-product-price-details-header > p:last-child {
  color: #2b2c2c;
  font-size: 1.2rem;
  font-weight: 900;
}

.ws-product-price-detail-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ws-product-price-detail-item > p:first-child {
  color: #4f585e;
  font-size: 0.9rem;
  margin: 0.3rem 0rem;
}

.ws-product-price-detail-item > p:last-child {
  color: #1a1c1c;
  font-size: 0.9rem;
}

.ws-product-price-details-content {
  background-color: #ffffff;
  border-top: 1px solid #dfe3e6;
  border-bottom: 1px solid #dfe3e6;

  padding: 1rem;
}

.ws-product-price-detail-subitem-highlight,
.ws-product-price-detail-subitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.3rem 1rem;
}

.ws-product-price-detail-subitem-highlight {
  background-color: #ffe3ad;
  border: 1px solid #f6e056;
}

.ws-product-price-detail-subitem-highlight p {
  font-size: 0.8rem;
  font-weight: 600;
}

.ws-product-price-detail-subitem-highlight > p:first-child {
  color: #916d19;
}

.ws-product-price-detail-subitem-highlight > p:last-child {
  color: #2b2c2c;
}

.ws-product-price-detail-subitem {
  background-color: #f2f7f7;
  border: 1px solid #dedfe0;
}

.ws-product-price-detail-subitem p {
  font-size: 0.8rem;
  font-weight: 600;
}

.ws-product-price-detail-subitem > p:first-child {
  color: #517187;
}

.ws-product-price-detail-subitem > p:last-child {
  color: #2b2c2c;
}

.ws-product-price-details-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.ws-product-price-details-total-container > p:first-child {
  color: #4f585e;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
}

.ws-product-price-details-total-container > p:last-child {
  color: #1a1c1c;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
}

.ws-product-price-details-disclaimer-container {
  padding: 0rem 1rem 0.5rem 1rem;
}

.ws-product-price-details-disclaimer-container > p {
  color: #2b2c2c;
  font-size: 0.8rem;
}

.ws-product-price-details-disclaimer-container > p > span {
  color: #68737a;
}

.ws-product-price-details-confirm-button {
  padding: 0.8em 1.8em;
  border: 1px solid #eaecee;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  font-size: 16px;
  transition: 0.4s;
  z-index: 1;
  color: #17c3b2;
  width: 100%;
  font-weight: bold;
}

.ws-product-price-details-confirm-button::before {
  content: "";
  width: 0;
  height: 1000%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #17c3b2;
  transition: 0.6s ease;
  display: block;
  z-index: -1;
}

.ws-product-price-details-confirm-button:hover::before {
  width: 105%;
}

.ws-product-price-details-confirm-button:hover {
  color: #111;
}

.ws-shipment-card {
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  transition: 0.3s all;
  margin-bottom: 1rem;
}

.ws-shipment-card:hover {
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
}

.ws-shipment-card p {
  margin: 0px;
}

.ws-shipment-card-header {
  padding: 1rem;
}

.ws-shipment-card-header > p:first-child {
  color: #043833;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.ws-shipment-card-header > p:last-child {
  color: #043833;
  font-size: 0.8rem;
}

.ws-shipment-card-body {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  background-color: #ebfdfb;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.ws-shipment-card-body-sub-section-2 {
  font-weight: bold;
  color: #043833;
}

.ws-shipment-card-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ws-shipment-card-footer-items-container {
  width: 100%;
  padding: 1rem 0rem;
  padding-bottom: 0rem;
  border-radius: 10px !important;
}

.ws-shipment-card-footer-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding: 0rem 1rem;
}
.ws-shipment-see-all-items > div:first-child {
  border-radius: 10px !important;
}

.ws-shipment-see-all-items .accordion-header > button {
  color: #24c8c2 !important;
  font-weight: bold;
  /* padding: .5rem 0rem; */
  text-align: center !important;
  justify-content: center !important;
  border-radius: 10px !important;
  /* background-color:  #EBFDFB !important; */
}

.ws-shipment-see-all-items .accordion-header > button:not(.collapsed) {
  color: #212529;
  border-radius: 0px !important;
}

.ws-shipment-see-all-body {
  background-color: #eaecee70;
}

.ws-shipment-see-all-body > p:first-child {
  font-weight: bold;
  color: #001412;
}

.ws-shipment-medicine-card {
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  /* margin: 1rem 0rem; */
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  transition: 0.3s all;
  border: 1px solid #eaecee;
}

.ws-shipment-medicine-card:hover {
  box-shadow: 0px 5px 10px rgb(0 0 0 / 30%);
}

.ws-shipment-medicine-card > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ws-shipment-medicine-card > div:first-child > p:first-child {
  color: #2a2a2a;
  font-weight: bold;
  font-size: 1rem;
}

.ws-shipment-medicine-card > div:first-child > button {
  background-color: #03dac5;
  border: none;
  text-align: center;
  color: #27324b;
  border-radius: 0.25rem;
  padding: 0.3rem 1.5rem;
  font-size: 0.7rem;
  font-weight: bold;
}

.ws-shipment-medicine-card > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.ws-net-rate-container {
  display: flex;
  align-items: center;
}

.ws-net-rate-container > div:first-child > p:first-child,
.ws-net-rate-container > div:last-child > p:first-child,
.ws-total-container > p:first-child {
  color: #2a2a2a;
  font-size: 0.8rem;
}

.ws-net-rate-container > div:first-child > p:last-child,
.ws-net-rate-container > div:last-child > p:last-child,
.ws-total-container > p:last-child {
  color: #2a2a2a;
  font-weight: bold;
  font-size: 1rem;
}

.ws-net-rate-container > div:first-child {
  margin-right: 2rem;
}

.ws-total-container > p:first-child {
  text-align: end;
}

.empty-cart-message {
  font-size: 1.5rem;
  color: #1a4059;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.ws-cart-header {
  position: sticky !important;
  z-index: 1;
}

.ws-cart-header-update {
  top: 6rem;
}

@media only screen and (max-width: 750px) {
  .ws-cart-items-list {
    width: 100%;
    margin-bottom: 0rem;
  }

  .ws-cart-main-content-wrapper {
    flex-wrap: wrap;
    height: fit-content;
    padding: 0rem 0.5rem;
    padding-bottom: 5rem;
    padding-top: 6rem;
  }

  .ws-cart-price-details-container {
    width: 100%;
    margin: 1rem;
  }

  .ws-products-header-cart {
    top: 6.8rem;
    /* flex-wrap: wrap; */
    padding: 1rem;
  }

  .ws-products-header > p {
    width: 11rem;
  }

  .ws-product-search-container {
    width: 12%;
    min-height: 2.5rem;
  }
  .ws-cart-main-container {
    margin-top: 0rem;
  }
  .ws-cart-header-update {
    top: 8.5rem;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .ws-products-header-cart {
    top: 7rem;
  }

  .ws-cart-items-list {
    width: 50%;
  }
  .ws-cart-price-details-container {
    width: 40%;
    top: 12rem;
  }

  .ws-cart-main-content-wrapper {
    padding: 4rem 0rem;
    padding-top: 7.5rem;
  }
  .ws-cart-main-content-wrapper-update {
    padding: 7rem 0rem;
  }
}
