.upload_cart_container {
  position: fixed;
  overflow-y: hidden !important;
  height: 100vh;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2929297b;
  z-index: 99;
  backdrop-filter: blur(5px);
  display: grid;
  place-items: center;
}

.upload_cart_sub_container {
  margin: 0 1rem;
  height: 600px;
  width: 80%;
  overflow-x: auto;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 5px 5px #0000001e, 0px 0px 15px #00000015;
  animation: showResult 0.5s forwards;
}

.upload_selection_sub_cntr .upload_cart_btn,
.upload_selection button {
  box-shadow: 0 5px 5px #0000003b, 0px 0px 15px #0000003b;
  transition: all 0.25s ease;
}

.upload_selection_sub_cntr .upload_cart_btn:hover,
.upload_selection button:hover {
  filter: brightness(0.7);
}

.upload_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #2f2963;
  padding: 0.5rem;
  border-radius: 5px;
}

.upload_title svg {
  cursor: pointer;
  font-size: 1.5rem;
  color: #d4d4d4;
  transition: all 0.25s ease;
}

.upload_title svg:hover {
  color: #48d1cc;
}

.upload_title p {
  font-size: 1.1rem;
  text-align: center;
  color: #ffffff;
  flex: 1;
  margin-bottom: 0 !important;
}

.upload_check{
  padding: 0.5rem;
  background-color: #f46d6a;
  color: #faeded;
  border-radius: 5px;
  animation: showUploadError 0.25s forwards;
}

@keyframes showUploadError {
  0%{
    transform: translateY(-2rem);
    opacity: 0;
  }

  40%{
    opacity: 1;
  }

  100%{
    transform: translateY(0);
  }
}

.upload_selection {
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.25s ease;
  padding: 0.5rem;
  overflow-x: auto;
}

.upload_selection_sub_cntr {
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  width: 40%;
}

.upload_cart_btn {
  min-height: 150px;
  margin-bottom: 1rem;
  border-radius: 5px;
  background-color: #4b4495;
  border: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.upload_cart_btn label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 2rem;
  text-align: center;
}

.upload_cart_btn label svg {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.upload_cart_btn label p {
  margin-bottom: 0 !important;
}

.upload_cart_btn input {
  display: none;
}

.download_sample_csv button {
  width: 100%;
  padding: 1rem;
  color: #4b4495;
  border: 1px solid #4b4495;
  border-radius: 5px;
  background-color: #ffffff;
  transition: all 0.25s ease;
  margin-bottom: 1rem;
}

.download_sample_csv svg {
  margin-right: 0.5rem;
  margin-bottom: 3px;
}

.search_cntr {
  height: 2.5rem;
  margin-bottom: 0.5rem;
  background-color: #f3f3fb;
  border: 1px solid #48d1cc;
  display: flex;
  align-items: center;
}

.search_cntr input {
  height: 100%;
  width: 100%;
  border: none;
  margin: 0.5rem;
  padding: 0rem 0.3rem;
  color: #616161 !important;
  background-color: #f3f3fb;
}

.search_cntr button {
  height: 100%;
  margin: 0 !important;
  width: 50px;
  border: 1px solid #ffffff;
  background-color: #48d1cc !important;
  pointer-events: none;
}

.search_cntr button svg {
  color: #ffffff !important;
}

.medicine_list_tbl {
  height: 90%;
  overflow-y: scroll;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.medicine_list_tbl .submit_cntr {
  background-color: #ffffff !important;
  border-top: 1px solid #dfdeeb;
  display: flex;
  justify-content: right;
}

.medicine_list_tbl .submit_cntr button {
  border: 1px solid #48d1cc;
  background-color: #48d1cc;
  color: #ffffff;
  width: 200px !important;
  height: 3rem;
  box-shadow: 0 5px 5px #0000001e, 0px 0px 15px #00000015;
}

.medicine_list_tbl .submit_cntr button:hover {
  background-color: #ffffff;
  color: #48d1cc;
}

.medicine_list_tbl::-webkit-scrollbar {
  display: none;
}

.medicine_list_tbl .tbl_cntr {
  height: 500px;
  overflow: auto;
}

.medicine_list_tbl table {
  table-layout: fixed;
  min-width: 100%;
  overflow: auto;
}

.medicine_list_tbl table,
.medicine_list_tbl th,
.medicine_list_tbl td {
  border-collapse: collapse;
  text-align: center;
  font-weight: 300;
}

.medicine_list_tbl thead {
  position: sticky;
  top: 0;
  color: #ffffff;
  z-index: 99;
}

.upload_cart_sub_container .confirm_list thead {
  background-color: #2f2963;
}

.upload_cart_sub_container .error_list thead {
  background-color: #f46d6a;
}

.medicine_list_tbl th,
.medicine_list_tbl td {
  padding: 0.5rem;
}

.medicine_list_tbl th:first-child {
  width: 100px;
}

.medicine_list_tbl th:not(:last-child),
.medicine_list_tbl td:not(:last-child) {
  border-right: 1px solid #ffffff;
}

.medicine_list_tbl td:nth-child(4) {
  width: 200px !important;
}

.medicine_list_tbl tbody {
  font-size: 0.9rem;
}

.medicine_list_tbl tbody tr {
  transition: all 0.25s ease;
}

.upload_cart_sub_container .confirm_list tbody tr:nth-child(even) {
  background-color: #f3f3fb;
}

.upload_cart_sub_container .confirm_list tbody tr:hover {
  background-color: #e8e8ee;
}

.upload_cart_sub_container .error_list tbody tr:nth-child(even) {
  background-color: #faeded;
}

.upload_cart_sub_container .error_list tbody tr:hover {
  background-color: #efe4e4;
}

.medicine_list_tbl tbody td input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: right;
}

.upload_cart_sub_container .confirm_list tbody td input {
  border: 1px solid #4b4495;
  color: #4b4495;
}

.upload_cart_sub_container .error_list tbody td input {
  border: 1px solid #f46d6a;
  color: #f46d6a;
}

.medicine_list_tbl tbody td:nth-child(4) button {
  max-width: 100px;
}

.medicine_list_tbl button {
  margin: 0.3rem 0.5rem;
  border-radius: 3px;
  background-color: #ffffff;
  transition: all 0.25s ease;
}

.medicine_list_tbl button svg {
  font-size: 1.2rem;
}

.medicine_list_tbl .disableEvent {
  pointer-events: none;
  filter: grayscale(0.8);
}

.medicine_list_tbl .edit_btn {
  border: 1px solid #48d1cc;
  color: #48d1cc;
}

.medicine_list_tbl .edit_btn:hover {
  background-color: #48d1cc;
  color: #ffffff;
}

.medicine_list_tbl .dlt_btn {
  border: 1px solid #f46d6a;
  color: #f46d6a;
}

.medicine_list_tbl .dlt_btn:hover {
  background-color: #f46d6a;
  color: #ffffff;
}

.medicine_list_tbl .retry_btn {
  border: 1px solid #15920f;
  color: #15920f;
}

.medicine_list_tbl .retry_btn:hover {
  background-color: #15920f;
  color: #ffffff;
}

.success_cntr {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: showResult 0.25s forwards;
}

@keyframes showResult {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.countAnime {
  font-weight: bold;
  animation: countShake 1s infinite !important;
}

@keyframes countShake {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.count_cont {
  display: flex;
  align-items: center;
}

.count_cont .search_cntr {
  min-width: 25% !important;
  border-radius: 0;
}

.success_count {
  height: 2.5rem;
  padding: 0.5rem;
  background-color: #eafae9;
  color: #15920f;
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.25s ease-in-out;
}

.error_count {
  height: 2.5rem;
  padding: 0.5rem;
  background-color: #faeded;
  color: #f46d6a;
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error_count button {
  margin: 0.1rem 0.2rem;
  padding: 0px 4px;
  border: 1px solid #f46d6a;
  background-color: #f46d6a;
  color: #ffffff;
  box-shadow: 0 3px 3px #3a39396b, 0px 0px 7px #2e2d2d53;
  transition: all 0.25s ease;
}

.error_count button:hover {
  color: #f46d6a;
  background-color: #faeded;
}

.error_count button svg {
  font-size: 0.9rem;
}

.error_highlight {
  border: 1px solid #ffffff !important;
  color: #ffffff;
  animation: errorHighlight 1.5s infinite;
}

@keyframes errorHighlight {
  20% {
    background-color: #f46d6a;
  }
  40% {
    background-color: #faeded;
  }
  80% {
    background-color: #f46d6a;
  }
}

.success_cntr h4 {
  color: #15920f;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success_cntr h4 svg {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation: successShow 1.2s infinite;
}

@keyframes successShow {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0.6;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.success_cntr .cart_btn {
  margin-top: 3rem;
  padding: 1rem;
  border: 1px solid #2f2963;
  color: #2f2963;
  background-color: #ffffff;
  border-radius: 5px;
  width: 150px;
  transition: all 0.25s ease;
}

.success_cntr .cart_btn:hover {
  background-color: #2f2963;
  color: #ffffff;
}

.loader_cntr {
  display: grid;
  place-items: center;
  height: 100%;
}

.progress_bar_cntr {
  min-width: 50%;
}

.progress_bar_cntr p {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: #48d1cc;
  transition: all 0.25s ease-in-out;
  animation: successShow 1.2s infinite;
}

.progress_bar_sub_cntr {
  background-color: #eeeeee;
  width: 100%;
  height: 1rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 10rem;
}

.progress_bar_sub_cntr .progress_bar {
  background-color: #48d1cc;
  height: 0.5rem;
  width: 50%;
  border-radius: 10rem;
  transition: all 0.25s ease-in-out;
}

.dropdown_main_cntr {
  max-height: 240px;
  overflow-y: auto;
}

.dropdown_cntr:not(:last-child) {
  border-bottom: 1px solid #aaa9a9 !important;
}

.medList_cntr,
.sub_medList_cntr {
  display: flex;
  justify-content: space-between;
}

.sub_medList_cntr {
  margin-left: 5px;
  padding: 2px 5px;
  background-color: #edededb6;
  border-radius: 3px;
  width: 250px;
}

.medList_cntr:not(:last-child) {
  border-bottom: 1px solid #616161 !important;
}

.medList_cntr p {
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
}

.medList_cntr > p {
  font-size: 0.8rem;
  color: #4b4495;
}

.medList_cntr > p > span {
  font-size: 0.7rem;
  color: #aaa9a9;
}

.sub_medList_cntr p {
  font-size: 0.8rem;
  color: #616161;
  text-align: center;
}

.sub_medList_cntr p:not(:first-child) {
  margin-left: 10px;
}

.sub_medList_cntr span {
  font-weight: 700;
  font-size: 0.6rem !important;
}

.medDropDown > button {
  outline: none !important;
  border: 1px solid #616161;
  color: #616161 !important;
  font-size: 0.8rem !important;
}

.medDropDown > button:focus {
  border-color: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #d4d4d4 !important;
  border-color: #616161 !important;
}

.medDropDown > button:hover {
  background-color: #edededb6 !important;
  border-color: #616161 !important;
}

.medDropDown > button:not(:disabled):not(:disabled):active {
  background-color: #edededb6 !important;
  border-color: #616161 !important;
}

.medDropDown .dropdown-menu a:active {
  background-color: #edededb6 !important;
  border-color: #616161 !important;
}

@media only screen and (max-width: 750px) {
  .count_cont {
    flex-direction: column;
  }

  .count_cont .search_cntr {
    width: 100%;
  }

  .count_cont .success_count {
    width: 100%;
  }

  .count_cont .error_count {
    width: 100%;
  }

  .upload_cart_sub_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .upload_selection {
    flex-direction: column;
    justify-content: start;
  }
}
