.final-onboard-parent-container {
  background: #4d6495;
  display: block;
  overflow-y: auto;
  min-height: 100vh;
}

.final-nav-container {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.final-onboard-page-title {
  margin-left: 2rem;
}

.final-onboard-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wholesale-img {
  height: 10rem;
  width: 8.75rem;
}

.onboarding-img {
  height: 17rem;
  width: 20rem;
  margin-top: -196px;
}

.main-content-container {
  background: #ffffff;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 160px;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  bottom: 0;
}

/* .carousel-indicators > button {
     min-height: 0.5rem;
     border-radius: 11px !important;
     max-width: 24px;
     border-top: 0px;
     border-bottom: 0px;
  } */

.carousel-image {
  width: 150px;
  height: 200px;
}

.carousel-item {
  margin-bottom: 1.5rem;
}

.carousel-indicators .active {
  border-bottom: 0px !important;
  border-top: 0px !important;
  margin-top: 20px !important;
  border-radius: 11px !important;
  height: 6px !important;
  width: 20px !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #cad1df !important;
  border-bottom: 0px !important;
  border-top: 0px !important;
  margin-top: 20px;
  border-radius: 100%;
  height: 5px;
  width: 5px;
}

.redirect-link{
    color: #F7B737;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    .main-content-container{
        width: 100%;
    }

    .wholesale-img{
        margin-bottom: 2rem;
    }

}

@media only screen and (max-width: 1001px) and (min-width:751px){
    .wholesale-img{
        height: 15rem;
        width: 11rem;
    }
}
