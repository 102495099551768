* {
  scroll-behavior: smooth;
}
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #6d699b28;
}
*::-webkit-scrollbar-thumb {
  background: #6d699b9a;
  border-radius: 5px;
}

.border_none {
  border: none !important;
}
.web_display {
  display: block;
}
.font_ubuntu {
  font-family: "Ubuntu", sans-serif;
}
.font_weight_md {
  font-weight: bold !important;
}
.font_weight_500 {
  font-weight: 500 !important;
}
.font_weight_lg {
  font-weight: bolder !important;
}
.font_weight_lt {
  font-weight: lighter;
}
.color_grey {
  color: #2223268f !important;
}
.color_grey_1 {
  color: grey;
}
.color_grey_3 {
  color: #9399a4d8 !important;
}
.color_grey_2 {
  color: #d1cef87e;
}
.color_black {
  color: rgb(24, 24, 24) !important;
}
.color_white {
  color: white !important;
}
.color_pink {
  color: #c915b1 !important;
}
.color_warn {
  color: rgb(247, 88, 30);
}
.bg_white {
  background-color: white;
}
.color_green {
  color: rgb(11, 163, 11);
}
.color_white_lite {
  color: rgba(255, 255, 255, 0.596) !important;
}
.color_ice {
  color: aliceblue !important;
}
.bg_grey {
  background-color: aliceblue !important;
}
.padding_5 {
  padding: 5px;
}
.padding_10 {
  padding: 10px !important;
}
.padding_top_10 {
  padding-top: 10px;
}
.font_weight_400 {
  font-weight: 500;
}
.padding_15 {
  padding: 15px;
}
.padding_top_12 {
  padding-top: 12px;
}
.padding_bottom_10 {
  padding-bottom: 10px;
}
.font_16 {
  font-size: 16px !important;
}
.font_18 {
  font-size: 18px !important;
}
.font_14 {
  font-size: 14px !important;
}
.font_26 {
  font-size: 26px !important;
}
.font_12 {
  font-size: 12px !important;
}
.font_10 {
  font-size: 10px !important;
}
.font_8 {
  font-size: 8px !important;
}
.txt_center {
  text-align: center !important;
}
.margin_auto {
  margin: 0px auto !important;
}
.margin-none{
  margin: 0 !important;
}
.cursor_pointer {
  cursor: pointer !important;
}
.width_100 {
  width: 100% !important;
}
.text .no_wrap {
  white-space: nowrap !important;
}
.text_decoration_none {
  text-decoration: none !important;
  color: black;
}
.line_height_3 {
  line-height: 16px !important;
}
.color_primary {
  color: #4b4495 !important;
}
.brdr_primary {
  border: 1px solid #353070 !important;
}
.brdr_grey {
  border: 2px solid rgb(53, 3, 3) !important;
}
.brdr_none {
  border: none !important;
}
.bg_white {
  background-color: white !important;
}

.color_secondary {
  color: #5bd2cc !important;
}

.color_tertiory {
  color: #17706C !important;
}
.text_right {
  text-align: right !important;
}
.margin_10 {
  margin: 10px !important;
}
.mb_8 {
  margin-bottom: 0.5rem;
}
.display_flex {
  display: flex !important;
  /* justify-content: space-between !important; */
}
.space_btw {
  justify-content: space-between !important;
}
.bg_primary {
  background-color: #4b4495 !important;
}
.brdr_primary {
  border: 1px solid #4b4495;
}
.bg_primary_lt {
  background-color: #4b4495 !important;
}
.bg_primary_lt2 {
  background-color: #cdcdf2 !important;
}
.color_primary_lt {
  color: #4b4495cb !important;
}
.color_primary_lt2 {
  color: #9f97fa !important;
}
.bg_secondary {
  background-color: #5bd2cc !important;
}
.bg_light_green {
  background-color: #e5f7f7 !important;
}
.border_radius_5 {
  border-radius: 5px;
}
.text_line_through {
  text-decoration: line-through !important;
}
.text_center {
  text-align: center !important;
}
.elevation_1 {
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
}
.elevation_3 {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.116),
    0px 0px 25px rgba(0, 0, 0, 0.082);
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}
.margin_5px {
  margin: 5px !important;
}
.margin_10px {
  margin: 10px !important;
}
.m_r_16 {
  margin-right: 1rem !important;
}
.primary_btn {
  color: white !important;
  background-color: #4b4495 !important;
  border-radius: 8px;
  padding: 5px 10px;
  border: none !important;
  width: fit-content !important;
  height: fit-content !important;
}
.txt_right {
  text-align: right !important;
}
.display_none{
  display: none;
}
.width_10 {
  width: 10%;
}

.bg_blur {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}
.space_around {
  justify-content: space-around !important;
}
button:focus {
  outline: none !important;
}
.bg_opacity {
  opacity: 0.9 !important;
}
.bg_dark_primary {
  background-color: #2f2963 !important;
}
.button {
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
}

.bg-hidden {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.disabled {
  color: gray !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.active_nav {
  border-bottom: 2px solid #17706C;
}

.carousel-control-prev-icon {
  filter: invert(50%) !important;
}

.carousel-control-next-icon {
  filter: invert(50%) !important;
}
.modal-header {
  border-bottom: none !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.modal-footer {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.no-items-container {
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.flex-start-center-row {
  display: flex !important;
  align-items: center;
}

.flex-align-center-row {
  display: flex ;
  justify-content: center;
  align-items: center;
}

.flex-align-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-start-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.overflow-hidden {
  overflow: hidden !important;
}

.got_it_btn {
  border: 1px solid #4b4495;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #ffffff;
  color: #4b4495;
  transition: all 0.25s ease;
}

.got_it_btn:hover {
  background-color: #4b4495;
  color: #ffffff;
}

/* *{
  white-space: pre;
} */

/* ::-webkit-input-placeholder {
  text-align: center;
}

.got_it_btn:hover {
  background-color: #4b4495;
  color: #ffffff;
}

.ws-footer-color-primary{
  color: #517187 !important;
}

:-ms-input-placeholder {  
  text-align: center; 
}

input:focus::-webkit-input-placeholder{
  color: transparent!important;
}
input:focus::-moz-placeholder{
  color: transparent!important;
}
input:focus:-moz-placeholder{
  color: transparent!important;
} */
