.more-menu-main-container {
  min-height: 100vh;
  height: fit-content;
  background-color: #f2f7f7;
  padding-top: 4rem;
}

.more-menu-header-wrapper {
  width: 100%;
  background-color: #ebfdfb;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 15%;

}

.ws-menu-nav-container{
  display: flex;
  align-items: center;
  margin: 1rem 0.5rem;
  cursor: pointer;

}

.more-menu-header {
  background-color: #ebfdfb;
  height: fit-content;
  width: 100%;
  /* padding: 0.5rem 15%; */
}

.more-menu-header p {
  margin: 0px;
}

.more-menu-header div {
  margin: 1rem 0.5rem;
}

.more-menu-header > div > p:first-child {
  font-size: 1rem;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.5);
}

.more-menu-header > div > p:last-child {
  font-size: 1.1rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
}

.more-menu-body-container {
  padding: 2rem 15%;
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-direction: column;
}
.ws-menu-section-container {
  padding: 0.5rem;
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.ws-menu-section-card {
  width: 20rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: rgb(149 157 165 / 10%) 0px 2px 10px 0px;
  margin: 0.5rem;
  cursor: pointer;
  transition: 0.3s all;
}

.ws-menu-section-card:hover {
  box-shadow: rgb(149 157 165 / 40%) 0px 2px 10px 0px;
}

.ws-menu-section-card p {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
}

.ws-menu-nav-container > svg {
  /* color: #1A4059; */
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.ws-menu-nav-container > svg:hover {
  fill: #02c3b0 !important;
}

.ws-menu-section-card-disabled{
  opacity: .5;
  cursor: not-allowed;
}

@media only screen and (max-width: 750px) {
  .more-menu-main-container {
    padding-top: 7rem;
  }
  .more-menu-header {
    padding: 0.5rem 1rem;
  }
  .more-menu-header div {
    margin: 0.5rem 0rem;
  }
  .more-menu-body-container {
    padding: 0rem;
  }
  .ws-menu-section-container {
    padding: 0.5rem 0rem;
  }
  .ws-menu-section-card {
    margin: 0 0 0.5rem 0;
    width: 100%;
    padding: 1rem;
  }
.more-menu-header-wrapper {
  padding:0rem 1rem;
}
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .more-menu-main-container {
    padding-top: 7rem;
  }

  .more-menu-body-container {
    padding: 2rem;
  }

  .more-menu-header {
    padding: 0.5rem 2rem;
  }

  .more-menu-header-wrapper{
    padding: .5rem 1rem;
  }
}
