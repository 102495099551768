
.cart_parent{
    /* margin: 2%; */
    padding: 9rem 10%;
    background-color: #F2F7F7;
    padding-bottom: 2%;
}

.cart_items_widget{
    min-height: fit-content;
    max-height: fit-content;
    overflow-y: scroll;
    background-color: #F2F7F7;
    padding: 0px !important;
    display: table;
    /* border: 1px solid rgba(128, 128, 128, 0.644); */
}

.cart_total_widget{
    height: fit-content;
    /* border: 1px solid rgba(128, 128, 128, 0.644); */
    background-color: #F2F7F7;
    padding:1% 3%;
    position: sticky !important;
    top: 10% !important;

}

.updated_products > .accordion-button{
 background: rgba(247, 94, 34, 0.123) !important;
 color: rgb(250, 119, 59) !important;
 font-weight: bolder;
}
.out_of_stock > .accordion-button{
    padding: 8px !important;
    background-color: rgba(252, 205, 205, 0.76);   
     color: rgb(235, 57, 57) !important;
     font-weight: bold;
 border: none !important;
 font-size: 14px;
}

.accordion-button:focus{
    outline: none !important;
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}
.updatedProductCard{
    width: 100%;
    height: fit-content;
    display: table;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.123);
}
.removeFromCartBtn{
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: rgb(228, 20, 20);
    background-color: transparent;
}
.editFromCartBtn{
    border: none;
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
}

.cart_items{
    height: fit-content;
    display: table;
    width: 98%;
    border: 2px solid rgba(128, 128, 128, 0.205);
    padding: 10px;
    border-radius: 10px;
    margin: 10px auto;
    background-color: rgba(228, 227, 227, 0.308);
}
.cart_items_widget::-webkit-scrollbar{
    display: none;
}
.cart_card_parent{
    display: flex;
    align-items: center;
   justify-content: space-between;
}
.cart_card{
    width: 99%;
    padding: 10px;
    height: fit-content;
    display: table;
   background-color: white;
   border-radius: 10px;
   border: 1px solid rgba(128, 128, 128, 0.158);
   cursor: pointer;
   margin: 10px auto;
}
.cart_card:hover{
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.096), 0px 0px 50px rgba(0, 0, 0, 0.082);
    border: 2px solid rgba(128, 128, 128, 0.308);
}
.subShip{
    background-color: rgba(255, 255, 255, 0.651);
    padding: 10px;
    margin:0px 10px 0px 10px;
    border: 2px solid rgba(128, 128, 128, 0.151);
    height: fit-content;
    display: table;
    width: 100%;
    border-radius: 5px;
}
.ptr_section{
    text-align: right !important;
    width: 100%;
    height: fit-content;
    display: table;
    padding: 5px;
    line-height: 12px;
}
.rightSec{
    text-align: right !important;
width: 100% !important;

}
.total_card_sec{
    background-color: #48d1cd25;
    padding: 10px;
    line-height: 5px;
    font-size: 14px;
    width: fit-content;
    margin-left: -21px;
    margin-top: 20px;
    border-top: 1px solid #48d1cd70;
    border-bottom: 1px solid #48d1cd70;
    border-right: 1px solid #48d1cd71;
    border-top-right-radius: 7px;
    border-bottom-right-radius:7px ;
    text-align: right;
}

.qty_input{
    border-top:none;
    border-left: none;
    border-right: none;  
    border-bottom: 2px solid rgba(128, 128, 128, 0.397);  
    font-size: 14px;
    font-weight: bold;
    width: 50px;
}
.qty_edit_sec{
    width: 100%;
    text-align: right !important;

    margin-top: 30px;
}

.subTotal_note{
    width: 98%;
    padding: 10px;
   color: #CDCDF2;
   font-size: 12px;
   margin: 5px;
   border-radius: 5px;
}
.payment_btn{
    border: none;
    border-radius: 5px;
    width: 98%;
    margin: 5px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
}
.payment_btn:focus{
outline: none !important;
}
.payment_btn:hover{
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.096), 0px 0px 6px rgba(0, 0, 0, 0.13);
}
.radioInput{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
    transform: scale(1.2);
    cursor: pointer;
    margin-right: 10px;
}
.radioInput > .label{
    margin-top: -4px !important;
    color: rgb(61, 61, 61);
}
.radio_div{
    margin-top: -10px;
    color:rgb(87, 87, 87) !important ;
}
.invoice_val{
    margin: 5px 10px;
    width: 50%;
    line-height: 10px;
    color: rgb(87, 87, 87) !important;
}
.color_dark_green{
    color:#104e4b !important
}
.delete_product_svg{
    width: 20px;
    cursor: pointer;
    margin: 0px 25px 3px 0px;

}
.delete_product_svg:hover{

    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}


.brdr_highlight{
    border: 2px solid rgba(128, 128, 128, 0.534);
}
.cart_header{
    position: sticky !important;
    top:7% !important;
}
.highlight_item{
    background-color: #ffd500d2 !important;
    color: #7E6322 !important;
    padding: 5px;
    text-align: center;
    height: 30px;
    border-radius: 4px;
    margin: 5px 0px;

}
.payment_div{
    margin: 10px;
    background-color: white;
    padding: 10px;
    border: 1px solid rgba(128, 128, 128, 0.295);
    border-radius: 4px;
}
.emptyCart{
    height: 70vh;
    text-align: center;
}
.emptyCart > img{
    width: 250px;
    margin-top: 10%;
}
.errorAnime{
    animation: error 0.5s ease-in-out !important;
}
.credit_select_div{
    border-bottom: 1px solid #8481BE;
    margin-bottom: 4px;
}
.limit_card{
    height: fit-content !important;
    display: table;
    padding: 3px;
    margin-left: 20px;
    border-radius: 5px;
    
}
.clear_cart_sec{
   display: flex;
   position: relative;
}

.clear_cart_btn{
    color: rgb(39, 38, 38);
}

.cart_check{
  border: rgba(128, 128, 128, 0.267) !important;
  cursor: pointer;
  width: 15px;
  height: 15px;
  top: 5px !important;
  margin-top:5px !important;
}
.cart_check_lg{
    border: rgba(128, 128, 128, 0.267) !important;
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-right: 15px !important;
}
.delete_all{
    font-size: 24px !important;
    color: rgb(226, 72, 16);
    cursor: pointer;
}
.dwnld_cart_csv{
    cursor: pointer;
    margin: 0px 15px;
}
.dwnld_cart_csv:hover{
   text-decoration: underline;
   color: #104e4b;
}

.gift_header > .accordion-button{
    background: linear-gradient(124deg, #4B4495, #5CD1CC, #5CD1CC, #4B4495);
    background-size: 1800% 1800%;
    -webkit-animation: rainbow 5s ease infinite;
    animation: rainbow 5s ease infinite;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}

.gift_header > .accordion-button::after{
    content: "▼";
    color: #ffffff !important;
    background-image: none;
    font-weight: bold;
}

.gift_header svg {
    font-size: 1.8rem;
}

.gift_banner {
    max-height: 150px;
    overflow: hidden;
    border-radius: 3px;
}

.gift_banner img{
    width: 100%;
}

.gift_list{
    margin: 0.5rem 0;
    padding: 0.5rem;
    color: #ffffff;
    font-weight: 500;
    background: linear-gradient(124deg, #4B4495, #5CD1CC, #5CD1CC, #4B4495);
    background-size: 1800% 1800%;
    -webkit-animation: rainbow 5s ease infinite;
    animation: rainbow 5s ease infinite;
    border-radius: 3px;
}

.gift_panel {
    background: linear-gradient(124deg, #4B4495, #5CD1CC, #5CD1CC, #4B4495);
    background-size: 1800% 1800%;
    -webkit-animation: rainbow 5s ease infinite;
    animation: rainbow 5s ease infinite;
    color: white;
    text-transform: uppercase;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gift_panel_size_1 {
    margin-top: 0.5rem;
    padding: 0.3rem;
    font-weight: 700;
    max-width: 150px;
}

.gift_panel_size_2 {
    padding: 0 0.3rem;
}

.gift_panel_size_1 svg {
    font-size: 1rem;
}

.gift_panel_size_2 svg {
    font-size: 0.8rem;
}

.gift_panel p {
    margin-bottom: 0;
    letter-spacing: 1px;
    margin-left: 0.3rem;
}

.gift_panel_size_1 p {
    font-size: 0.7rem;
}

.gift_panel_size_2 p {
    font-size: 0.7rem;
}
.generic_message{
    display: flex;
    justify-content: space-between;
    align-content: center !important;
    padding:2px;
    border: 1px solid #F46D6A;
    background-color: #FFECEB;
    border-radius: 20px;
}
.generic_message  div{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center !important;
}

.generic_message img{
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

@keyframes error {
    from{margin-left:-4px}
    30%{margin-left: 4px;}
    50%{margin-left: -4px;}
    75%{margin-left: 4px;}
    to{margin-left: -4px;}

}
@media only screen and (max-width: 1001px) and (min-width:751px){

    .cart_parent{
        padding: 9rem 5%;
    }
    .total_card_sec{
        font-size: 12px;
    }
    .subTotal_note{
        font-size: 10px;
    }
}

@media only screen and (max-width: 750px) {
    .cart_parent{
        /* margin-top: 10%; */
        padding: 8.5rem 5%;
    }
    .total_card_sec{
        font-size: 12px;
    }
    .subTotal_note{
        font-size: 10px;
    }
    .accordion-button{
        font-size: 14px;
    }
    .updatedProductCard{
        font-size: 14px;
    }
    .removeFromCartBtn{
        font-size: 12px;
    }
    .editFromCartBtn{
       font-size: 12px;
    }
    .updatedProductName{
        font-size: 12px;
    }
    .updated_cd{
        font-size: 12px;
    }
}