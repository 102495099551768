.map-container{
    height:35rem;
    width: 31.2rem;
}

.enable-location-info{
    display: flex;
    min-height: 10rem;
    align-items: center;
    justify-content: center;
    border-radius: 0px !important;
}

@media only screen and (max-width: 750px) {
    .map-container{
        height: 80vh ;
        width: auto ;
        /* height: 100vh; */
        /* width: 100vh; */
    }
}