.onboarding {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  /* position: fixed;
    top: 0;
    overflow-y: scroll; */
  background-color: rgba(255, 255, 255, 0.233);
  overflow: hidden;
  padding: 0px 0px !important;
}

.parentDiv_onboarding {
  width: 100%;
  /* margin: 3% auto; */
  height: 100vh;
  /* border-radius: 10px; */
  background-color: #4b4495;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
  /* border: 3px solid rgba(245, 245, 245, 0.233); */
  padding: 0px 0px !important;
  display: block;
  overflow: auto;
}
/* .saveoLogo{
    width: 100px;
    height: 130px;
    margin-left: 45%;
    margin-top: 10px;
}
.storeLogo{
    width: 200px;
    height: 300px;
    margin-left: 10%;
    margin-top: 10px;
}
.storeLogo2{
    width: 200px;
    height: 300px;
   margin-left: 40%;
   margin-top: -110px;
} */
.onboarding_section {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100vh;
  background-color: white;
  margin: -100px auto;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
/* .inputs_div{
    margin: 0px auto !important;
    padding:3% 10px 0px 10px;
    font-size: 24px;
} */

.password_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.password1 {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
  font-size: 14px;
}
.input_password {
  width: 350px;
  margin: 20px auto !important;
}
.button {
  width: 200px !important;
  padding: 10px;
  /* margin: 10px; */
}
.button:focus {
  outline: 0 !important;
}

.width_200px {
  width: 350px;
  text-align: center;
  margin: 0px auto;
}

.onboard-card{
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  /* .saveoLogo{
        height: 120px;
        margin-left: 38%;
    } */
  .parentDiv_onboarding {
    width: 106%;
    margin: 0% 0% 0% -3%;
    height: fit-content;
  }
  .onboarding {
    overflow: inherit;
  }
  .onboarding_section {
    height: 100%;
    width: 108%;
    margin: 0px 0px 0px -3%;
  }
  /* .inputs_div{
        margin-top: -40px;
    } */
  /* .storeLogo{
        width: 150px;
        height: 270px;
        margin-left: 2%;
        margin-top: 10px;
        position: relative;
        top: 96px;
    } */
  /* .storeLogo2{
        width: 100px;
        height: 200px;
       margin-left: 20%;
       margin-top: -30px;
       position: relative;
        top: 100px;
    } */
  .input_phone {
    width: 280px;
    margin: 15px auto !important;
  }

  .onboarding_section_2 {
    position: absolute;
    width: 93.5%;
    left: 10px;
    bottom: 0px;
  }

  .select-business-section {
    width: 93% !important;
    height: 100vh !important;
  }

  .onboard-card {
    height: 100px !important;
    /* padding: 20px 10px; */
  }
  .onboard-img-container {
    height: 60px !important;
    width: 100px !important;
    margin-left: 20px;
    margin-right: 10px;
  }

  .onboard-card-content {
    display: inline-block;
    margin-top: 10px;
  }

  .onboard-card-title {
    font-size: 12px !important;
  }
  .onboard-card-text {
    font-size: 10px !important;
  }

  .next-button-container {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .help-tag {
    /* margin: 5px 20px 0px auto !important; */
    cursor: pointer;
  }
  .header-nav {
    margin: 0px 25px !important;
    padding-top: 20px !important;
  }

  .shimmer-skeleton>div:first-child{
    height: 4rem !important;
    width: 4rem !important;
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  /* .saveoLogo{
        height: 120px;
       
    } */
    .onboarding{
      overflow: inherit;
    }
  .parentDiv_onboarding {
    /* margin:10% auto !important; */
    height: fit-content;
  }
  .onboarding_section {
    height: 100vh;
    justify-content: space-between;
  }
  /* .storeLogo{
        width: 150px;
        height: 270px;
        margin-left: 3%;
        margin-top: 10px;
    }
    .storeLogo2{
        width: 120px;
        height: 240px;
       margin-left: 35%;
       margin-top: -60px;
    } */
  .onboarding_inputs {
    width: 250px !important;
  }
  .onboard-img-container {
    margin-left: 40px;
    margin-right: 20px;
  }
  .onboard-img-container {
    height: 80px !important;
    width: 100px !important;
  }
  .active-tag {
    min-height: 100px !important;
  }

  .select-business-section {
    width: 65% !important;
  }
}
@media only screen and (max-width: 1290px) and (min-width: 1002px) {
  /* .saveoLogo{
        height: 120px;
       
    } */
  .parentDiv_onboarding {
    /* margin:10% auto !important; */
    /* height: 645px; */
  }
  .onboarding_section {
    /* height: 300px; */
    justify-content: flex-start;
  }
  /* .storeLogo{
        width: 200px;
        height: 300px;
        margin-left: 3%;
        margin-top: 10px;
    }
    .storeLogo2{
        width: 200px;
        height: 300px;
       margin-left: 35%;
       margin-top: -110px;
    } */
  .select-business-section {
    width: 65% !important;
  }
}

.password-error {
  font-size: 12px;
  margin: -10px auto 0px auto;
  text-align: start;
  width: 350px;
  padding: 0px !important;
}

.header-nav {
  /* margin: 3% 10px auto; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 13%;
  padding-top: 20px;
}

.header-nav-icon {
  /* margin: 10px 0px; */
}

.header-nav-title {
  margin: 10px 20px;
  /* margin-top: 10px; */
  font-size: 18px;
  /* padding-top: 20px; */
  color: white;
}

.help-tag {
  color: #48d1cc;
  /* margin: 10px 20px 0px auto; */
  cursor: pointer;
}

.select-business-section {
  /* width: 67%; */
  width: 60%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100vh;
  background-color: #f2f7f7;
  text-align: center;
  margin-top: 20px;
  margin: 10px auto !important;

  /* position: absolute; */
}

.onboard-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-evenly; */
  height: 150px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: white;
  /* padding-top: 30px; */
  margin-top: 20px;
  /* padding: 20px 10px; */
}

.onboard-card-container {
  padding: 10px 0px !important;
}

.onboard-img-container {
  display: flex;
  background-color: #dadafd;
  min-height: 80px;
  min-width: 80px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 10px;
}

.shimmer-skeleton{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}

.shimmer-skeleton>div:first-child{
  height: 5rem;
  width: 5rem;
  border-radius: 8px;
  margin-left: 1.25rem;
}

.shimmer-skeleton>div:last-child{
  width: 50%;
  margin-left: 1.5rem;
}

.shimmer-skeleton>div:last-child>div:first-child{
  height: .6rem;
  margin-bottom: .5rem;
  width: 100%;
}

.shimmer-skeleton>div:last-child>div:last-child{
  width: 90%;
  height: .6rem;
}


.onboard-img {
  color: #4b4495;
  size: 1rem;
  max-height: 46px;
}

.onboard-card-content {
  display: inline-block;
  text-align: left;
  margin-top: 10px;
  margin-right: 10px;
}

.onboard-card-title {
  font-weight: bold;
  color: #6d6d6d;
}

.onboard-card-text {
  color: #9399a4;
}

.active-tag {
  min-height: 70px;
  min-width: 10px;
  background-color: #4b4495;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.next-button-container {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inputs-zipcode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  /* display: inline-block; */
}
.zip-item {
  width: 40px !important;
  height: 40px !important;
  background-color: #f5f5f5;
  border: none;
  margin: 20px 5px;
}

.zip-item:focus {
  background-color: #f5f5f5;
}

.skip-password{
  margin: 0px;
  font-size: 0.7rem;
  color: #6D6D6D;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.skip-password:hover{

  font-size: 0.8rem;
  color: #4b4495;
}