
.homepage{
    width: 100%;
    overflow: hidden !important;
    background-color:#F2F7F7 ;
    padding-top: 9rem;
}

.banner_section{
    width:80%;
    height: 260px ;
    background-color: #F2F7F7;
    vertical-align: top !important;
    margin: 0px auto !important;
    animation: none !important;
    animation-play-state: paused !important;
}

.banner_img{
    width: 100%;
    height: 240px;
    padding:0px;
   margin: 0px auto !important;
   cursor: pointer;
}
.react-3d-carousel{
    animation: none !important;
    animation-play-state: paused !important;
}
.slider-single-content{
/* width:fit-content !important; */
animation: none !important;
animation-play-state: paused !important;
}
.slider-container{
    animation: none !important;
    animation-play-state: paused !important;
}
.slider-content{
    animation: none !important;
    animation-play-state: paused !important;
}
 .preactive{
   margin-left: -20% !important;
}
.proactive{
    margin-left:20% !important;
 }
.dialyFlashSale{
    margin:1rem auto;
    height:fit-content !important;
    width: 100%;
    padding: 0px !important;
    /* padding-bottom: 4px; */
    background: rgb(31,1,84);
    background: linear-gradient(90deg, #504097 0%, rgba(153,31,231,1) 100%);
    border-radius: 10px;
    overflow: hidden !important;
}
.color_light_purple{
    color:rgb(231, 171, 243) !important;
}
.fash_sale_spark_img{
    width: 30px;
    margin-top: -5px;
}
.flash_header_12{
    font-size: 12px !important;
}
.flash_header_14{
    font-size: 14px !important;
}
.flash_sale_header{
    display: flex;
    padding: 5px 0px 0px 5px;
    line-height: 17px;
}
.clock_img{
   font-size: 20px;
}
.skeletal_div{
    width: 100%;
    height: 200px; 
    margin: 5% auto;
}
.skeletal_div:first-child{
    margin-top: 0 !important;
}
.skeletal_div_sm{
    width: 70%;
    height: 200px; 
    margin: 5% auto;
}
.time_limit{
    width:100%;
    background-color: white;
    border: 1px solid rgb(231, 171, 243);
    border-right: none !important;
    padding: 3px;
    text-align: right !important;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    margin-left: 30px;


}
.dealofday{
    background-color: #CDCDF2;
    width: 100%;
    height: fit-content;
}
.covidEssentials{
    background-color: #CAF1EF;
    color: #569D99;
    height:fit-content !important;
    width: 100%;
    padding:1% 10px !important;
    /* padding-bottom: 4px; */
    overflow: hidden !important;
}
.trendingProducts{
    background-color: #FEF4D9;
    height:fit-content !important;
    width: 100%;
    padding:1% 10px !important;
    /* padding-bottom: 4px; */
    overflow: hidden !important;
}
.commonSlider{
    background-color: aliceblue;
    height:fit-content !important;
    width: 100%;
    padding:1% 10px !important;
    /* padding-bottom: 4px; */
    overflow: hidden !important;
}
.specialProducts{
    background-color: #F7DFDD;
    height:fit-content !important;
    width: 100%;
    padding:1% 10px !important;
    /* padding-bottom: 4px; */
    overflow: hidden !important;
}
.orderedProducts{
    background-color: #f7dfdd00;
    height:fit-content !important;
    width: 100%;
    padding:1% 10px !important;
    /* padding-bottom: 4px; */
    overflow: hidden !important;
}
.web_carousel{
    display: block;
}
.mobile_carousel{
    display: none;
}

.myAccount_list > div {
    display: flex;
    align-items: center;
    flex-direction: column;
}
@media only screen and (max-width: 750px) {
    .banner_section{
        height: 150px;
        padding:5px;
    }
    .homepage{
        padding-top:8.3rem;
    }
    .flash_header_12{
        font-size: 7px !important;
       
    }
    .flash_header_14{
        font-size: 9px !important;
    }
    .skeletal_div{
        height: 180px; 
    }
    .skeletal_div_sm{
        height: 180px; 
    }
    .fash_sale_spark_img{
        width: 14px;
        margin-top: -2px;
    }
    .flash_sale_header{
        line-height: 10px;
        margin-top: -3px;
    }
    .dialyFlashSale{
        margin: 1rem 0rem 1rem 1rem;
        height: 200px;
        width: 100%;
        text-align: right !important;
        overflow: hidden !important;
    }
    .left_scroll{
        display: none;
    }
    .right_scroll{
        display: none;
    }
    .time_limit{
        padding: 2px;
        margin-right: 12px !important;
    }

    
    .time_limit > p{
        font-size: 10px !important;
    }
    .clock_img{
        font-size: 15px !important;
    }
    .banner_img{
        width: 100%;
       height: 140px;
    }
    .web_carousel{
        display: none;
    }
    .mobile_carousel{
        display: block;
    }
    .banner_section{
        width: 100%;
    }

}



/* categories section css  */

.categories_section{
    background-color: white;
    width: 100%;
    height: fit-content;
    padding:2%;
}
.categories_section::-webkit-scrollbar{
    display: none !important;
}
.cat_parent:hover{

color:#4B4495 !important;
font-weight: bolder !important;
}
.cat_list{
    padding: 2% 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
}
.myAccount_list{
    padding: 2% 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.myAccount_list > div{
    margin: 1% 2%;
}
.cat_list > div{
    margin: 1% 2%;
}
.cat_item{
    background-color: #F1FCFB;
    border: 1px solid #E5F7F7;
    border-radius: 50%;
   padding: 10px;
    margin: 3px 30px;
    cursor: pointer;
    height: 72px;
}



.account_item{
    background-color: #F1FCFB;
    border: 1px solid #E5F7F7;
    border-radius: 8px;
   padding: 10px;
    margin: 7px 22px;
    cursor: pointer;
    height: 62px;
    width: 62px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.account_item >img{
    height: 40px;
    width: 40px;
}
.cat_item > img{
    height: 50px;
    width: 50px;
}
.cat_name{
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: black

}
.new_tag_cat{
    background-color: #FFD600;
    color: #7E6322;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bolder;
    border-radius: 3px;
   /* top: -10px !important; */
   top: -10px;
   left: 10px;
    position: relative;
    /* left: 10px; */
}
.new_tag{
    background-color: #FFD600;
    color: #7E6322;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bolder;
    border-radius: 3px;
   /* top: -10px !important; */
   top: 0px;
   left: 0px;
    position: relative;
    /* left: 10px; */
}
.web_display{
    display: block;
  }
  .mobile_display{
      display: none;
  }
@media only screen and (max-width: 750px) {
    .cat_item > img{
        height:26px;
        width: 26px;
    }
    .account_item > img{
        height:26px;
        width: 26px;
    }
    .cat_name{
        font-size: 12px;
        font-weight: 500;
        max-width: 90px;
    }
    .cat_item{
        height: 50px;
        width: 50px;
        padding: 10px 11px;
        margin: 2px 7px;
    }
    .account_item{
        height: 50px;
        width: 50px;
        padding: 10px 11px;
        margin: 2px 7px;
    }
    .categories_section > header{
        font-size: 14px;
        position: sticky;
        left: 0%;
    }
    .categories_section{
        width: 110% !important;
        overflow-x: auto;
        transition: all 0.6s ease;
        flex:none !important;
        padding: 15px;
    }
    .cat_list{
        width: 150% !important;
       transition: all 0.6s ease;
       margin-left: -20px;
       margin-top: 5px;
       
    }
    .myAccount_list{
        transition: all 0.6s ease;
        /* margin-left: -20px; */
        margin-top: 5px;
        padding: 0px 10px;
    }
    .myAccount_list > div {
        padding-bottom: 20px;
    }
    .cat_list > div {
        padding-bottom: 20px;
    }
    .new_tag{
        font-size: 8px;
        padding: 2px 3px;
        left: 8%;
    }
    .new_tag_cat{
        font-size: 8px;
        padding: 2px 3px;
        left: 8%;
    }
    .web_display{
        display: none;
      }
    

}

@media only screen and (max-width: 1001px) and (min-width:751px){
    .flash_header_12{
        font-size: 12px !important;
    }
    .homepage{
        padding-top:10rem;
    }
    .flash_header_14{
        font-size: 14px !important;
    }
    .fash_sale_spark_img{
        width: 24px;
        margin-top: -5px;
    }
    .flash_sale_header{
        line-height: 12px;
    }
    .banner_section{
        height: 200px;
    }
    .dialyFlashSale{
        height: 220px;
    }
    .left_scroll{
        display: none;
    }
    .right_scroll{
        display: none;
    }
    .new_tag_cat{
        font-size: 8px;
        padding: 2px 3px;
        left: 8%;
    }
    .new_tag{
        font-size: 8px;
        padding: 2px 3px;
        left: 8%;
    }
    .cat_item > img{
        height:26px;
        width: 26px;
    }
    .account_item > img{
        height:26px;
        width: 26px;
    }
    .cat_list > div {
        padding-bottom: 20px;
    }
    .myAccount_list>div{
        padding-bottom: 20px;

    }
    .cat_name{
        font-size: 8px;
        font-weight: 500;
        
    }
    .cat_item{
        height: 50px;
        width: 50px;
        padding: 10px 11px;
        margin: 2px 10px;
    }
    .account_item{
        height: 50px;
        width: 50px;
        padding: 10px 11px;
        margin: 5px 10px;
    }

    .banner_section{
        height: 230px;
    }
    .banner_img{
        height: 200px;
    }
   
    
}


@media only screen and (max-width: 1290px) and (min-width:1002px){
    .banner_section{
        height: 250px;
    }
    .homepage{
        padding-top:9rem;
    }
    .dialyFlashSale{
        height: 230px;
    }
    .left_scroll{
        display: none;
    }
    .right_scroll{
        display: none;
    }
    .myAccount_list > div{
        padding-bottom: 20px;

    }
    .cat_list > div {
        padding-bottom: 20px;
    }
    .cat_item{
        margin: 2px 10px;
    }
    .account_item{
        margin: 5px 10px;
    }
   
}















