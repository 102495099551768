.campCont {
  background-color: #514aa1;
  border-radius: 5px;
  padding: 0.5rem;
  width: 500px;
  position: relative;
  animation: showCampaign 0.35s forwards ease-in;
}

@keyframes showCampaign {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Current Status Tag */
.campStatus {
  margin: 0;
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  padding: 0.2rem 0.5rem;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
  font-size: 0.8rem;
  z-index: 2;
}

.campLive {
  background-color: #cd760c;
}

.campCompleted {
  background-color: #249e6c;
}

.campExpired {
  background-color: #ef5a46;
}

.campExpired span {
  margin-right: 0.2rem;
}

/* Campaign Banner */
.campBanner {
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #a19dd2;
}

.campBanner img {
  width: 100%;
  border-radius: 3px;
}

.disableBanner {
  filter: brightness(0.6);
}

/* Default Banner Image */
.campBanner_default {
  display: grid;
  place-items: center;
  padding: 1rem;
  height: 150px;
}

.campBanner_default svg {
  font-size: 3rem;
  color: #a19dd2;
}

.campBanner_default p {
  margin: 0;
  color: #a19dd2;
  text-transform: uppercase;
  font-weight: bold;
}

/* Campaign Milestone Description */
.campMilestone {
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.campMilestone li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.completedMilestone {
  background-color: #443f78;
  padding: 0.5rem;
  border-radius: 3px;
}

.campDescCont {
  display: flex;
  align-items: flex-start;
}

.completedMilestone p {
  font-weight: 500;
}

.campCheck {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  display: flex;
}

.campBullet {
  color: white;
  margin-top: -0.2rem;
  margin-right: 0.5rem;
  display: flex;
}

.campDesc {
  color: white;
  margin: 0;
  font-size: 0.8rem;
}

/* Campaign Progress Bar */
.campProgressCont {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 0.5rem 0 1rem;
}

.campProgressBar {
  width: 100%;
  height: 5px;
  background-color: #d1d1d1;
  display: flex;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
}

.campProgress {
  height: 100%;
  background-color: #24c8c2;
  border-radius: 3px;
}

.completedMilestone .campProgressCont {
  margin: 0 0.5rem 0 1.5rem;
}

/* Campaign Progress Box */
.campProgressBox {
  position: absolute;
  padding: 0.2rem;
  top: 50%;
  background-image: linear-gradient(to bottom, #90fffb, #48d1cc);
  min-width: 55px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 3px;
  border: 1px solid #24c8c2;
  line-height: 1.2;
}
.campProgressBox p {
  margin: 0;
}

.campProgressHeader {
  font-weight: bold;
  font-size: 0.65rem;
  color: #17706c;
}

.campProgressValue {
  font-weight: bolder;
  font-size: 0.8rem;
  color: #383838;
}
