.products_slide_view{
    display: flex;
    overflow-x: scroll;
    scrollbar-width: thin !important;
    scroll-behavior: smooth;
    justify-content: start ;
    text-align: start !important;
     
}
.products_slide_view2{
    width: 200% ;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.slider2_parent{
    width: 100% ;
    transition: all 0.6s ease;
        flex:none !important;
        scroll-behavior: smooth;
        overflow-x: auto;

}
.slider2_parent::-webkit-scrollbar{
    display: none !important;
    padding: 0px 10px;
}
.products_slide_view::-webkit-scrollbar{
    display: none !important;
    padding: 0px 10px;
}
.display_block{
   
}
.left_scroll{
    width: fit-content;
    /* padding:50px 10px; */
    background-color: #a49cf550;
    margin-left: -12px;
    color: aliceblue;
    z-index: 5 !important;
}
.right_scroll{
    width: fit-content;
    /* padding:50px 10px; */
    background-color: #a49cf598;
    text-align: right !important;
    color: aliceblue;
    z-index: 3;
}
.slider_footer{
   padding: 5px;
   width: 100% !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.slider_footer2{
    background-color:#4B4495;
    padding: 7px;
    margin-left: -2%;
    width: 105% !important;
}
.arrow_dark{
    color: #750a67;
}
.arrow_light{
    color: rgba(179, 19, 158, 0.575);
}
.arrow_dark2{
    color: white;
}
.arrow_light2{
    color: rgba(255, 255, 255, 0.582);
}
.see_all_btn{
    font-size: 14px;
}
@media only screen and (max-width: 750px) {
    .left_scroll{
        display: none !important;
    }
    .right_scroll{
        display: none !important;
    }
    .slider_footer{
        padding: 3px;
    }
    .see_all_btn{
        font-size: 10px;
    }
    .arrow_dark{
       font-size: 12px;
    }
    .arrow_light{
        font-size: 12px;
    }
    .products_slide_view2{
        width: 400%;
       
    }
    .slider2_parent{
        width: 110% ;
       
    }
}

@media only screen and (max-width: 1001px) and (min-width:751px){
    .left_scroll{
        display: none !important;
    }
    .right_scroll{
        display: none !important;
    }
}

@media only screen and (max-width: 1290px) and (min-width:1002px){
    .left_scroll{
        display: none;
    }
    .right_scroll{
        display: none;
    }
}