.apply-credit-parent-container {
  padding: 0px;
  /* margin-top: 3rem; */
  padding-top: 6rem;
  min-height: 100vh;
}

.apply-credit-main-container {
  background: #f6f6f6;
  min-height: 90vh;
  margin: 0px;
  padding-top: var(--bs-gutter-x, 0.75rem);
  display: block;
  overflow: auto;
}

.apply-credit-main-wrapper {
  width: 50%;
}

.apply-credit-text-input-container {
  margin: 1.5rem 0rem;
}

.input-text-apply-credit {
  min-height: 3rem;
  border: 1px solid gray;
  border-radius: 8px;
}

.apply-credit-input-document-container {
  border: 1px dashed grey !important;
  color: grey;
  min-height: 12.5rem;
  min-width: 48%;
}

.apply-credit-input-document-preview-container {
  height: 12.5rem;
  width: 48%;
  border-radius: 10px !important;
}

.preview-image-credit {
  height: 12.37rem;
  width: 100%;
  object-fit: cover;
  border-radius: 10px !important;
}

.upload-pan {
  min-width: 97% !important;
  min-height: 15rem !important;
}

.pan-front-preview-container {
  min-width: 97% !important;
  min-height: 15rem !important;
  border: 1px dashed grey !important;
  border-radius: 10px !important;
}

.preview-image-credit-pan {
  height: 15rem !important;
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px !important;
}

.apply-credit-button {
  width: 50%;
  border-radius: 0px;
  min-height: 4rem;
  background: #48d1cc;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 8px;
}

.apply-credit-apply-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0rem;
}

.document-upload-section1-group {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.document-upload-section2-container {
  margin: 1.5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit-document-input-label {
  color: gray;
}

.stepper-container {
  margin: 1rem 0rem;
  margin-top: 3rem;

}

.credit-reponse-parent-container {
  min-height: 85vh;
  text-align: center;
}

.response-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  width: 100%;
}

.response-button {
  min-height: 3rem;
  width: 40%;
  background: #48d1cc;
  margin: 0.5rem auto;
  font-weight: bold;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  font-weight: bold;
}

.response-button:hover {
  background: #48d1cc;
  border: none;
  font-weight: bold;
  box-shadow: none;
}

.next-variant {
  min-height: 3rem;
  background: #48d1cc;
  font-weight: bold;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  position: absolute;
  bottom: 4rem;
  width: 40%;
}

.status-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select-business-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-option {
  margin: 1rem auto;
}

@media only screen and (max-width: 750px) {
  .apply-credit-main-wrapper {
    width: 100%;
  }

  .apply-credit-button {
    width: 100%;
  }

  .apply-credit-main-container {
    padding: 0px;
  }

  .apply-credit-input-document-container {
    min-height: 6.5rem;
  }

  .apply-credit-input-document-preview-container {
    height: 6.5rem !important;
  }

  .preview-image-credit {
    height: 6.35rem;
  }

  .upload-pan {
    min-height: 12.5rem;
    width: 97%;
  }

  .next-variant {
    width: 100%;
    border-radius: 0px;
  }
  .status-info {
    width: 100%;
    font-size: 1.2rem;
  }
  .response-button {
    width: 100%;
    border-radius: 0px !important;
  }

  .response-button-container {
    bottom: 3rem;
  }

  .select-business-wrapper {
    /* width: fit-content; */
  }
}

@media only screen and (max-width: 1001px) and (min-width: 751px) {
  .apply-credit-parent-container {
    margin-top: 5rem;
  }

  .response-button {
    width: 60%;
  }

  .response-button-container {
    bottom: 4rem;
  }

  .status-info {
    width: 70%;
    font-size: 1.5rem;
  }
}
