/* From uiverse.io by @abrahamcalsin */
.loaderRectangle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 3px;
   }
   
   .loaderRectangle div {
    width: .2rem;
    height: .9rem;
    animation: .9s ease-in-out infinite;
    background: #ffffff;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
   }
   
   .loaderRectangle div:nth-child(1) {
    animation-name: rectangleOneAnim;
    animation-delay: .1s;
   }
   
   @keyframes rectangleOneAnim {
    0% {
     height: 5px;
    }
   
    40% {
     height: 10px;
    }
   
    100% {
     height: 5px;
    }
   }
   
   .loaderRectangle div:nth-child(2) {
    animation-name: rectangleTwoAnim;
    animation-delay: .2s;
   }
   
   @keyframes rectangleTwoAnim {
    0% {
     height: 5px;
    }
   
    40% {
     height: 20px;
    }
   
    100% {
     height: 5px;
    }
   }
   
   .loaderRectangle div:nth-child(3) {
    animation-name: rectangleThreeAnim;
    animation-delay: .3s;
   }
   
   @keyframes rectangleThreeAnim {
    0% {
     height: 5px;
    }
   
    40% {
     height: 30px;
    }
   
    100% {
     height: 5px;
    }
   }
   
   .loaderRectangle div:nth-child(4) {
    animation-name: rectangleFourAnim;
    animation-delay: .4s;
   }
   
   @keyframes rectangleFourAnim {
    0% {
     height: 5px;
    }
   
    40% {
     height: 20px;
    }
   
    100% {
     height: 5px;
    }
   }
   
   .loaderRectangle div:nth-child(5) {
    animation-name: rectangleFiveAnim;
    animation-delay: .5s;
   }
   
   @keyframes rectangleFiveAnim {
    0% {
     height: 5px;
    }
   
    40% {
     height: 10px;
    }
   
    100% {
     height: 5px;
    }
   }
